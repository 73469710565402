import React, { useState, useContext, useEffect, useRef } from "react";
import CheckBox from "@mui/icons-material/CheckBox";
import {
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/Context";

import { SocketContext } from "../../context/SocketContext";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";

function BilletHistorikk() {
  //* Was here for the tables
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [rows, setRows] = useState(false);

  //* Redirect to login if not logged in
  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, []);

  //* Manage enter from the scan
  const inputRef = useRef(null);
  const buttonRef = useRef(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLogin, isBlocking, BlockingScreen } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1400);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const { operator, hall, session } = useContext(AppContext);

  const location = useLocation();

  const [ticketToSearch, setTicketToSearch] = useState(
    location.state ? location.state.value : ""
  );
  const handleTicketToSearchChange = (event) => {
    setTicketToSearch(event.target.value);
  };
  const ticketToSearchRef = useRef(ticketToSearch);
  useEffect(() => {
    ticketToSearchRef.current = ticketToSearch;

    if (ticketToSearch.length === 7) {
      forceClick();
    }
  }, [ticketToSearch]);

  //* Socket setup
  const { socket } = useContext(SocketContext);
  useEffect(() => {
    const handleTicketHistoric = (data) => {
      if (!data.transactions) {
        data.transactions = [];
      }
      const movements = [
        ...data.sessions.sessionsListOpened,
        ...data.sessions.sessionsListClosed,
        ...data.transactions,
      ];

      // Sorting by date in descending order
      movements.sort((a, b) => new Date(b.date) - new Date(a.date));
      setRows(movements);
    };

    socket.on("ticketHistoric", handleTicketHistoric);

    return () => {
      socket.off("ticketHistoric", handleTicketHistoric);
    };
  }, [socket]);

  const searchTicket = () => {
    var data = {
      operator: operator,
      sessionID: session,
      bingoName: hall,
      ticket7Pincode: ticketToSearchRef.current,
    };
    socket.emit("ticketHistoric", data);
  };

  function convertToMySQLDateTime(isoString) {
    const date = new Date(isoString);

    // Padding function to add leading zeros if necessary
    const pad = (number) => (number < 10 ? "0" + number : number);

    // Format the date using UTC methods
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // getUTCMonth() returns month from 0-11
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  function getActionText(action) {
    switch (action) {
      case "Opened session":
        return t("ticketHistoric.openSession");
      case "Closed session":
        return t("ticketHistoric.closeSession");
      case 11:
        return t("ticketHistoric.win");
      case 12:
        return t("ticketHistoric.payment");
      case 13:
        return t("ticketHistoric.deposit");
      case 14:
        return t("ticketHistoric.cancel");
      case 15:
        return t("ticketHistoric.deposit");
      case 16:
        return t("ticketHistoric.depositFromPreviousSession");
      default:
        return "";
    }
  }

  const forceClick = () => {
    buttonRef.current.click(); // Use the ref to trigger the click
  };

  return (
    <>
      {isBlocking && <BlockingScreen />}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/*BUTTONS*/}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                height: "200px",
                maxHeight: "200px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "25px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "100%",
                    height: "200px",
                    padding: "25px",
                    margin: "0px 0 20px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      height: "75px",
                      margin: "5px 0 10px 0",
                      backgroundColor: "#232a45",

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#365ab8",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fcad89",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fcad89",
                        },
                      },

                      "& input": {
                        color: "white !important",
                        fontSize: "30px",
                        borderColor: "black",
                      },
                      "& label": {
                        fontSize: "22px",
                        lineHeight: "2.438em",
                        color: "white !important",
                      },
                      "& label.Mui-focused": {
                        color: "white !important",
                      },
                      "& input.Mui-focused": {
                        color: "white !important",
                        borderColor: "black",
                      },
                    }}
                    aria-describedby={id}
                    label={t("ticketHistoric.ticketToSearch")}
                    value={ticketToSearch}
                    onChange={handleTicketToSearchChange}
                    autoComplete="off"
                    variant="outlined"
                    inputRef={inputRef}
                  ></TextField>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "100%",
                    height: "200px",
                    padding: "25px",
                    margin: "0px 0 20px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "180px",
                      height: "80px",
                      fontSize: "21px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      backgroundColor: "#009e82",
                    }}
                    ref={buttonRef}
                    onClick={() => {
                      searchTicket();
                    }}
                  >
                    {t("menu.search")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/*TABLE*/}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                padding: "10px",
                height: "100%",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "calc(100% - 200px)",
                  marginBottom: "200px",
                  overflowY: "scroll", // Enables vertical scrolling
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Opera
                  },
                }}
              >
                <Table
                  sx={{
                    color: "white",
                    background: "#1f2c67",
                  }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      background: "#1f2c67",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">
                        {t("ticketHistoric.date")}
                      </TableCell>
                      <TableCell align="center">
                        {t("ticketHistoric.action")}
                      </TableCell>
                      <TableCell align="center">
                        {t("ticketHistoric.valueChange")}
                      </TableCell>
                      <TableCell align="center">
                        {t("ticketHistoric.saldoInit")}
                      </TableCell>
                      <TableCell align="center">
                        {t("ticketHistoric.saldoEnd")}
                      </TableCell>
                      <TableCell align="center">
                        {t("ticketHistoric.gameID")}
                      </TableCell>
                      <TableCell align="center">
                        {t("ticketHistoric.bingoName")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {rows && (
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:nth-of-type(odd)": {
                              backgroundColor: "#232a45",
                            },
                            "&:nth-of-type(even)": {
                              backgroundColor: "#1a2036",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {convertToMySQLDateTime(row.date)}
                          </TableCell>

                          <TableCell align="center">
                            {getActionText(row.action)}
                          </TableCell>
                          <TableCell align="center">
                            {row.valueChange}
                          </TableCell>
                          <TableCell align="center">{row.saldoInit}</TableCell>
                          <TableCell align="center">{row.saldoEnd}</TableCell>
                          <TableCell align="center">{row.gameID}</TableCell>
                          <TableCell align="center">{row.bingoName}</TableCell>

                          {/*
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                // handle click
                              }}
                            >
                              {t("ticketHistoric.printAgain")}
                            </Button>
                          </TableCell>
                            */}
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default BilletHistorikk;
