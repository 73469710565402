import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import "../../assets/css/buttons.css";
import { SocketContext } from "../../context/SocketContext";
import toast from "react-hot-toast";

function ModalReconnect() {
  const { t } = useTranslation();

  const { transactions, updateIsReconnected, updateFocusOnMainScreen } =
    useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleCancel = () => {
    updateIsReconnected(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("socketStatus.reconnectTitle")}</h1>
            {transactions.length > 0 ? (
              <p>{t("socketStatus.reconnectTextTransactions")}</p>
            ) : (
              <p>{t("socketStatus.reconnectTextNoTransactions")}</p>
            )}
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: "55px" }}
            >
              <button
                className="btn-custom-yes"
                style={{ opacity: transactions.length > 0 ? 0.5 : 1 }}
                onClick={() => {
                  if (transactions.length > 0) {
                    return;
                  }
                  handleRefresh();
                }}
              >
                {t("socketStatus.refresh")}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  handleCancel();
                }}
              >
                {t("socketStatus.notRefresh")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalReconnect;
