import React, { useEffect, useState, useContext } from "react";
import { Grid, TextField, Typography, Button } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import CardPayment from "./CardPayment";

function PaymentScreenDescription({ amount, updateAmountShortcut }) {
  const { t } = useTranslation();

  const [isFocusedAmount, setIsFocusedAmount] = useState(false);
  const [isFocusedTicket, setIsFocusedTicket] = useState(false);

  const {
    isAnonUser,
    isCard,
    total,
    isFullUser,
    parentInfo,
    payCash,
    payCard,
    showCard,
    updateShowCard,
    updateIsSingleTransaction,
    updateShowSpillerkontoPayment,
    payCashTemp,
    payCardTemp,
    withdraw,
    paySpillerkonto,
    paySpillerkontoTemp,
  } = useContext(AppContext);

  const [isParentFullUser, setIsParentFullUser] = useState(false);

  useEffect(() => {
    if (!parentInfo) {
      setIsParentFullUser(false);
      return;
    }

    if (
      parentInfo.username.slice(0, 4) === "anon" ||
      parentInfo.username.slice(0, 6) === "hybrid"
    ) {
      setIsParentFullUser(false);
      return;
    }
    setIsParentFullUser(true);
  }, [parentInfo]);

  useEffect(() => {
    if (payCashTemp === 0) {
      updateAmountShortcut(0);
    }
  }, [payCashTemp]);

  const amountFocus = () => {
    setIsFocusedAmount(true);
    isFocusedTicket(false);
  };

  return (
    <Grid
      container
      flexDirection="row"
      sx={{
        height: "100%",
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {showCard && <CardPayment isSingleTransaction={false} />}

      {/* TITLE */}
      <Grid
        item
        xs={12}
        sx={{
          padding: "10px",
          height: "75px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          justifyContent: "flex-start",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h2>
          {isCard ? t("sales.paymentCardScreen") : t("sales.paymentCashScreen")}
        </h2>
      </Grid>

      {/*INPUT - SHORCUTS - PAYMENT OPTIONS */}
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "calc(100% - 125px)",
          width: "100%",
          alignContent: "space-around",
        }}
      >
        {/*INPUT*/}
        <Grid
          item
          xs={12}
          sx={{
            padding: "10px",
            height: "100px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid
            container
            flexDirection="row"
            sx={{
              height: "100%",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                height: "75px",
                fontSize: "19px",
                fontWeight: "bold",
                flexDirection: "row",
              }}
            >
              <Grid
                container
                flexDirection="row"
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginLeft: "-35px",
                }}
              >
                <Grid
                  item
                  xs={3}
                  sx={{
                    padding: "10px",
                    alignContent: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "75px",
                    fontSize: "19px",
                    fontWeight: "bold",
                    flexDirection: "row",
                  }}
                >
                  <AttachMoneyIcon
                    sx={{ fontSize: "35px", margin: "-5px 0 0 10px" }}
                  />{" "}
                </Grid>
                <Grid
                  item
                  xs={9}
                  sx={{
                    padding: "10px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    height: "75px",
                    fontSize: "19px",
                    fontWeight: "bold",
                    flexDirection: "row",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginLeft: "-30px",
                      textTransform: "uppercase",
                    }}
                  >
                    {t("sales.amount")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: 0,
                padding: "10px",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                onFocus={() => amountFocus()}
                onBlur={() => setIsFocusedAmount(false)}
                value={amount == 0 ? "" : amount}
                sx={{
                  "& label": {
                    position: "relative !important",
                    fontSize: "22px !important",
                    color: "white !important",
                  },
                  "& label.Mui-focused": {
                    position: "relative !important",
                    color: "white !important",
                  },
                  "& input": {
                    color: "white !important",
                    border: "3px solid white",
                    borderRadius: "4px",
                    height: "50px !important",
                    width: "275px !important",
                    fontSize: "40px !important",
                    textAlign: "center",
                  },
                  "& input.Mui-focused": {
                    color: "white !important",
                    border: "3px solid white",
                    borderRadius: "4px",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/*SHORTCUTS*/}
        <Grid
          item
          xs={12}
          sx={{
            padding: "10px",
            height: "335px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            justifyContent: "center",
            alignContent: "center",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ width: "100%", textAlign: "left" }}>
            {t("sales.shortcuts")}
          </h2>
          <Grid
            container
            flexDirection="row"
            sx={{
              height: "100%",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                border: "2px solid black",
                width: "100%",
                maxWidth: "100% !important",
                height: "100%",
                backgroundColor: "#232a45",
              }}
            >
              <Grid
                container
                flexDirection="row"
                sx={{
                  height: "100%",
                  width: "100%",
                  marginTop: 0,
                  marginLeft: 0,
                  display: "flex",
                  alignContent: "space-around",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "100%",
                        width: "100%",
                        fontSize: "35px",
                        fontWeight: "bold",
                        backgroundColor: "#889cab",
                        color: "white",
                        marginTop: "0px",
                      }}
                      onClick={() => {
                        updateAmountShortcut(10);
                      }}
                    >
                      10
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "100%",
                        width: "100%",
                        fontSize: "35px",
                        fontWeight: "bold",
                        backgroundColor: "#889cab",
                        color: "white",
                        marginTop: "0px",
                      }}
                      onClick={() => {
                        updateAmountShortcut(20);
                      }}
                    >
                      20
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "100%",
                        width: "100%",
                        fontSize: "35px",
                        fontWeight: "bold",
                        backgroundColor: "#889cab",
                        color: "white",
                        marginTop: "0px",
                      }}
                      onClick={() => {
                        updateAmountShortcut(50);
                      }}
                    >
                      50
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "100%",
                        width: "100%",
                        fontSize: "35px",
                        fontWeight: "bold",
                        backgroundColor: "#889cab",
                        color: "white",
                        marginTop: "0px",
                      }}
                      onClick={() => {
                        updateAmountShortcut(100);
                      }}
                    >
                      100
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "100%",
                        width: "100%",
                        fontSize: "35px",
                        fontWeight: "bold",
                        backgroundColor: "#889cab",
                        color: "white",
                        marginTop: "0px",
                      }}
                      onClick={() => {
                        updateAmountShortcut(200);
                      }}
                    >
                      200
                    </Button>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      sx={{
                        height: "100%",
                        width: "100%",
                        fontSize: "35px",
                        fontWeight: "bold",
                        backgroundColor: "#889cab",
                        color: "white",
                        marginTop: "0px",
                      }}
                      onClick={() => {
                        updateAmountShortcut(500);
                      }}
                    >
                      500
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/*CARD PAYMENT - SPILLERKONTO */}
        <Grid
          item
          xs={4}
          sx={{
            padding: "10px",
            height: "100px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            justifyContent: "flex-start",
            alignContent: "center",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              width: "225px",
              height: "90px",
              backgroundColor:
                total < 0 ||
                total -
                  (payCash + payCashTemp) -
                  (payCard - payCardTemp) -
                  withdraw <=
                  0
                  ? "grey"
                  : "#009f83",
              opacity:
                total < 0 ||
                total -
                  (payCash + payCashTemp) -
                  (payCard - payCardTemp) -
                  withdraw <=
                  0
                  ? "0.25"
                  : "1",
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
            onClick={() => {
              if (
                total <= 0 ||
                total -
                  (payCash + payCashTemp) -
                  (payCard - payCardTemp) -
                  withdraw <=
                  0
              ) {
                return;
              }

              if (isAnonUser && !parentInfo) {
                //registerAnonUser();
                return;
              }
              updateShowCard(true);
              updateIsSingleTransaction(false);
            }}
          >
            {isCard ? t("sales.payCash") : t("sales.payCard")}
          </Button>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            padding: "10px",
            height: "100px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            justifyContent: "flex-start",
            alignContent: "center",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              width: "225px",
              height: "90px",
              backgroundColor:
                (isFullUser || isParentFullUser) &&
                total > 0 &&
                total -
                  (payCash + payCashTemp) -
                  (paySpillerkonto - paySpillerkontoTemp) -
                  withdraw >
                  0
                  ? "#009f83"
                  : "grey",
              opacity:
                (isFullUser || isParentFullUser) &&
                total > 0 &&
                total -
                  (payCash + payCashTemp) -
                  (paySpillerkonto - paySpillerkontoTemp) -
                  withdraw >
                  0
                  ? "1"
                  : "0.25",
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
            onClick={() => {
              if (!isFullUser && !parentInfo) {
                return;
              }
              if (total <= 0) {
                return;
              }
              if (
                total <= 0 ||
                total -
                  (payCash + payCashTemp) -
                  (payCard - payCardTemp) -
                  (paySpillerkonto + paySpillerkontoTemp) -
                  withdraw <=
                  0
              ) {
                return;
              }
              updateShowSpillerkontoPayment(true);
            }}
          >
            {t("sales.payCH")}
          </Button>
          {/*
          <Button
            variant="contained"
            component="label"
            sx={{
              width: "225px",
              height: "90px",
              backgroundColor: "grey",
              opacity: "0.25",
              color: "white",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
            onClick={() => {
              return;
            }}
          >
            {t("sales.payCH")}
          </Button>
          */}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentScreenDescription;
