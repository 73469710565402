export const countriesDataNO = [
  { value: 'Afghanistan', label: 'Afghanistan', phoneCode: '+93' },
  { value: 'Aland Islands', label: 'Ålandøyene', phoneCode: '+358' },
  { value: 'Albania', label: 'Albania', phoneCode: '+355' },
  { value: 'Algeria', label: 'Algerie', phoneCode: '+213' },
  { value: 'American Samoa', label: 'Amerikansk Samoa', phoneCode: '+1-684' },
  { value: 'Andorra', label: 'Andorra', phoneCode: '+376' },
  { value: 'Angola', label: 'Angola', phoneCode: '+244' },
  { value: 'Anguilla', label: 'Anguilla', phoneCode: '+1-264' },
  { value: 'Antarctica', label: 'Antarktis', phoneCode: '+672' },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua og Barbuda',
    phoneCode: '+1-268',
  },
  { value: 'Argentina', label: 'Argentina', phoneCode: '+54' },
  { value: 'Armenia', label: 'Armenia', phoneCode: '+374' },
  { value: 'Aruba', label: 'Aruba', phoneCode: '+297' },
  { value: 'Australia', label: 'Australia', phoneCode: '+61' },
  { value: 'Austria', label: 'Østerrike', phoneCode: '+43' },
  { value: 'Azerbaijan', label: 'Aserbajdsjan', phoneCode: '+994' },
  { value: 'Bahamas', label: 'Bahamas', phoneCode: '+1-242' },
  { value: 'Bahrain', label: 'Bahrain', phoneCode: '+973' },
  { value: 'Bangladesh', label: 'Bangladesh', phoneCode: '+880' },
  { value: 'Barbados', label: 'Barbados', phoneCode: '+1-246' },
  { value: 'Belarus', label: 'Hviterussland', phoneCode: '+375' },
  { value: 'Belgium', label: 'Belgia', phoneCode: '+32' },
  { value: 'Belize', label: 'Belize', phoneCode: '+501' },
  { value: 'Benin', label: 'Benin', phoneCode: '+229' },
  { value: 'Bermuda', label: 'Bermuda', phoneCode: '+1-441' },
  { value: 'Bhutan', label: 'Bhutan', phoneCode: '+975' },
  { value: 'Bolivia', label: 'Bolivia', phoneCode: '+591' },
  {
    value: 'Bosnia-Herzegovina',
    label: 'Bosnia-Hercegovina',
    phoneCode: '+387',
  },
  { value: 'Botswana', label: 'Botswana', phoneCode: '+267' },
  { value: 'Bouvet Island', label: 'Bouvetøya', phoneCode: '+47' },
  { value: 'Brazil', label: 'Brasil', phoneCode: '+55' },
  {
    value: 'British Indian Ocean Territory',
    label: 'Britisk territorium i Indiahavet',
    phoneCode: '+246',
  },
  { value: 'Brunei Darussalam', label: 'Brunei', phoneCode: '+673' },
  { value: 'Bulgaria', label: 'Bulgaria', phoneCode: '+359' },
  { value: 'Burkina Faso', label: 'Burkina Faso', phoneCode: '+226' },
  { value: 'Burundi', label: 'Burundi', phoneCode: '+257' },
  { value: 'Cambodia', label: 'Kambodsja', phoneCode: '+855' },
  { value: 'Cameroon', label: 'Kamerun', phoneCode: '+237' },
  { value: 'Canada', label: 'Canada', phoneCode: '+1' },
  { value: 'Cape Verde', label: 'Cape Verde', phoneCode: '+238' },
  { value: 'Cayman Islands', label: 'Caymanøyene', phoneCode: '+1-345' },
  {
    value: 'Central African Republic',
    label: 'Den sentralafrikanske republikk',
    phoneCode: '+236',
  },
  { value: 'Chad', label: 'Tsjad', phoneCode: '+235' },
  { value: 'Chile', label: 'Chile', phoneCode: '+56' },
  { value: 'China', label: 'Kina', phoneCode: '+86' },
  { value: 'Christmas Island', label: 'Christmasøya', phoneCode: '+61' },
  { value: 'Cocos Islands', label: 'Cocos Islands', phoneCode: '+61' },
  { value: 'Colombia', label: 'Colombia', phoneCode: '+57' },
  { value: 'Comoros', label: 'Komorene', phoneCode: '+269' },
  { value: 'Congo', label: 'Kongo', phoneCode: '+242' },
  {
    value: "Congo, Democratic People's Republic",
    label: "Congo, Democratic People's Republic",
    phoneCode: '+243',
  },
  { value: 'Cook Islands', label: 'Cookøyene', phoneCode: '+682' },
  { value: 'Costa Rica', label: 'Costa Rica', phoneCode: '+506' },
  { value: 'Croatia', label: 'Kroatia', phoneCode: '+385' },
  { value: 'Cuba', label: 'Cuba', phoneCode: '+53' },
  { value: 'Cyprus', label: 'Kypros', phoneCode: '+357' },
  { value: 'Czech Republic', label: 'Tsjekkia', phoneCode: '+420' },
  { value: 'Denmark', label: 'Danmark', phoneCode: '+45' },
  { value: 'Djibouti', label: 'Djibouti', phoneCode: '+253' },
  { value: 'Dominica', label: 'Dominica', phoneCode: '+1-767' },
  {
    value: 'Dominican Republic',
    label: 'Den dominikanske republikk',
    phoneCode: '+1-809',
  },
  { value: 'Ecuador', label: 'Ecuador', phoneCode: '+593' },
  { value: 'Egypt', label: 'Egypt', phoneCode: '+20' },
  { value: 'El Salvador', label: 'El Salvador', phoneCode: '+503' },
  { value: 'England', label: 'England', phoneCode: '+44' },
  { value: 'Equatorial Guinea', label: 'Ekvatorial-Guinea', phoneCode: '+240' },
  { value: 'Eritrea', label: 'Eritrea', phoneCode: '+291' },
  { value: 'Estonia', label: 'Estland', phoneCode: '+372' },
  { value: 'Ethiopia', label: 'Etiopia', phoneCode: '+251' },
  { value: 'European Union', label: 'European Union', phoneCode: '+388' },
  { value: 'Falkland Islands', label: 'Falkland Islands', phoneCode: '+500' },
  { value: 'Faroe Islands', label: 'Færøyene', phoneCode: '+298' },
  { value: 'Fiji', label: 'Fiji', phoneCode: '+679' },
  { value: 'Finland', label: 'Finland', phoneCode: '+358' },
  { value: 'Former USSR', label: 'Former USSR', phoneCode: '' },
  { value: 'France', label: 'Frankrike', phoneCode: '+33' },
  {
    value: 'France. Metropolitan',
    label: 'France. Metropolitan',
    phoneCode: '',
  },
  { value: 'French Guyana', label: 'French Guyana', phoneCode: '' },
  {
    value: 'French Southern Territories',
    label: 'De franske sørterritorier',
    phoneCode: '+262',
  },
  { value: 'Gabon', label: 'Gabon', phoneCode: '+241' },
  { value: 'Gambia', label: 'Gambia', phoneCode: '+220' },
  { value: 'Georgia', label: 'Georgia', phoneCode: '+995' },
  { value: 'Germany', label: 'Tyskland', phoneCode: '+49' },
  { value: 'Ghana', label: 'Ghana', phoneCode: '+233' },
  { value: 'Gibraltar', label: 'Gibraltar', phoneCode: '+350' },
  { value: 'Great Britain', label: 'Great Britain', phoneCode: '+44' },
  { value: 'Greece', label: 'Hellas', phoneCode: '+30' },
  { value: 'Greenland', label: 'Grønland', phoneCode: '+299' },
  { value: 'Grenada', label: 'Grenada', phoneCode: '+1-473' },
  {
    value: 'Guadeloupe (French)',
    label: 'Guadeloupe (French)',
    phoneCode: '+590',
  },
  { value: 'Guam (USA)', label: 'Guam (USA)', phoneCode: '+1-671' },
  { value: 'Guatemala', label: 'Guatemala', phoneCode: '+502' },
  { value: 'Guernsey', label: 'Guernsey', phoneCode: '+44-1481' },
  { value: 'Guinea', label: 'Guinea', phoneCode: '+224' },
  { value: 'Guinea Bissau', label: 'Guinea Bissau', phoneCode: '+245' },
  { value: 'Guyana', label: 'Guyana', phoneCode: '+592' },
  { value: 'Haiti', label: 'Haiti', phoneCode: '+509' },
  {
    value: 'Heard & McDonald Islands',
    label: 'Heard & McDonald Islands',
    phoneCode: '+672',
  },
  { value: 'Honduras', label: 'Honduras', phoneCode: '+504' },
  { value: 'Hong Kong', label: 'Hongkong', phoneCode: '+852' },
  { value: 'Hungary', label: 'Ungarn', phoneCode: '+36' },
  { value: 'Iceland', label: 'Island', phoneCode: '+354' },
  { value: 'India', label: 'India', phoneCode: '+91' },
  { value: 'Indonesia', label: 'Indonesia', phoneCode: '+62' },
  { value: 'Iran', label: 'Iran', phoneCode: '+98' },
  { value: 'Iraq', label: 'Irak', phoneCode: '+964' },
  { value: 'Ireland', label: 'Irland', phoneCode: '+353' },
  { value: 'Isle of Man', label: 'Man', phoneCode: '+44-1624' },
  { value: 'Israel', label: 'Israel', phoneCode: '+972' },
  { value: 'Italy', label: 'Italia', phoneCode: '+39' },
  {
    value: "Ivory Coast (Cote D'Ivoire)",
    label: "Ivory Coast (Cote D'Ivoire)",
    phoneCode: '+225',
  },
  { value: 'Jamaica', label: 'Jamaica', phoneCode: '+1-876' },
  { value: 'Japan', label: 'Japan', phoneCode: '+81' },
  { value: 'Jersey', label: 'Jersey', phoneCode: '+44-1534' },
  { value: 'Jordan', label: 'Jordan', phoneCode: '+962' },
  { value: 'Kazakhstan', label: 'Kasakhstan', phoneCode: '+7' },
  { value: 'Kenya', label: 'Kenya', phoneCode: '+254' },
  { value: 'Kiribati', label: 'Kiribati', phoneCode: '+686' },
  { value: 'Kuwait', label: 'Kuwait', phoneCode: '+965' },
  { value: 'Kyrgyzstan', label: 'Kirgisistan', phoneCode: '+996' },
  { value: 'Laos', label: 'Laos', phoneCode: '+856' },
  { value: 'Latvia', label: 'Latvia', phoneCode: '+371' },
  { value: 'Lebanon', label: 'Libanon', phoneCode: '+961' },
  { value: 'Lesotho', label: 'Lesotho', phoneCode: '+266' },
  { value: 'Liberia', label: 'Liberia', phoneCode: '+231' },
  { value: 'Libya', label: 'Libya', phoneCode: '+218' },
  { value: 'Liechtenstein', label: 'Liechtenstein', phoneCode: '+423' },
  { value: 'Lithuania', label: 'Litauen', phoneCode: '+370' },
  { value: 'Luxembourg', label: 'Luxembourg', phoneCode: '+352' },
  { value: 'Macau', label: 'Macau', phoneCode: '+853' },
  { value: 'Macedonia', label: 'Macedonia', phoneCode: '+389' },
  { value: 'Madagascar', label: 'Madagaskar', phoneCode: '+261' },
  { value: 'Malawi', label: 'Malawi', phoneCode: '+265' },
  { value: 'Malaysia', label: 'Malaysia', phoneCode: '+60' },
  { value: 'Maldives', label: 'Maldivene', phoneCode: '+960' },
  { value: 'Mali', label: 'Mali', phoneCode: '+223' },
  { value: 'Malta', label: 'Malta', phoneCode: '+356' },
  { value: 'Marshall Islands', label: 'Marshalløyene', phoneCode: '+692' },
  {
    value: 'Martinique (French)',
    label: 'Martinique (French)',
    phoneCode: '+596',
  },
  { value: 'Mauritania', label: 'Mauritania', phoneCode: '+222' },
  { value: 'Mauritius', label: 'Mauritius', phoneCode: '+230' },
  { value: 'Mayotte', label: 'Mayotte', phoneCode: '+262' },
  { value: 'Mexico', label: 'Mexico', phoneCode: '+52' },
  { value: 'Micronesia', label: 'Micronesia', phoneCode: '+691' },
  { value: 'Moldavia', label: 'Moldavia', phoneCode: '+373' },
  { value: 'Monaco', label: 'Monaco', phoneCode: '+377' },
  { value: 'Mongolia', label: 'Mongolia', phoneCode: '+976' },
  { value: 'Montenegro', label: 'Montenegro', phoneCode: '+382' },
  { value: 'Montserrat', label: 'Montserrat', phoneCode: '+1-664' },
  { value: 'Morocco', label: 'Marokko', phoneCode: '+212' },
  { value: 'Mozambique', label: 'Mosambik', phoneCode: '+258' },
  { value: 'Myanmar', label: 'Myanmar', phoneCode: '+95' },
  { value: 'Namibia', label: 'Namibia', phoneCode: '+264' },
  { value: 'Nauru', label: 'Nauru', phoneCode: '+674' },
  { value: 'Nepal', label: 'Nepal', phoneCode: '+977' },
  { value: 'Netherlands', label: 'Nederland', phoneCode: '+31' },
  {
    value: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
    phoneCode: '+599',
  },
  {
    value: 'New Caledonia (French)',
    label: 'New Caledonia (French)',
    phoneCode: '+687',
  },
  { value: 'New Zealand', label: 'New Zealand', phoneCode: '+64' },
  { value: 'Nicaragua', label: 'Nicaragua', phoneCode: '+505' },
  { value: 'Niger', label: 'Niger', phoneCode: '+227' },
  { value: 'Nigeria', label: 'Nigeria', phoneCode: '+234' },
  { value: 'Niue', label: 'Niue', phoneCode: '+683' },
  { value: 'Norfolk Island', label: 'Norfolkøya', phoneCode: '+672' },
  { value: 'North Korea', label: 'North Korea', phoneCode: '+850' },
  { value: 'Northern Ireland', label: 'Northern Ireland', phoneCode: '+44' },
  {
    value: 'Northern Mariana Islands',
    label: 'Nord-Marianene',
    phoneCode: '+1-670',
  },
  { value: 'Norway', label: 'Norge', phoneCode: '+47' },
  { value: 'Oman', label: 'Oman', phoneCode: '+968' },
  { value: 'Pakistan', label: 'Pakistan', phoneCode: '+92' },
  { value: 'Palau', label: 'Palau', phoneCode: '+680' },
  {
    value: 'Palestinian Territory',
    label: 'Palestinian Territory',
    phoneCode: '+970',
  },
  { value: 'Panama', label: 'Panama', phoneCode: '+507' },
  { value: 'Papua New Guinea', label: 'Papua Ny-Guinea', phoneCode: '+675' },
  { value: 'Paraguay', label: 'Paraguay', phoneCode: '+595' },
  { value: 'Peru', label: 'Peru', phoneCode: '+51' },
  { value: 'Philippines', label: 'Filippinene', phoneCode: '+63' },
  { value: 'Pitcairn Island', label: 'Pitcairn Island', phoneCode: '+64' },
  { value: 'Poland', label: 'Polen', phoneCode: '+48' },
  {
    value: 'Polynesia (French)',
    label: 'Polynesia (French)',
    phoneCode: '+689',
  },
  { value: 'Portugal', label: 'Portugal', phoneCode: '+351' },
  { value: 'Private IP', label: 'Private IP', phoneCode: '' },
  { value: 'Puerto Rico', label: 'Puerto Rico', phoneCode: '+1-787' },
  { value: 'Qatar', label: 'Qatar', phoneCode: '+974' },
  { value: 'Reunion Island', label: 'Reunion Island', phoneCode: '+262' },
  { value: 'Romania', label: 'Romania', phoneCode: '+40' },
  { value: 'Russian Federation', label: 'Russland', phoneCode: '+7' },
  { value: 'Rwanda', label: 'Rwanda', phoneCode: '+250' },
  { value: 'Saint Helena', label: 'Saint Helena', phoneCode: '+290' },
  {
    value: 'Saint Kitts & Nevis Anguilla',
    label: 'Saint Kitts & Nevis Anguilla',
    phoneCode: '+1-869',
  },
  { value: 'Saint Lucia', label: 'Saint Lucia', phoneCode: '+1-758' },
  {
    value: 'Saint Pierre and Miquelon',
    label: 'Saint-Pierre og Miquelon',
    phoneCode: '+508',
  },
  {
    value: 'Saint Tome (Sao Tome) and Principe',
    label: 'Saint Tome (Sao Tome) and Principe',
    phoneCode: '+239',
  },
  {
    value: 'Saint Vincent & Grenadines',
    label: 'Saint Vincent & Grenadines',
    phoneCode: '+1-784',
  },
  { value: 'Samoa', label: 'Samoa', phoneCode: '+685' },
  { value: 'San Marino', label: 'San Marino', phoneCode: '+378' },
  { value: 'Saudi Arabia', label: 'Saudi-Arabia', phoneCode: '+966' },
  { value: 'Scotland', label: 'Scotland', phoneCode: '+44' },
  { value: 'Senegal', label: 'Senegal', phoneCode: '+221' },
  { value: 'Serbia', label: 'Serbia', phoneCode: '+381' },
  { value: 'Seychelles', label: 'Seychellene', phoneCode: '+248' },
  { value: 'Sierra Leone', label: 'Sierra Leone', phoneCode: '+232' },
  { value: 'Singapore', label: 'Singapore', phoneCode: '+65' },
  { value: 'Slovak Republic', label: 'Slovak Republic', phoneCode: '+421' },
  { value: 'Slovenia', label: 'Slovenia', phoneCode: '+386' },
  { value: 'Solomon Islands', label: 'Salomonøyene', phoneCode: '+677' },
  { value: 'Somalia', label: 'Somalia', phoneCode: '+252' },
  { value: 'South Africa', label: 'Sør-Afrika', phoneCode: '+27' },
  {
    value: 'South Georgia & Sandwich Islands',
    label: 'South Georgia & Sandwich Islands',
    phoneCode: '+500',
  },
  { value: 'South Korea', label: 'South Korea', phoneCode: '+82' },
  { value: 'Spain', label: 'Spania', phoneCode: '+34' },
  { value: 'Sri Lanka', label: 'Sri Lanka', phoneCode: '+94' },
  { value: 'Sudan', label: 'Sudan', phoneCode: '+249' },
  { value: 'Suriname', label: 'Surinam', phoneCode: '+597' },
  {
    value: 'Svalbard and Jan Mayen Islands',
    label: 'Svalbard and Jan Mayen Islands',
    phoneCode: '+47',
  },
  { value: 'Swaziland', label: 'Swaziland', phoneCode: '+268' },
  { value: 'Sweden', label: 'Sverige', phoneCode: '+46' },
  { value: 'Switzerland', label: 'Sveits', phoneCode: '+41' },
  { value: 'Syria', label: 'Syria', phoneCode: '+963' },
  { value: 'Tadjikistan', label: 'Tadjikistan', phoneCode: '+992' },
  { value: 'Taiwan', label: 'Taiwan', phoneCode: '+886' },
  { value: 'Tanzania', label: 'Tanzania', phoneCode: '+255' },
  { value: 'Thailand', label: 'Thailand', phoneCode: '+66' },
  { value: 'Tibet', label: 'Tibet', phoneCode: '' },
  { value: 'Timor-Leste', label: 'Øst-Timor', phoneCode: '+670' },
  { value: 'Togo', label: 'Togo', phoneCode: '+228' },
  { value: 'Tokelau', label: 'Tokelau', phoneCode: '+690' },
  { value: 'Tonga', label: 'Tonga', phoneCode: '+676' },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad og Tobago',
    phoneCode: '+1-868',
  },
  { value: 'Tunisia', label: 'Tunisia', phoneCode: '+216' },
  { value: 'Turkey', label: 'Tyrkia', phoneCode: '+90' },
  { value: 'Turkmenistan', label: 'Turkmenistan', phoneCode: '+993' },
  {
    value: 'Turks and Caicos Islands',
    label: 'Turks- og Caicosøyene',
    phoneCode: '+1-649',
  },
  { value: 'Tuvalu', label: 'Tuvalu', phoneCode: '+688' },
  { value: 'Uganda', label: 'Uganda', phoneCode: '+256' },
  { value: 'Ukraine', label: 'Ukraina', phoneCode: '+380' },
  {
    value: 'United Arab Emirates',
    label: 'De forente arabiske emirater',
    phoneCode: '+971',
  },
  { value: 'United Kingdom', label: 'Storbritannia', phoneCode: '+44' },
  { value: 'United Nations', label: 'United Nations', phoneCode: '' },
  { value: 'United States', label: 'USA', phoneCode: '+1' },
  {
    value: 'United States Minor Outlying Islands',
    label: 'USAs ytre småøyer',
    phoneCode: '+1',
  },
  { value: 'Unknown', label: 'Unknown', phoneCode: '' },
  { value: 'Uruguay', label: 'Uruguay', phoneCode: '+598' },
  { value: 'Uzbekistan', label: 'Usbekistan', phoneCode: '+998' },
  { value: 'Vanuatu', label: 'Vanuatu', phoneCode: '+678' },
  {
    value: 'Vatican City State',
    label: 'Vatican City State',
    phoneCode: '+379',
  },
  { value: 'Venezuela', label: 'Venezuela', phoneCode: '+58' },
  { value: 'Vietnam', label: 'Vietnam', phoneCode: '+84' },
  {
    value: 'Virgin Islands (British)',
    label: 'Virgin Islands (British)',
    phoneCode: '+1-284',
  },
  {
    value: 'Virgin Islands (USA)',
    label: 'Virgin Islands (USA)',
    phoneCode: '+1-340',
  },
  { value: 'Wales', label: 'Wales', phoneCode: '+44' },
  {
    value: 'Wallis and Futuna Islands',
    label: 'Wallis and Futuna Islands',
    phoneCode: '+681',
  },
  { value: 'Western Sahara', label: 'Vest-Sahara', phoneCode: '+212' },
  { value: 'Yemen', label: 'Jemen', phoneCode: '+967' },
  { value: 'Yugoslavia', label: 'Yugoslavia', phoneCode: '' },
  { value: 'Zaire', label: 'Zaire', phoneCode: '+243' },
  { value: 'Zambia', label: 'Zambia', phoneCode: '+260' },
  { value: 'Zimbabwe', label: 'Zimbabwe', phoneCode: '+263' },
];
