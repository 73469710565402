import React, { useEffect, useState, useContext, useRef } from 'react';
import { TextField } from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalEditUserLimits() {
  const { t } = useTranslation();

  const {
    session,
    updateShowModalEditUserLimits,
    updateFocusOnMainScreen,
    userInfo,
    parentInfo,
    operator,
    hall,
    currentUser,
    userLimits,
  } = useContext(AppContext);

  const [isUserConfirmed, setIsUserConfirmed] = useState('');
  // #region HANDLERS
  //* INPUT HANDLERS
  const [limitDaily, setLimitDaily] = useState('');
  const handleCashInChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setLimitDaily(numericValue);

    //setLimitDaily(event.target.value);
  };
  const limitDailyRef = useRef(limitDaily);
  useEffect(() => {
    limitDailyRef.current = limitDaily;
  }, [limitDaily]);

  const [limitMonthly, setLimitMonthly] = useState('');
  const handleCashOutChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setLimitMonthly(numericValue);
  };
  const limitMonthlyRef = useRef(limitMonthly);
  useEffect(() => {
    limitMonthlyRef.current = limitMonthly;
  }, [limitMonthly]);

  useEffect(() => {
    setLimitDaily(userLimits.limitDaily);
    setLimitMonthly(userLimits.limitMonthly);
  }, [userLimits]);

  const inputRef = useRef(null);

  useEffect(() => {
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 600);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // #region SOCKETS
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleUpdateUserLimits = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      setIsUserConfirmed(true);
    };

    socket.on('updateUserLimits', handleUpdateUserLimits);

    return () => {
      socket.off('updateUserLimits', handleUpdateUserLimits);
    };
  }, [socket]);

  // #region LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handleConfirm = async () => {
    if (!limitDaily || !limitMonthly) {
      toast.error(t('error.errorEmptyLimits'));
      return;
    }

    if (parseInt(limitDailyRef.current) >= parseInt(limitMonthlyRef.current)) {
      toast.error(t('error.errorLimitSize'));
      return;
    }

    var dataToSend = {
      userInfo: parentInfo ? parentInfo : userInfo,
      user7Pincode: parentInfo ? parentInfo['7 digit PIN'] : currentUser,
      limitDaily: limitDailyRef.current,
      limitMonthly: limitMonthlyRef.current,
      action: 'updateUserLimits',
      session: session,
    };
    socket.emit('updateUserLimits', dataToSend);
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    updateFocusOnMainScreen(true);
    updateShowModalEditUserLimits(false);
  };

  return (
    <div id="react-confirm-alert">
      <div class="react-confirm-alert-overlay">
        <div class="react-confirm-alert">
          {isUserConfirmed ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.limitsUpdated')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    cancel();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : (
            <>
              <div class="react-confirm-alert-body" style={{ width: '1000px' }}>
                <h1>{t('sales.userLimits')}</h1>

                <TextField
                  autoComplete="off"
                  label={t('sales.limitDaily')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleCashInChange}
                  className="modal-textfield"
                  value={limitDaily}
                  inputRef={inputRef}
                />
                <TextField
                  autoComplete="off"
                  label={t('sales.limitMonthly')}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={(e) => {
                    handleCashOutChange(e);
                  }}
                  className="modal-textfield"
                  value={limitMonthly}
                />

                <div className="react-confirm-alert-button-group">
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      handleConfirm();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.confirm')}
                  </button>
                  <button
                    className="btn-custom-no"
                    onClick={() => {
                      cancel();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CancelIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.cancel')}
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalEditUserLimits;
