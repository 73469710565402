import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import { Person, QrCode as QrCodeIcon } from "@mui/icons-material";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast from "react-hot-toast";
import {
  Star as StarIcon,
  StarOutline as StarOutlineIcon,
} from "@mui/icons-material";

function LastUsersFiltered() {
  const { socket } = useContext(SocketContext);
  const { t } = useTranslation();

  const {
    hall,
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsHybridUser,
    updateIsAnonUser,
    updateIsFullUser,
    updateIsFastUserLogin,
    operator,
    updateUserInfo,
    updateUserChildren,
    updateUserSiblings,
    favUsers,
    updateFavUsers,
    allUsers,
    userToSearch,
    updateUserToSearch,
    updateUserResults,
  } = useContext(AppContext);

  const [filterUsers, setFilterUsers] = useState([]);
  const [mixUsers, setMixUsers] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);

  useEffect(() => {
    const favUsersWithFlag = favUsers.map((user) => ({
      ...user,
      isFav: true,
    }));
    setMixUsers([...favUsersWithFlag, ...allUsers]);
  }, [favUsers, allUsers]);

  useEffect(() => {
    if (mixUsers.length === 0) {
      return;
    }
    const lowerCaseSearch = userToSearch.toLowerCase();
    const filteredUsers = mixUsers.filter((user) => {
      const userFirstname = user.firstname ? user.firstname.toLowerCase() : "";
      const userUsername = user.username ? user.username.toLowerCase() : "";

      return (
        userFirstname.includes(lowerCaseSearch) ||
        userUsername.includes(lowerCaseSearch)
      );
    });

    setFilterUsers(filteredUsers);
  }, [userToSearch, allUsers, mixUsers]);

  useEffect(() => {
    const uniqueUsersArray = [];
    const seenPincodes = new Set();

    filterUsers.forEach((user) => {
      if (!seenPincodes.has(user.user7Pincode)) {
        seenPincodes.add(user.user7Pincode);
        uniqueUsersArray.push(user);
      }
    });
    setUniqueUsers(uniqueUsersArray);
  }, [filterUsers]);

  useEffect(() => {
    const handleSearchMember = (data) => {
      if (!data || !data.records) {
        toast.error(t("error.error"));
        return;
      }
      if (data.records.length === 0 || data.records.length > 1) {
        return;
      }
      updateUserToSearch("");
      updateUserResults("");
      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]["7 digit PIN"]);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      if (
        data.records[0].username.slice(0, 4) === "anon" ||
        (data.records[0].firstname === "" && data.records[0].mobile === "")
      ) {
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
      } else if (data.records[0].username.slice(0, 6) === "hybrid") {
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(true);

      var dataToRegister = {
        action: "registerLastUsers",
        operator: operator,
        bingoName: hall,
        userInfo: data.records[0],
      };
      registerLastUsers(dataToRegister);
    };

    const handleAddFavourite = (data) => {
      //setLastUsers(data);
      if (!data) {
        updateFavUsers([]);
        return;
      }
      data.sort((a, b) => new Date(b.date) - new Date(a.date));

      updateFavUsers(data);
    };

    const handleRemoveFavourite = (data) => {
      //setLastUsers(data);
      if (!data) {
        updateFavUsers([]);
        return;
      }
      data.sort((a, b) => new Date(b.date) - new Date(a.date));

      updateFavUsers(data);
    };

    socket.on("addFavourite", handleAddFavourite);
    socket.on("removeFavourite", handleRemoveFavourite);
    socket.on("searchMember", handleSearchMember);

    return () => {
      socket.off("addFavourite", handleAddFavourite);
      socket.off("removeFavourite", handleRemoveFavourite);
      socket.off("searchMember", handleSearchMember);
    };
  }, [socket]);

  const registerLastUsers = (data) => {
    socket.emit("registerLastUsers", data);
  };

  const addToFavourite = (user) => {
    if (isPincodePresent(favUsers, user)) {
      var data = {
        userInfo: user.userInfo,
        operator: operator,
        bingoName: hall,
        action: "removeFavourite",
        username: user.username,
        lastname: user.lastname,
        phonenumber: user.phonenumber,
        userID: user.userID,
        firstname: user.firstname,
        user7Pincode: user.user7Pincode,
      };
      socket.emit("removeFavourite", data);
      return;
    }

    var data = {
      userInfo: user.userInfo,
      operator: operator,
      bingoName: hall,
      action: "addFavourite",
      username: user.username,
      lastname: user.lastname,
      phonenumber: user.phonenumber,
      userID: user.userID,
      firstname: user.firstname,
      user7Pincode: user.user7Pincode,
    };
    socket.emit("addFavourite", data);
  };

  function isPincodePresent(array, objectToCheck) {
    for (const obj of array) {
      if (obj.user7Pincode === objectToCheck.user7Pincode) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {/* TRANS CONTAINER */}
      <Grid
        item
        xs={12}
        sx={{
          marginTop: "25px",
          marginBottom: "15px",
          height: "calc(100% - 95px)",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          backgroundColor: "#15192b",
          borderRadius: "15px",
          padding: "15px",
        }}
      >
        {/*Transactions*/}
        <Grid
          container
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignContent: "flex-start",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              height: "75px",
              width: "100%",
              padding: "0px 20px",
            }}
          >
            <h2> {t("sales.lastUsers")}</h2>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              height: "calc(100% - 100px)",
              width: "100%",
              padding: "0px 22px",
              marginTop: "0px",
              overflow: "auto",
            }}
            className="hide-scrollbar"
          >
            {uniqueUsers.length > 0 && (
              <>
                {uniqueUsers.map((user, index) => {
                  return (
                    <div
                      style={{
                        borderBottom: "1px solid white",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      key={index}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        onClick={() => {
                          //SEARCH USER
                          var data = {
                            searchByPin: true,
                            auth_value: user.user7Pincode,
                            action: "searchMember",
                            session: session,
                          };
                          socket.emit("searchMember", data);
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "19px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Person
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />{" "}
                          {user.firstname ? user.firstname : user.firstname}{" "}
                          {user.lastname ? user.lastname : ""}
                        </h2>

                        <p
                          style={{
                            fontSize: "17px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",

                            margin: "0px 0px 10px",
                            padding: "0px 0px",
                          }}
                        >
                          <QrCodeIcon
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />{" "}
                          {user.user7Pincode}
                        </p>
                      </div>
                      {user.isFav ? (
                        <p
                          style={{
                            fontSize: "17px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            addToFavourite(user);
                          }}
                        >
                          <StarIcon
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: "17px",
                            lineHeight: "normal",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onClick={() => {
                            addToFavourite(user);
                          }}
                        >
                          <StarOutlineIcon
                            sx={{ fontSize: "22px", marginRight: "8px" }}
                          />
                        </p>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default LastUsersFiltered;
