import React, { useContext } from "react";
import { Grid } from "@mui/material";

import VendorSlider from "./Slider/VendorSlider";

import VendorItem from "./VendorItem";
import SellScreen from "../SellScreen/SellScreen";
import OcrScreen from "../OcrScreen/OrcScreen";
import PaymentScreen from "../PaymentScreen/PaymentScreen";

import { AppContext } from "../../../context/Context";

function Vendors() {
  const { isSellerOpen, isPaymentOpen, isOcrOpen } = useContext(AppContext);

  return (
    <>
      {!isSellerOpen && !isOcrOpen && !isPaymentOpen && (
        <>
          <Grid
            container
            flexDirection="row"
            sx={{
              height: "calc(100% - 175px)",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
            }}
          >
            <VendorItem />
          </Grid>
          <VendorSlider />
        </>
      )}{" "}
      {isSellerOpen && (
        <Grid
          container
          flexDirection="row"
          sx={{
            height: "100%",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
          }}
        >
          <SellScreen />
        </Grid>
      )}
      {isOcrOpen && (
        <Grid
          container
          flexDirection="row"
          sx={{
            height: "100%",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
          }}
        >
          <OcrScreen />
        </Grid>
      )}
      {isPaymentOpen && (
        <Grid
          container
          flexDirection="row"
          sx={{
            height: "100%",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
          }}
        >
          <PaymentScreen />
        </Grid>
      )}
    </>
  );
}

export default Vendors;
