import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import BlurTable from '../../../assets/img/blurTable.jpg';

import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../assets/css/buttons.css';

function SellScreenListEBS({ updateAmountShortcut, isTab, isRefill }) {
  const { t } = useTranslation();

  const {
    userSiblings,
    userChildren,
    session,
    operator,
    hall,
    updateUserLogin,
    currentUser,
    ticketsCH,
    userInfo,
    parentInfo,
    ticketsEBS,
    updateTicketsEBS,
    currentTicketEBS,
    updateCurrentTicketEBS,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    socket.emit('ticketsEBS', {
      operator: operator,
      bingoName: hall,
      userID: parentInfo ? parentInfo.id : userInfo.id,
      userInfo: parentInfo ? parentInfo : userInfo,
      session: session,
      action: 'ticketsEBS',
    });
  }, []);

  useEffect(() => {
    const handleTicketsEBS = (data) => {
      if (data.error) {
        toast.error(t('error.error'));
        return;
      }

      updateTicketsEBS(data.ticketsEBS);
    };

    // Adding event listeners
    socket.on('ticketsEBS', handleTicketsEBS);

    // Cleanup function
    return () => {
      socket.off('ticketsEBS', handleTicketsEBS);
    };
  }, [socket]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const activateTicket = (ticket) => {
    updateCurrentTicketEBS(ticket);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: '10px',
        height: '555px',
        width: '100%',
        marginTop: '40px',
        marginLeft: 0,
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!isTab && (
        <h2 style={{ textAlign: 'left', width: '100%' }}>
          {t('sales.payoutTicket')}
        </h2>
      )}

      <Grid
        container
        flexDirection="row"
        sx={{
          height: '100%',
          width: '100%',
          marginTop: 0,
          marginLeft: 0,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: '2px solid black',
            width: '100%',
            maxWidth: '100% !important',
            height: '535px',
            backgroundColor: '#232a45',
          }}
        >
          {false ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundImage: `url(${BlurTable})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  padding: '20px',
                  textAlign: 'center',
                }}
              >
                {t('sales.unblock')}
              </p>
              <Button
                variant="contained"
                component="label"
                sx={{
                  height: '90px',
                  width: '150px',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  backgroundColor: '#42a5f5',
                  color: 'white',
                  marginTop: '0px',
                }}
                onClick={() => {
                  //updateAmountShortcut(10);
                }}
              >
                {t('sales.send')}
              </Button>
            </Box>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                /*maxHeight: "calc(100% - 200px)",
                marginBottom: "200px",*/
                height: '535px',
                overflowY: 'scroll', // Enables vertical scrolling
                scrollbarWidth: 'none', // For Firefox
                '&::-webkit-scrollbar': {
                  display: 'none', // For Chrome, Safari, and Opera
                },
              }}
            >
              <Table
                sx={{
                  color: 'white',
                  background: '#1f2c67',
                }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    background: '#1f2c67',
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {t('apneBilleter.ticketID')}
                    </TableCell>
                    <TableCell align="center">
                      {t('sales.currentSaldo')}
                    </TableCell>

                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketsEBS && ticketsEBS.length > 0 && (
                    <>
                      {ticketsEBS.map((ticket, index) =>
                        ticket.isCashedOut ? null : (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#232a45',
                              },
                              '&:nth-of-type(even)': {
                                backgroundColor: '#1a2036',
                              },
                            }}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              sx={{
                                color:
                                  currentUser === ticket.ticket7Pincode
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.ebsID}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                color:
                                  currentUser === ticket.ticket7Pincode
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.currentBalance}
                            </TableCell>

                            <TableCell align="center">
                              {currentTicketEBS.ebsID !== ticket.ebsID ? (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    if (
                                      ticket.currentBalance === 0 &&
                                      !isRefill
                                    ) {
                                      return;
                                    }
                                    activateTicket(ticket);
                                  }}
                                >
                                  {!isRefill
                                    ? t('sales.payout')
                                    : t('sales.refill')}
                                </Button>
                              ) : (
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="success"
                                  onClick={() => {
                                    if (
                                      ticket.currentBalance === 0 &&
                                      !isRefill
                                    ) {
                                      return;
                                    }
                                    activateTicket(ticket);
                                  }}
                                >
                                  {t('sales.selected')}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellScreenListEBS;
