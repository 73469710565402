import { useTranslation } from "react-i18next";

const useRoutes = () => {
  const { t } = useTranslation();

  const routes = [
    {
      name: "login",
      route: "/login",
    },

    {
      name: t("header.sales"),
      route: "/salgs",
    },

    {
      name: t("header.recent"),
      route: "/siste-salgs",
    },
    {
      name: t("header.lastCardTransactions"),
      route: "/kort-transaksjoner",
    },
    /*
    {
      name: t("header.lastTransactions"),
      route: "/siste-transaksjoner",
    },
    */
    {
      name: t("header.openTickets"),
      route: "/apne-billeter",
    },
    {
      name: t("header.ticketHistoric"),
      route: "/billet-historikk",
    },

    /*
    {
      name: t("header.newMember"),
      route: "/nytt-medlem",
    },
    {
      name: t("header.searchMember"),
      route: "/sok-medlemmer",
    },
    {
      name: t("header.onlinePayment"),
      route: "/online-utbetaling",
    },
    {
      name: t("header.onlinePaymentHistory"),
      route: "/online-utbetaling-historikk",
    },
    
    {
      name: t("header.report"),
      route: "/rapport",
    },
    {
      name: t("header.playerWithBalanceReport"),
      route: "/spiller-med-balande-rapport",
    },
    {
      name: t("header.adminReport"),
      route: "/admin-rapport",
    },
    */
    {
      name: t("header.endShift"),
      route: "/avslutte-skiftet",
    },
  ];

  return routes;
};

export default useRoutes;
