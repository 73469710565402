import React, { useContext } from "react";
import { Grid, Button } from "@mui/material";
import { AppContext } from "../../context/Context";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/buttons.css";

function Multipliers({ isTicketCH }) {
  const {
    isMultiplier,
    isMultiplier2,
    isMultiplier3,
    isMultiplier4,
    isMultiplier5,
    isMultiplier6,
    isMultiplier7,
    isMultiplier8,
    isMultiplier9,
    isMultiplier10,
    updateIsMultiplier,
    updateIsMultiplier2,
    updateIsMultiplier3,
    updateIsMultiplier4,
    updateIsMultiplier5,
    updateIsMultiplier6,
    updateIsMultiplier7,
    updateIsMultiplier8,
    updateIsMultiplier9,
    updateIsMultiplier10,
  } = useContext(AppContext);

  const modalConfirm = () => {
    confirmAlert({
      title: "Multiplier",
      message: "",
      buttons: [
        {
          label: "x1",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x2",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x3",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x4",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x5",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x6",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x7",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x8",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x9",
          onClick: () => {},
          className: "btn-custom-yes",
        },
        {
          label: "x10",
          onClick: () => {},
          className: "btn-custom-yes",
        },
      ],

      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body react-confirm-alert-body-big">
            <div className="react-confirm-alert-button-group">
              <Grid
                container
                sx={{
                  height: "100%",
                  width: "100%",
                  marginTop: 0,
                  marginLeft: 0,
                  justifyContent: "center",
                  alignContent: "center",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier2 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 2) {
                        updateIsMultiplier(2);
                        updateIsMultiplier2(true);
                        updateIsMultiplier3(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier10(false);
                      }
                    }}
                  >
                    x2
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier3 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 3) {
                        updateIsMultiplier(3);
                        updateIsMultiplier3(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier10(false);
                      }
                    }}
                  >
                    x3
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier4 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 4) {
                        updateIsMultiplier(4);
                        updateIsMultiplier4(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier3(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier10(false);
                      }
                    }}
                  >
                    x4
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier5 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 5) {
                        updateIsMultiplier(5);
                        updateIsMultiplier3(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier3(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier10(false);
                      }
                    }}
                  >
                    x5
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier6 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 6) {
                        updateIsMultiplier(6);
                        updateIsMultiplier6(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier3(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier10(false);
                      }
                    }}
                  >
                    x6
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier7 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 7) {
                        updateIsMultiplier(7);
                        updateIsMultiplier7(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier10(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier3(false);
                      }
                    }}
                  >
                    x7
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier8 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 8) {
                        updateIsMultiplier(8);
                        updateIsMultiplier8(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier10(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier3(false);
                      }
                    }}
                  >
                    x8
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier9 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 9) {
                        updateIsMultiplier(9);
                        updateIsMultiplier9(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier10(false);
                        updateIsMultiplier3(false);
                      }
                    }}
                  >
                    x9
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor: !isMultiplier10 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 10) {
                        updateIsMultiplier(10);
                        updateIsMultiplier10(true);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier3(false);
                      }
                    }}
                  >
                    x10
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    padding: "10px",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      height: "135px",
                      width: "135px",
                      fontSize: "35px",
                      fontWeight: "bold",
                      backgroundColor:
                        isMultiplier !== 1 ? "#889cab" : "#42a5f5",
                      color: "white",
                      marginTop: "0px",
                    }}
                    onClick={() => {
                      onClose();
                      if (isMultiplier !== 1) {
                        updateIsMultiplier(1);
                        updateIsMultiplier10(false);
                        updateIsMultiplier2(false);
                        updateIsMultiplier4(false);
                        updateIsMultiplier5(false);
                        updateIsMultiplier6(false);
                        updateIsMultiplier7(false);
                        updateIsMultiplier8(false);
                        updateIsMultiplier9(false);
                        updateIsMultiplier3(false);
                      }
                    }}
                  >
                    x1
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <>
      {isTicketCH ? (
        <>
          {/*Multipliers*/}
          <Grid
            item
            xs={12}
            sx={{
              padding: "10px",
              height: "150px",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              justifyContent: "right",
              alignContent: "right",
              display: "flex",
              alignItems: "center",
              marginTop: "0px",
            }}
          >
            <Grid
              container
              flexDirection="row"
              sx={{
                height: "100%",
                width: "150px",
                marginTop: 0,
                marginLeft: 0,
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  border: "2px solid black",
                  width: "100%",
                  maxWidth: "100% !important",
                  height: "100%",
                  backgroundColor: "#232a45",
                  display: "flex",
                }}
              >
                <Grid
                  container
                  flexDirection="row"
                  sx={{
                    height: "100%",
                    width: "150px",
                    marginTop: 0,
                    marginLeft: 0,
                    display: "flex",
                    alignContent: "space-around",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        height: "100px",
                        width: "100px",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        component="label"
                        sx={{
                          height: "100%",
                          width: "100px",
                          fontSize: "35px",
                          fontWeight: "bold",
                          backgroundColor:
                            isMultiplier === 1 ? "#889cab" : "#42a5f5",
                          color: "white",
                          marginTop: "0px",
                        }}
                        onClick={() => {
                          modalConfirm();
                        }}
                      >
                        {isMultiplier !== 1 && isMultiplier}x
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/*END Shortcuts*/}
        </>
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            padding: "10px",
            height: "150px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            justifyContent: "right",
            alignContent: "right",
            display: "flex",
            alignItems: "center",
            marginTop: "0px",
          }}
        ></Grid>
      )}
    </>
  );
}

export default Multipliers;
