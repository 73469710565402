import React from "react";
import { Grid, Button } from "@mui/material/";
import BackspaceIcon from "@mui/icons-material/Backspace";

function CalculatorItem({ value, updateAmount }) {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          height: "100px",
          width: "100px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {value !== null && (
          <Button
            variant="contained"
            component="label"
            sx={{
              height: "100%",
              width: "100%",
              fontSize: "35px",
              fontWeight: "bold",
              backgroundColor: value === "backspace" ? "#fc86c1;" : "#889cab",
              color: "white",
              marginTop: "20px",
            }}
            onClick={() => {
              updateAmount(value);
            }}
          >
            {value == "backspace" ? <BackspaceIcon /> : value}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default CalculatorItem;
