import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { TRANSLATIONS_NO } from "./no.js";
import { TRANSLATIONS_EN } from "./en.js";

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false }, // React already does escaping
  resources: {
    no: {
      translation: TRANSLATIONS_NO,
    },
    en: {
      translation: TRANSLATIONS_EN,
    },
  },
});

if (
  !localStorage.getItem("language") ||
  (localStorage.getItem("language") !== "no" &&
    localStorage.getItem("language") !== "en")
) {
  i18n.changeLanguage("no");
} else {
  i18n.changeLanguage(localStorage.getItem("language"));
}
