import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Skeleton,
} from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import BlurTable from '../../../assets/img/blurTable.jpg';

import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../assets/css/buttons.css';

function SellScreenListOKD({ updateAmountShortcut, isTab, isRefill }) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const loadingTable = Array.from({ length: 12 });

  const {
    OKBingoID,
    userSiblings,
    userChildren,
    session,
    operator,
    hall,
    updateUserLogin,
    currentUser,
    ticketsCH,
    userInfo,
    parentInfo,
    ticketsOK,
    updateTicketsOK,
    currentTicketOK,
    updateCurrentTicketOK,
    updateTicketsOKWithNewSaldo,
    isOKBingoEnabled,
    ticketsTempOK,
  } = useContext(AppContext);

  const OKBingoIDRef = useRef(OKBingoID);
  useEffect(() => {
    OKBingoIDRef.current = OKBingoID;
  }, [OKBingoID]);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!isOKBingoEnabled) {
      return;
    }
    socket.emit('ticketsOK', {
      operator: operator,
      bingoName: hall,
      userID: parentInfo ? parentInfo.id : userInfo.id,
      userInfo: parentInfo ? parentInfo : userInfo,
      session: session,
      action: 'ticketsOK',
      OKBingoID: OKBingoID,
    });
  }, []);

  useEffect(() => {
    if (!isOKBingoEnabled) {
      return;
    }
    if (!ticketsOK || ticketsOK.length === 0) {
      return;
    }
    for (let i = 0; i < ticketsOK.length; i++) {
      if (
        currentTicketOK.okID === ticketsOK[i].okID &&
        ticketsOK[i].amount &&
        !isRefill
      ) {
        updateCurrentTicketOK(ticketsOK[i]);
        updateAmountShortcut(ticketsOK[i].amount);
      }
    }
  }, [ticketsOK]);

  useEffect(() => {
    const handleTicketsOK = (data) => {
      if (data.error) {
        toast.error(t('error.error'));
        return;
      }

      if (!data.ticketsOK) {
        setLoading(false);
      }

      updateTicketsOK(data.ticketsOK);
    };

    const handleBroadcastGetTicketSaldoOK = (data) => {
      if (data.error) {
        toast.error(t('error.error'));
        return;
      }
      if (!data || data.length === 0) {
        return;
      }
      updateTicketsOKWithNewSaldo(data);
      setLoading(false);
      if (currentTicketOK.amount && !isRefill) {
        updateAmountShortcut(currentTicketOK.amount);
      }
    };

    socket.on('broadcastGetTicketSaldoOK', handleBroadcastGetTicketSaldoOK);
    socket.on('ticketsOK', handleTicketsOK);

    return () => {
      socket.off('broadcastGetTicketSaldoOK', handleBroadcastGetTicketSaldoOK);
      socket.off('ticketsOK', handleTicketsOK);
    };
  }, [socket]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const activateTicket = (ticket) => {
    if (!ticket.amount) {
      return;
    }
    if (!isRefill) {
      updateAmountShortcut(ticket.amount);
    }
    updateCurrentTicketOK(ticket);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: '10px',
        height: '555px',
        width: '100%',
        marginTop: '0',
        marginLeft: 0,
        justifyContent: 'center',
        alignContent: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {!isTab && (
        <h2 style={{ textAlign: 'left', width: '100%' }}>
          {t('sales.payoutTicket')}
        </h2>
      )}

      <Grid
        container
        flexDirection="row"
        sx={{
          height: '100%',
          width: '100%',
          marginTop: 0,
          marginLeft: 0,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: '2px solid black',
            width: '100%',
            maxWidth: '100% !important',
            height: '535px',
            backgroundColor: '#232a45',
          }}
        >
          {loading ? (
            <TableContainer
              component={Paper}
              sx={{
                height: '535px',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Table
                sx={{
                  color: 'white',
                  background: '#1f2c67',
                }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    background: '#1f2c67',
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {t('apneBilleter.ticketID')}
                    </TableCell>
                    <TableCell align="center">{t('sales.saldo')}</TableCell>

                    <TableCell align="center"> {t('sales.status')}</TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loadingTable.map((index) => (
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:nth-of-type(odd)': {
                          backgroundColor: '#232a45',
                        },
                        '&:nth-of-type(even)': {
                          backgroundColor: '#1a2036',
                        },
                      }}
                      key={index}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <Skeleton />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>

                      <TableCell align="center">
                        <Skeleton />
                      </TableCell>

                      <TableCell align="center">
                        {/* Button */}
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer
              component={Paper}
              sx={{
                height: '535px',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Table
                sx={{
                  color: 'white',
                  background: '#1f2c67',
                }}
                stickyHeader
                aria-label="simple table"
              >
                <TableHead
                  sx={{
                    background: '#1f2c67',
                  }}
                >
                  <TableRow>
                    <TableCell align="center">
                      {t('apneBilleter.ticketID')}
                    </TableCell>
                    <TableCell align="center">{t('sales.saldo')}</TableCell>

                    <TableCell align="center">{t('sales.status')} </TableCell>
                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketsOK && ticketsOK.length > 0 && (
                    <>
                      {ticketsOK.map((ticket, index) =>
                        ticket.isCashedOut ||
                        ticketsTempOK.some(
                          (ticketTemp) =>
                            ticketTemp.ticketNumber === ticket.okID
                        ) ? null : (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#232a45',
                              },
                              '&:nth-of-type(even)': {
                                backgroundColor: '#1a2036',
                              },
                            }}
                            key={index}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              align="center"
                              sx={{
                                color:
                                  currentTicketOK.okID === ticket.okID
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.okID}
                            </TableCell>

                            <TableCell
                              align="center"
                              sx={{
                                color:
                                  currentTicketOK.okID === ticket.okID
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.amount !== undefined &&
                              ticket.amount !== null
                                ? ticket.amount
                                : '-'}
                            </TableCell>

                            {/*Status*/}
                            <TableCell
                              align="center"
                              sx={{
                                color:
                                  currentTicketOK.okID === ticket.okID
                                    ? '#18ff18'
                                    : 'inherit',
                              }}
                            >
                              {ticket.errorNumber === 3 &&
                                ticket.errorDescription ===
                                  'Ticket er allerede lukket' &&
                                t('sales.closed')}

                              {(ticket.errorNumber === 2 &&
                                ticket.errorDescription ===
                                  'Ticket er stengt') ||
                                (ticket.isClosed && t('sales.closed'))}

                              {ticket.errorNumber === '' &&
                                ticket.errorDescription.includes(
                                  'Billett logget inn på Terminalnr:'
                                ) &&
                                t('sales.inGame')}

                              {ticket.errorNumber === '' &&
                                ticket.errorDescription === '' &&
                                t('sales.open')}
                            </TableCell>

                            <TableCell align="center">
                              {ticket.amount && (
                                <>
                                  {currentTicketOK.okID !== ticket.okID ? (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        if (ticket.amount === 0 && !isRefill) {
                                          return;
                                        }
                                        activateTicket(ticket);
                                      }}
                                    >
                                      {!isRefill
                                        ? t('sales.payout')
                                        : t('sales.refill')}
                                    </Button>
                                  ) : (
                                    <Button
                                      size="small"
                                      variant="outlined"
                                      color="success"
                                      onClick={() => {
                                        if (
                                          ticket.newSaldo === 0 &&
                                          !isRefill
                                        ) {
                                          return;
                                        }
                                        activateTicket(ticket);
                                      }}
                                    >
                                      {t('sales.selected')}
                                    </Button>
                                  )}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SellScreenListOKD;
