export const countriesDataEN = [
  { value: 'Afghanistan', label: 'Afghanistan', phoneCode: '+93' },
  { value: 'Aland Islands', label: 'Aland Islands', phoneCode: '+358' },
  { value: 'Albania', label: 'Albania', phoneCode: '+355' },
  { value: 'Algeria', label: 'Algeria', phoneCode: '+213' },
  { value: 'American Samoa', label: 'American Samoa', phoneCode: '+1-684' },
  { value: 'Andorra', label: 'Andorra', phoneCode: '+376' },
  { value: 'Angola', label: 'Angola', phoneCode: '+244' },
  { value: 'Anguilla', label: 'Anguilla', phoneCode: '+1-264' },
  { value: 'Antarctica', label: 'Antarctica', phoneCode: '+672' },
  {
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
    phoneCode: '+1-268',
  },
  { value: 'Argentina', label: 'Argentina', phoneCode: '+54' },
  { value: 'Armenia', label: 'Armenia', phoneCode: '+374' },
  { value: 'Aruba', label: 'Aruba', phoneCode: '+297' },
  { value: 'Australia', label: 'Australia', phoneCode: '+61' },
  { value: 'Austria', label: 'Austria', phoneCode: '+43' },
  { value: 'Azerbaijan', label: 'Azerbaijan', phoneCode: '+994' },
  { value: 'Bahamas', label: 'Bahamas', phoneCode: '+1-242' },
  { value: 'Bahrain', label: 'Bahrain', phoneCode: '+973' },
  { value: 'Bangladesh', label: 'Bangladesh', phoneCode: '+880' },
  { value: 'Barbados', label: 'Barbados', phoneCode: '+1-246' },
  { value: 'Belarus', label: 'Belarus', phoneCode: '+375' },
  { value: 'Belgium', label: 'Belgium', phoneCode: '+32' },
  { value: 'Belize', label: 'Belize', phoneCode: '+501' },
  { value: 'Benin', label: 'Benin', phoneCode: '+229' },
  { value: 'Bermuda', label: 'Bermuda', phoneCode: '+1-441' },
  { value: 'Bhutan', label: 'Bhutan', phoneCode: '+975' },
  { value: 'Bolivia', label: 'Bolivia', phoneCode: '+591' },
  {
    value: 'Bosnia-Herzegovina',
    label: 'Bosnia-Herzegovina',
    phoneCode: '+387',
  },
  { value: 'Botswana', label: 'Botswana', phoneCode: '+267' },
  { value: 'Bouvet Island', label: 'Bouvet Island', phoneCode: '+47' },
  { value: 'Brazil', label: 'Brazil', phoneCode: '+55' },
  {
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
    phoneCode: '+246',
  },
  { value: 'Brunei Darussalam', label: 'Brunei Darussalam', phoneCode: '+673' },
  { value: 'Bulgaria', label: 'Bulgaria', phoneCode: '+359' },
  { value: 'Burkina Faso', label: 'Burkina Faso', phoneCode: '+226' },
  { value: 'Burundi', label: 'Burundi', phoneCode: '+257' },
  { value: 'Cambodia', label: 'Cambodia', phoneCode: '+855' },
  { value: 'Cameroon', label: 'Cameroon', phoneCode: '+237' },
  { value: 'Canada', label: 'Canada', phoneCode: '+1' },
  { value: 'Cape Verde', label: 'Cape Verde', phoneCode: '+238' },
  { value: 'Cayman Islands', label: 'Cayman Islands', phoneCode: '+1-345' },
  {
    value: 'Central African Republic',
    label: 'Central African Republic',
    phoneCode: '+236',
  },
  { value: 'Chad', label: 'Chad', phoneCode: '+235' },
  { value: 'Chile', label: 'Chile', phoneCode: '+56' },
  { value: 'China', label: 'China', phoneCode: '+86' },
  { value: 'Christmas Island', label: 'Christmas Island', phoneCode: '+61' },
  { value: 'Cocos Islands', label: 'Cocos Islands', phoneCode: '+61' },
  { value: 'Colombia', label: 'Colombia', phoneCode: '+57' },
  { value: 'Comoros', label: 'Comoros', phoneCode: '+269' },
  { value: 'Congo', label: 'Congo', phoneCode: '+242' },
  {
    value: "Congo, Democratic People's Republic",
    label: "Congo, Democratic People's Republic",
    phoneCode: '+243',
  },
  { value: 'Cook Islands', label: 'Cook Islands', phoneCode: '+682' },
  { value: 'Costa Rica', label: 'Costa Rica', phoneCode: '+506' },
  { value: 'Croatia', label: 'Croatia', phoneCode: '+385' },
  { value: 'Cuba', label: 'Cuba', phoneCode: '+53' },
  { value: 'Cyprus', label: 'Cyprus', phoneCode: '+357' },
  { value: 'Czech Republic', label: 'Czech Republic', phoneCode: '+420' },
  { value: 'Denmark', label: 'Denmark', phoneCode: '+45' },
  { value: 'Djibouti', label: 'Djibouti', phoneCode: '+253' },
  { value: 'Dominica', label: 'Dominica', phoneCode: '+1-767' },
  {
    value: 'Dominican Republic',
    label: 'Dominican Republic',
    phoneCode: '+1-809',
  },
  { value: 'Ecuador', label: 'Ecuador', phoneCode: '+593' },
  { value: 'Egypt', label: 'Egypt', phoneCode: '+20' },
  { value: 'El Salvador', label: 'El Salvador', phoneCode: '+503' },
  { value: 'England', label: 'England', phoneCode: '+44' },
  { value: 'Equatorial Guinea', label: 'Equatorial Guinea', phoneCode: '+240' },
  { value: 'Eritrea', label: 'Eritrea', phoneCode: '+291' },
  { value: 'Estonia', label: 'Estonia', phoneCode: '+372' },
  { value: 'Ethiopia', label: 'Ethiopia', phoneCode: '+251' },
  { value: 'European Union', label: 'European Union', phoneCode: '+388' },
  { value: 'Falkland Islands', label: 'Falkland Islands', phoneCode: '+500' },
  { value: 'Faroe Islands', label: 'Faroe Islands', phoneCode: '+298' },
  { value: 'Fiji', label: 'Fiji', phoneCode: '+679' },
  { value: 'Finland', label: 'Finland', phoneCode: '+358' },
  { value: 'France', label: 'France', phoneCode: '+33' },
  { value: 'Gabon', label: 'Gabon', phoneCode: '+241' },
  { value: 'Gambia', label: 'Gambia', phoneCode: '+220' },
  { value: 'Georgia', label: 'Georgia', phoneCode: '+995' },
  { value: 'Germany', label: 'Germany', phoneCode: '+49' },
  { value: 'Ghana', label: 'Ghana', phoneCode: '+233' },
  { value: 'Gibraltar', label: 'Gibraltar', phoneCode: '+350' },
  { value: 'Greece', label: 'Greece', phoneCode: '+30' },
  { value: 'Greenland', label: 'Greenland', phoneCode: '+299' },
  { value: 'Grenada', label: 'Grenada', phoneCode: '+1-473' },
  { value: 'Guam (USA)', label: 'Guam (USA)', phoneCode: '+1-671' },
  { value: 'Guatemala', label: 'Guatemala', phoneCode: '+502' },
  { value: 'Guinea', label: 'Guinea', phoneCode: '+224' },
  { value: 'Guyana', label: 'Guyana', phoneCode: '+592' },
  { value: 'Haiti', label: 'Haiti', phoneCode: '+509' },
  { value: 'Honduras', label: 'Honduras', phoneCode: '+504' },
  { value: 'Hong Kong', label: 'Hong Kong', phoneCode: '+852' },
  { value: 'Hungary', label: 'Hungary', phoneCode: '+36' },
  { value: 'Iceland', label: 'Iceland', phoneCode: '+354' },
  { value: 'India', label: 'India', phoneCode: '+91' },
  { value: 'Indonesia', label: 'Indonesia', phoneCode: '+62' },
  { value: 'Iran', label: 'Iran', phoneCode: '+98' },
  { value: 'Iraq', label: 'Iraq', phoneCode: '+964' },
  { value: 'Ireland', label: 'Ireland', phoneCode: '+353' },
  { value: 'Israel', label: 'Israel', phoneCode: '+972' },
  { value: 'Italy', label: 'Italy', phoneCode: '+39' },
  { value: 'Jamaica', label: 'Jamaica', phoneCode: '+1-876' },
  { value: 'Japan', label: 'Japan', phoneCode: '+81' },
  { value: 'Jordan', label: 'Jordan', phoneCode: '+962' },
  { value: 'Kazakhstan', label: 'Kazakhstan', phoneCode: '+7' },
  { value: 'Kenya', label: 'Kenya', phoneCode: '+254' },
  { value: 'Kuwait', label: 'Kuwait', phoneCode: '+965' },
  { value: 'Kyrgyzstan', label: 'Kyrgyzstan', phoneCode: '+996' },
  { value: 'Laos', label: 'Laos', phoneCode: '+856' },
  { value: 'Latvia', label: 'Latvia', phoneCode: '+371' },
  { value: 'Lebanon', label: 'Lebanon', phoneCode: '+961' },
  { value: 'Lesotho', label: 'Lesotho', phoneCode: '+266' },
  { value: 'Liberia', label: 'Liberia', phoneCode: '+231' },
  { value: 'Libya', label: 'Libya', phoneCode: '+218' },
  { value: 'Lithuania', label: 'Lithuania', phoneCode: '+370' },
  { value: 'Luxembourg', label: 'Luxembourg', phoneCode: '+352' },
  { value: 'Macau', label: 'Macau', phoneCode: '+853' },
  { value: 'Madagascar', label: 'Madagascar', phoneCode: '+261' },
  { value: 'Malawi', label: 'Malawi', phoneCode: '+265' },
  { value: 'Malaysia', label: 'Malaysia', phoneCode: '+60' },
  { value: 'Maldives', label: 'Maldives', phoneCode: '+960' },
  { value: 'Mali', label: 'Mali', phoneCode: '+223' },
  { value: 'Malta', label: 'Malta', phoneCode: '+356' },
  { value: 'Marshall Islands', label: 'Marshall Islands', phoneCode: '+692' },
  {
    value: 'Martinique (French)',
    label: 'Martinique (French)',
    phoneCode: '+596',
  },
  { value: 'Mauritania', label: 'Mauritania', phoneCode: '+222' },
  { value: 'Mauritius', label: 'Mauritius', phoneCode: '+230' },
  { value: 'Mexico', label: 'Mexico', phoneCode: '+52' },
  { value: 'Moldova', label: 'Moldova', phoneCode: '+373' },
  { value: 'Monaco', label: 'Monaco', phoneCode: '+377' },
  { value: 'Mongolia', label: 'Mongolia', phoneCode: '+976' },
  { value: 'Montenegro', label: 'Montenegro', phoneCode: '+382' },
  { value: 'Montserrat', label: 'Montserrat', phoneCode: '+1-664' },
  { value: 'Morocco', label: 'Morocco', phoneCode: '+212' },
  { value: 'Mozambique', label: 'Mozambique', phoneCode: '+258' },
  { value: 'Myanmar', label: 'Myanmar', phoneCode: '+95' },
  { value: 'Namibia', label: 'Namibia', phoneCode: '+264' },
  { value: 'Nauru', label: 'Nauru', phoneCode: '+674' },
  { value: 'Nepal', label: 'Nepal', phoneCode: '+977' },
  { value: 'Netherlands', label: 'Netherlands', phoneCode: '+31' },
  { value: 'New Zealand', label: 'New Zealand', phoneCode: '+64' },
  { value: 'Nicaragua', label: 'Nicaragua', phoneCode: '+505' },
  { value: 'Niger', label: 'Niger', phoneCode: '+227' },
  { value: 'Nigeria', label: 'Nigeria', phoneCode: '+234' },
  { value: 'Norway', label: 'Norway', phoneCode: '+47' },
  { value: 'Oman', label: 'Oman', phoneCode: '+968' },
  { value: 'Pakistan', label: 'Pakistan', phoneCode: '+92' },
  { value: 'Palau', label: 'Palau', phoneCode: '+680' },
  { value: 'Panama', label: 'Panama', phoneCode: '+507' },
  { value: 'Papua New Guinea', label: 'Papua New Guinea', phoneCode: '+675' },
  { value: 'Paraguay', label: 'Paraguay', phoneCode: '+595' },
  { value: 'Peru', label: 'Peru', phoneCode: '+51' },
  { value: 'Philippines', label: 'Philippines', phoneCode: '+63' },
  { value: 'Poland', label: 'Poland', phoneCode: '+48' },
  { value: 'Portugal', label: 'Portugal', phoneCode: '+351' },
  { value: 'Qatar', label: 'Qatar', phoneCode: '+974' },
  { value: 'Romania', label: 'Romania', phoneCode: '+40' },
  { value: 'Russia', label: 'Russia', phoneCode: '+7' },
  { value: 'Rwanda', label: 'Rwanda', phoneCode: '+250' },
  {
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
    phoneCode: '+1-869',
  },
  { value: 'Saint Lucia', label: 'Saint Lucia', phoneCode: '+1-758' },
  {
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
    phoneCode: '+1-784',
  },
  { value: 'Samoa', label: 'Samoa', phoneCode: '+685' },
  { value: 'San Marino', label: 'San Marino', phoneCode: '+378' },
  { value: 'Saudi Arabia', label: 'Saudi Arabia', phoneCode: '+966' },
  { value: 'Senegal', label: 'Senegal', phoneCode: '+221' },
  { value: 'Serbia', label: 'Serbia', phoneCode: '+381' },
  { value: 'Seychelles', label: 'Seychelles', phoneCode: '+248' },
  { value: 'Singapore', label: 'Singapore', phoneCode: '+65' },
  { value: 'Slovakia', label: 'Slovakia', phoneCode: '+421' },
  { value: 'Slovenia', label: 'Slovenia', phoneCode: '+386' },
  { value: 'South Africa', label: 'South Africa', phoneCode: '+27' },
  { value: 'Spain', label: 'Spain', phoneCode: '+34' },
  { value: 'Sri Lanka', label: 'Sri Lanka', phoneCode: '+94' },
  { value: 'Sudan', label: 'Sudan', phoneCode: '+249' },
  { value: 'Suriname', label: 'Suriname', phoneCode: '+597' },
  { value: 'Swaziland', label: 'Swaziland', phoneCode: '+268' },
  { value: 'Sweden', label: 'Sweden', phoneCode: '+46' },
  { value: 'Switzerland', label: 'Switzerland', phoneCode: '+41' },
  { value: 'Syria', label: 'Syria', phoneCode: '+963' },
  { value: 'Taiwan', label: 'Taiwan', phoneCode: '+886' },
  { value: 'Tanzania', label: 'Tanzania', phoneCode: '+255' },
  { value: 'Thailand', label: 'Thailand', phoneCode: '+66' },
  { value: 'Togo', label: 'Togo', phoneCode: '+228' },
  {
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
    phoneCode: '+1-868',
  },
  { value: 'Tunisia', label: 'Tunisia', phoneCode: '+216' },
  { value: 'Turkey', label: 'Turkey', phoneCode: '+90' },
  { value: 'Uganda', label: 'Uganda', phoneCode: '+256' },
  { value: 'Ukraine', label: 'Ukraine', phoneCode: '+380' },
  {
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
    phoneCode: '+971',
  },
  { value: 'United Kingdom', label: 'United Kingdom', phoneCode: '+44' },
  { value: 'United States', label: 'United States', phoneCode: '+1' },
  { value: 'Uruguay', label: 'Uruguay', phoneCode: '+598' },
  { value: 'Uzbekistan', label: 'Uzbekistan', phoneCode: '+998' },
  { value: 'Vanuatu', label: 'Vanuatu', phoneCode: '+678' },
  { value: 'Vatican City', label: 'Vatican City', phoneCode: '+39' },
  { value: 'Venezuela', label: 'Venezuela', phoneCode: '+58' },
  { value: 'Vietnam', label: 'Vietnam', phoneCode: '+84' },
  { value: 'Yemen', label: 'Yemen', phoneCode: '+967' },
  { value: 'Zambia', label: 'Zambia', phoneCode: '+260' },
  { value: 'Zimbabwe', label: 'Zimbabwe', phoneCode: '+263' },
];
