import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Button,
  Typography,
  FormControlLabel,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  CameraAlt as CameraAltIcon,
  Edit as EditIcon,
  Check as CheckIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/buttons.css";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/Context";
import { SocketContext } from "../../context/SocketContext";
import toast, { Toaster } from "react-hot-toast";

import ModalEditNumbers from "./ModalEditNumbers";
import ModalCamera from "./ModalCamera";

export default function AvslutteSkiftet() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    isLogin,
    closeShiftNumbers,
    updateCloseShiftNumbers,
    ticketPictureOK,
    ticketPictureWG,
    ticketPictureNT,
  } = useContext(AppContext);
  const { socket } = useContext(SocketContext);

  const [theoric, setTheoric] = useState("");
  const [minisafe, setMinisafe] = useState(21260);
  const [cashier, setCashier] = useState(21260);
  const [cashierTotal, setCashierTotal] = useState(43200);
  const [deviated, setDeviated] = useState(0);

  const [currentProvider, setIsCurrentProvider] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const updateShowEditModal = (value) => {
    setShowEditModal(value);
  };

  const [showCamera, setShowCamera] = useState(false);
  const updateShowCamera = (value) => {
    setShowCamera(value);
  };

  const handleCaptureOK = () => {
    setCurrentTicket("OK");
    setShowCamera(true);
  };

  const handleCaptureWG = () => {
    setCurrentTicket("WG");
    setShowCamera(true);
  };

  const handleCaptureNT = () => {
    setCurrentTicket("NT");
    setShowCamera(true);
  };

  const [page, setPage] = useState(0);
  const [currentTicket, setCurrentTicket] = useState("");

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }

    socket.emit("closeShiftNumbers", { action: "closeShiftNumbers" });
  }, []);

  useEffect(() => {
    const handleCloseShiftNumbers = (data) => {
      if (!data) {
        toast.error("Error");
        return;
      }

      updateCloseShiftNumbers(data);
    };

    socket.on("closeShiftNumbers", handleCloseShiftNumbers);

    return () => {
      socket.off("closeShiftNumbers", handleCloseShiftNumbers);
    };
  }, [socket]);

  const openEdition = (index) => {
    switch (index) {
      case 0:
        setIsCurrentProvider("CH");
        break;
      case 1:
        setIsCurrentProvider("WG");
        break;
      case 2:
        setIsCurrentProvider("OK D.");
        break;
      default:
        break;
    }
    updateShowEditModal(true);
  };

  const nextStep = () => {
    if (page === 0 && (!ticketPictureOK || !ticketPictureWG)) {
      toast.error(t("report.errorTicket"));
      return;
    }

    if (page === 0) {
      setPage(1);
      return;
    }

    if (page === 1) {
      /*
      modifyLogin(false);
      modifyLogin(false);
      modifyOperator("");
      modifyOperatorFull("");
      modifyHall("");
      updateSession("");
      navigate("/login");
      */
    }
  };

  const backStep = () => {
    if (page === 1) {
      setPage(0);
      return;
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          maxWidth: "100%",
          maxHeight: "80%",
          width: "100%",
          height: "80%",
          overflow: "hidden",

          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            style: {
              zIndex: "9999999 !important",
              fontSize: "24px",
            },
          }}
          containerStyle={{
            zIndex: 99999999,
          }}
        />
        {showEditModal && (
          <ModalEditNumbers
            updateShowEditModal={updateShowEditModal}
            provider={currentProvider}
          />
        )}
        {showCamera && (
          <ModalCamera
            updateShowCamera={updateShowCamera}
            currentTicket={currentTicket}
          />
        )}
        {page === 0 && (
          <>
            {Array.from({ length: 4 }).map((_, index) => (
              <Grid
                key={index}
                item
                xs={3}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  container
                  sx={{
                    width: "280px",
                    maxWidth: "280px",
                    height: "350px",
                    backgroundColor: "#1f3b84",
                    borderRadius: "20px",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      height: "65px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: "20px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {index === 0 && "ClubHouse"}
                      {index === 1 && "Win Games"}
                      {index === 2 && "OK Bingo"}
                      {index === 3 && "Norsk Tipping"}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "100%",
                      height: "205px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      borderBottom: "2px solid black",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",

                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "19px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>
                              {t("report.cashin")}
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              {t("report.cashout")}
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              {t("report.neto")}
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",

                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                          }}
                        >
                          <ul style={{ listStyle: "none" }}>
                            <li style={{ marginTop: "20px" }}>
                              {index === 0 && closeShiftNumbers
                                ? closeShiftNumbers.chCashin
                                : index === 1 && closeShiftNumbers
                                ? closeShiftNumbers.wgCashin
                                : index === 2 && closeShiftNumbers
                                ? closeShiftNumbers.okCashin
                                : "-"}
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              {" "}
                              {index === 0 && closeShiftNumbers
                                ? closeShiftNumbers.chCashout
                                : index === 1 && closeShiftNumbers
                                ? closeShiftNumbers.wgCashout
                                : index === 2 && closeShiftNumbers
                                ? closeShiftNumbers.okCashout
                                : "-"}
                            </li>
                            <li style={{ marginTop: "20px" }}>
                              {" "}
                              {index === 0 && closeShiftNumbers
                                ? closeShiftNumbers.chNeto
                                : index === 1 && closeShiftNumbers
                                ? closeShiftNumbers.wgNeto
                                : index === 2 && closeShiftNumbers
                                ? closeShiftNumbers.okNeto
                                : "-"}
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <>
                    {index === 1 && (
                      <Grid
                        item
                        xs={6}
                        sx={{
                          width: "100%",
                          height: "80px",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {!ticketPictureWG ? (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: "100%",
                              height: "80px",
                              fontSize: "21px",
                              backgroundColor: "#42a5f5",
                            }}
                            onClick={handleCaptureWG}
                          >
                            <CameraAltIcon />
                            {t("report.picture")}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: "100%",
                              height: "80px",
                              fontSize: "21px",
                              backgroundColor: "#388e3c",
                            }}
                            onClick={handleCaptureWG}
                          >
                            <CheckIcon />
                            {t("report.picture")}
                          </Button>
                        )}
                      </Grid>
                    )}
                  </>
                  <>
                    {index === 2 && (
                      <Grid
                        item
                        xs={6}
                        sx={{
                          width: "100%",
                          height: "80px",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {!ticketPictureOK ? (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: "100%",
                              height: "80px",
                              fontSize: "21px",
                              backgroundColor: "#42a5f5",
                            }}
                            onClick={handleCaptureOK}
                          >
                            <CameraAltIcon />
                            {t("report.picture")}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="success"
                            type="submit"
                            sx={{
                              width: "100%",
                              height: "80px",
                              fontSize: "21px",
                              backgroundColor: "#388e3c",
                            }}
                            onClick={handleCaptureOK}
                          >
                            <CheckIcon />
                            {t("report.picture")}
                          </Button>
                        )}
                      </Grid>
                    )}
                  </>

                  <>
                    {index === 3 && (
                      <Grid
                        item
                        xs={6}
                        sx={{
                          width: "100%",
                          height: "80px",
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {!ticketPictureNT ? (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              width: "100%",
                              height: "80px",
                              fontSize: "21px",
                              backgroundColor: "#42a5f5",
                            }}
                            onClick={handleCaptureNT}
                          >
                            <CameraAltIcon />
                            {t("report.picture")}
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            color="success"
                            type="submit"
                            sx={{
                              width: "100%",
                              height: "80px",
                              fontSize: "21px",
                              backgroundColor: "#388e3c",
                            }}
                            onClick={handleCaptureNT}
                          >
                            <CheckIcon />
                            {t("report.picture")}
                          </Button>
                        )}
                      </Grid>
                    )}
                  </>

                  <Grid
                    item
                    xs={index !== 0 ? 6 : 12}
                    sx={{
                      width: "100%",
                      height: "80px",
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="warning"
                      type="submit"
                      sx={{ width: "100%", height: "80px", fontSize: "18px" }}
                      onClick={() => {
                        openEdition(index);
                      }}
                    >
                      <EditIcon /> {t("report.edit")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}

        {page === 1 && (
          <div className="react-confirm-alert-body" style={{ width: "1200px" }}>
            <h1>{t("report.closeReport")}</h1>

            <TextField
              autoComplete="off"
              label={t("report.theoric")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={theoric}
              className="modal-textfield"
              value={theoric}
            />

            <TextField
              autoComplete="off"
              label={t("report.minisafe")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={minisafe}
              className="modal-textfield"
              value={minisafe}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashier")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={cashier}
              className="modal-textfield"
              value={cashier}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashierTotal")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={cashierTotal}
              className="modal-textfield"
              value={cashierTotal}
            />

            <TextField
              autoComplete="off"
              label={t("report.difference")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={deviated}
              className="modal-textfield"
              value={deviated}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  onChange={() => {}}
                  name="checked"
                  color="primary"
                  sx={{
                    color: "black",
                    "&.Mui-checked": {
                      color: "primary.main",
                    },
                  }}
                />
              }
              label={
                <Typography
                  variant="h6"
                  style={{
                    color: "black",
                    marginLeft: "10px",
                    marginTop: "5px",
                  }}
                >
                  {t("report.acceptDeviated")}
                </Typography>
              }
            />
          </div>
        )}
      </Grid>

      {/* FOOTER BUTTONS */}
      <Grid
        container
        sx={{
          maxWidth: "100%",
          maxHeight: "20%",
          width: "100%",
          height: "20%",
          overflow: "hidden",

          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            width: "100%",
            height: "80px",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn-custom-back"
            onClick={() => {
              backStep();
            }}
            style={{
              backgroundColor: page === 0 ? "gray" : "#fc86c1",
            }}
          >
            {t("report.back")}
          </button>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            width: "100%",
            height: "80px",
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className="btn-custom-next"
            onClick={() => {
              nextStep();
            }}
            style={{
              backgroundColor:
                page === 0 &&
                (!ticketPictureOK || !ticketPictureWG || !ticketPictureNT)
                  ? "gray"
                  : "#009e82",
            }}
          >
            {page === 0 ? t("report.next") : t("report.sendReport")}
          </button>
        </Grid>
      </Grid>
    </>
  );
}
