import React, { useContext, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
//import config from "./ScanConfig";
//import BarcodeScannerComponent from "react-usb-barcode-scanner";

import Header from './components/Header/Header';
import Login from './views/Login';
import Salgs from './views/Salgs';
import SisteSalgs from './views/SisteSalgs';
import UserLogin from './views/UserLogin/UserLogin';
import ApneBilleter from './views/ApneBilleter/ApneBilleter';
import BilletHistorikk from './views/BilletHistorikk';
import SisteTransaksjoner from './views/SisteTransaksjoner';
import SisteKortTransaksjoner from './views/SisteKortTransaksjoner';
import AvslutteSkiftet from './views/AvslutteSkiftet/AvslutteSkiftet';

import ModalSpecialGames from './views/SpecialGames/ModalSpecialGames';
import ModalEndShift from './views/AvslutteSkiftet/ModalEndShift';

import { Grid } from '@mui/material';
import { AppContext } from './context/Context';
import { SocketContext } from './context/SocketContext';
import ModalDisconnect from './views/SocketStatusModals/ModalDisconnect';
import ModalReconnect from './views/SocketStatusModals/ModalReconnect';
import LoadingScreen from './views/Loading/Loading';

export default function App() {
  const location = useLocation();

  const {
    isLogin,
    showSpecialGames,
    showModalEndShift,
    updateEnviroment,
    isDisconnected,
    updateIsDisconnected,
    isReconnected,
    updateIsReconnected,
    isLoadingScreen,
  } = useContext(AppContext);

  const isDisconnectedRef = useRef(isDisconnected);
  useEffect(() => {
    isDisconnectedRef.current = isDisconnected;
  }, [isDisconnected]);

  const showSpecialGamesRef = useRef(showSpecialGames);
  useEffect(() => {
    showSpecialGamesRef.current = showSpecialGames;
  }, [showSpecialGames]);

  useEffect(() => {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      updateEnviroment('stage01');
    } else if (hostname.includes('stage01')) {
      updateEnviroment('stage01');
    } else if (hostname.includes('stage02')) {
      updateEnviroment('stage02');
    } else {
      updateEnviroment('prod');
    }
  }, []);

  const { socket } = useContext(SocketContext);
  useEffect(() => {
    socket.on('connect', () => {
      if (isDisconnectedRef.current) {
        updateIsDisconnected(false);
        updateIsReconnected(true);
        return;
      }
    });

    socket.on('disconnect', () => {
      updateIsDisconnected(true);
      updateIsReconnected(false);
    });

    socket.on('connect_error', () => {
      updateIsDisconnected(true);
      updateIsReconnected(false);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('connect_error');
    };
  }, [socket]);

  return (
    <>
      {isLoadingScreen && <LoadingScreen />}

      <Grid
        container
        direction="row"
        sx={{
          height: '100vh',
          backgroundColor: '#08141e',
        }}
      >
        {isDisconnected && location.pathname !== '/user-login' && (
          <ModalDisconnect />
        )}
        {isReconnected && location.pathname !== '/user-login' && (
          <ModalReconnect />
        )}

        {showModalEndShift && <ModalEndShift />}

        {isLogin && (
          <Grid item xs={2}>
            <Header />
          </Grid>
        )}
        <Grid
          item
          xs={!isLogin ? 12 : 10}
          sx={{ height: '100%', backgroundColor: '#15192b' }}
        >
          {showSpecialGames && <ModalSpecialGames />}
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/salgs" element={<Salgs />} />
            <Route path="/siste-salgs" element={<SisteSalgs />} />
            <Route path="/apne-billeter" element={<ApneBilleter />} />
            <Route path="/billet-historikk" element={<BilletHistorikk />} />
            <Route
              path="/siste-transaksjoner"
              element={<SisteTransaksjoner />}
            />
            <Route
              path="/kort-transaksjoner"
              element={<SisteKortTransaksjoner />}
            />
            <Route path="/login" element={<Login />} />
            <Route exact path="/user-login" element={<UserLogin />} />
            <Route
              exact
              path="/avslutte-skiftet"
              element={<AvslutteSkiftet />}
            />
          </Routes>
        </Grid>
      </Grid>
    </>
  );
}
