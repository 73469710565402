import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import { SocketNFCContext } from '../../../context/SocketNFC';
import toast from 'react-hot-toast';
import Select from 'react-select';

import { countriesDataEN } from '../../../utils/countriesDataEN';
import { countriesDataNO } from '../../../utils/countriesDataNO';

import { NorwegianId } from 'norwegian-national-id-validator';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PriceChange as PriceChangeIcon,
  PersonOff as PersonOffIcon,
  QrCodeScanner as ScanIcon,
} from '@mui/icons-material/';
import InputAdornment from '@mui/material/InputAdornment';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function ModalFullUserEdit() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [disableEdit, setDisableEdit] = useState(false);

  const [isUserDeletedConfirm, setIsUserDeletedConfirm] = useState(false);
  const [isUserDeleted, setIsUserDeleted] = useState(false);

  useEffect(() => {
    if (currentLanguage === 'no') {
      handleCountryChange({
        value: 'Norway',
        label: 'Norge',
      });
    } else {
      handleCountryChange({
        value: 'Norway',
        label: 'Norway',
      });
    }
    const handleLanguageChange = (lang) => {
      setCurrentLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const {
    updateIsLoadingScreen,
    updateShowWaitingForScan,
    parentInfo,
    enviroment,
    updateShowModalEditUserLimits,
    operatorInfo,
    session,
    updateCurrentUser,
    updateUserLogin,
    isHybridUser,
    isFullUser,
    updateIsFullUser,
    updateIsFullUserEditing,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    userInfo,
    isVerificationNeeded,

    logoutUser,

    updateShowModalIsVerificationNeeded,
    isLimitSurpassed,
    updateIsLimitSurpassed,
    updateIsDailyLimitSurpassed,
    isDailyLimitSurpassed,
    updateIsMonthlyLimitSurpassed,
    isMonthlyLimitSurpassed,
    updateShowUserInfo,
    updatePrint,
    resetTransactions,
    updateReceiptToPrint,
    updateIsCard,
    updateIsCash,
    updatePayCard,
    updatePayCash,
    updatePaySpillerkonto,
    updateChange,
    updatePaymentType,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updateCurrentUserID,
    resetTicketsCH,
    updateTotal,
    updateIsVerificationNeeded,
  } = useContext(AppContext);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON, FOCUS ON CARDID
  const inputRef = useRef(null);
  useEffect(() => {
    updateIsFullUserEditing(true);

    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1400);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);
  const [showSMS, setShowSMS] = useState(false);
  const [smsMemberRegistration, setSmsMemberRegistration] = useState('');
  const smsMemberRegistrationRef = useRef(smsMemberRegistration);
  useEffect(() => {
    smsMemberRegistrationRef.current = smsMemberRegistration;
  }, [smsMemberRegistration]);

  const [isUserConfirmed, setIsUserConfirmed] = useState('');

  // #region HANDLES
  //* NEW MEMBER HANDLES
  const [cardID, setCardID] = useState('');
  const handleCardIDChange = (event) => {
    let inputValue = event.target.value;
    if (inputValue.length > 12) return;

    if (inputValue === '' || inputValue.toLowerCase().startsWith('m')) {
      let newValue =
        inputValue.charAt(0).toUpperCase() +
        inputValue.slice(1).replace(/[^\d]/g, '');
      setCardID(newValue);
    }
  };
  const cardIDRef = useRef(cardID);
  useEffect(() => {
    console.log('cardID', cardID);
    cardIDRef.current = cardID;
  }, [cardID]);

  const [username, setUsername] = useState('');
  const handleUsernameChange = (event) => {
    if (isFullUser) return;
    const newValue = event.target.value.replace(/\s/g, '');
    setUsername(newValue);
  };
  const usernameRef = useRef(username);
  useEffect(() => {
    usernameRef.current = username;
  }, [username]);

  const [ssnid, setSSNID] = useState('');
  const handleSSNIDChange = (event) => {
    setSSNID(event.target.value);
  };
  const ssnidRef = useRef(ssnid);
  useEffect(() => {
    ssnidRef.current = ssnid;
  }, [ssnid]);

  const [firstname, setFirstName] = useState('');
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const firstnameRef = useRef(firstname);
  useEffect(() => {
    firstnameRef.current = firstname;
  }, [firstname]);

  const [lastname, setLastName] = useState('');
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const lastnameRef = useRef(lastname);
  useEffect(() => {
    lastnameRef.current = lastname;
  }, [lastname]);

  const [address1, setAddress1] = useState('');
  const handleAddress1Change = (event) => {
    setAddress1(event.target.value);
  };
  const address1Ref = useRef(address1);
  useEffect(() => {
    address1Ref.current = address1;
  }, [address1]);

  const [address2, setAddress2] = useState('');
  const handleAddress2Change = (event) => {
    setAddress2(event.target.value);
  };
  const address2Ref = useRef(address2);
  useEffect(() => {
    address2Ref.current = address2;
  }, [address2]);

  const [verificationMethod, setVerificationMethod] = useState(null);
  const [verificationMethodSelected, setVerificationMethodSelected] =
    useState('');

  const handleVerificationMethodChange = (selectedOption) => {
    setVerificationMethod(selectedOption.value);
    setVerificationMethodSelected(selectedOption);
  };
  const verificationMethodRef = useRef(verificationMethod);
  useEffect(() => {
    verificationMethodRef.current = verificationMethod;
  }, [verificationMethod]);

  const [options, setOptions] = useState([
    { value: 'Pass', label: t('sales.passport') },
    { value: 'Førerkort', label: t('sales.driverLicense') },
    { value: 'National-ID', label: t('sales.nationalID') },
    //{ value: 'Bankkort', label: t('sales.bankCard') },
    //{ value: 'Annet', label: t('sales.other') },
  ]);
  const optionsRef = useRef(options);
  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  const [country, setCountry] = useState('');
  const [countrySelected, setCountrySelected] = useState('');
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.value);
    setCountrySelected(selectedOption);
  };
  const countryRef = useRef(country);
  useEffect(() => {
    countryRef.current = country;
  }, [country]);

  const [postalCode, setPostalCode] = useState('');
  const handlePostalCodeChange = (event) => {
    const onlyNums = event.target.value.replace(/\D/g, '');
    setPostalCode(onlyNums);
  };
  const postalCodeRef = useRef(postalCode);
  useEffect(() => {
    postalCodeRef.current = postalCode;
  }, [postalCode]);

  const [region, setRegion] = useState('');
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
  const regionRef = useRef(region);
  useEffect(() => {
    regionRef.current = region;
  }, [region]);

  const [phonenumber, setPhonenumber] = useState('');
  const phonenumberRef = useRef(phonenumber);
  useEffect(() => {
    phonenumberRef.current = phonenumber;
  }, [phonenumber]);
  const handlePhonenumberChange = (event) => {
    // Remove all non-digit characters
    let input = event.target.value.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6, 9);
    }

    setPhonenumber(input);
  };

  async function isEightDigitNumber(value) {
    const digits = value.replace(/\s/g, '_');

    if (digits[0] !== '4' && digits[0] !== '9') {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  const [birthdate, setBirthdate] = useState('');
  const handleBirthdateChange = (event) => {
    let inputValue = event.target.value;

    // Remove all non-digit characters
    const digitsOnly = inputValue.replace(/\D/g, '');

    // Breaking down the string and inserting dots
    let formattedInput = digitsOnly;
    if (digitsOnly.length > 2) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2)}`;
    }
    if (digitsOnly.length > 4) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(
        2,
        4
      )}.${digitsOnly.slice(4)}`;
    }

    // Limiting the length to 10 characters to match dd.mm.yyyy
    formattedInput = formattedInput.slice(0, 10);

    setBirthdate(formattedInput);
  };
  const birthdateRef = useRef(birthdate);
  useEffect(() => {
    birthdateRef.current = birthdate;
  }, [birthdate]);

  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const emailRef = useRef(email);
  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  const [verificationID, setVerificationID] = useState('');
  const handleVerificationIDChange = (event) => {
    setVerificationID(event.target.value);
  };
  const verificationIDRef = useRef(verificationID);
  useEffect(() => {
    verificationIDRef.current = verificationID;
  }, [verificationID]);

  const [terms, setTerms] = useState(true);
  const handleTermsChange = (event) => {
    setTerms(event.target.checked);
  };
  const termsRef = useRef(terms);
  useEffect(() => {
    termsRef.current = terms;
  }, [terms]);

  const [internetAccess, setInternetAccess] = useState(false);
  const handleInternetAccessChange = (event) => {
    setInternetAccess(event.target.checked);
  };
  const internetAccessRef = useRef(internetAccess);
  useEffect(() => {
    internetAccessRef.current = internetAccess;
  }, [internetAccess]);

  const parentInfoRef = useRef(parentInfo);
  useEffect(() => {
    parentInfoRef.current = parentInfo;
  }, [parentInfo]);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    if (parentInfo) {
      return;
    }
    if (isFullUser) {
      setSSNID(userInfo['SSN ID']);
      setCardID(userInfo['Membership card ID']);
      setUsername(userInfo.username);
      setFirstName(userInfo.firstname);
      setLastName(userInfo.lastname);
      setAddress1(userInfo.address);
      setAddress2(userInfo.address2);
      setPostalCode(userInfo['Postal Code']);
      setRegion(userInfo.City);
      setPhonenumber(userInfo.mobile);
      setBirthdate(userInfo.birthdate);
      setEmail(userInfo.email);
      if (userInfo['Verification ID']) {
        setVerificationID(userInfo['Verification ID']);
      } else {
        setVerificationID(Date.now().toString());
      }
      setTerms(userInfo['Accepts terms & conditions']);
      setIsPEP(userInfo['Is PEP']);
      setInternetAccess(
        userInfo['flag:Forbidden internet access managed by operator']
      );

      if (userInfo['Verification method'] === 'Other') {
        setVerificationMethodSelected({
          value: 'Annet',
          label: t('sales.other'),
        });
        setVerificationMethod('Annet');
      }

      //if (userInfo["Verification method"] === "Pass") {
      if (
        userInfo['Verification method'] === 'Electronic (BankID)' ||
        userInfo['Verification method'] === 'Electronic(BankID)'
      ) {
        setDisableEdit(true);
        setOptions([
          { value: 'Pass', label: t('sales.passport') },
          { value: 'Førerkort', label: t('sales.driverLicense') },
          { value: 'Electronic(BankID)', label: t('sales.electronicID') },
          { value: 'Bankkort', label: t('sales.bankCard') },
          { value: 'Annet', label: t('sales.other') },
        ]);
      }

      setTimeout(() => {
        if (userInfo['Verification method'] === 'Electronic (BankID)') {
          const matchedMethod = optionsRef.current.find(
            (item) => item.value === 'Electronic(BankID)'
          );

          // Check the result
          if (matchedMethod) {
            setVerificationMethodSelected(matchedMethod);
            setVerificationMethod(matchedMethod.value);
          }

          return;
        }

        const matchedMethod = optionsRef.current.find(
          (item) => item.value === userInfo['Verification method']
        );

        // Check the result
        if (matchedMethod) {
          setVerificationMethodSelected(matchedMethod);
          setVerificationMethod(matchedMethod.value);
        }
      }, 200);

      /*
      var matchedCountry = {};
      if (currentLanguage === "no") {
        matchedCountry = countriesDataNO.find(
          (item) => item.value === userInfo.country
        );
      } else {
        matchedCountry = countriesDataEN.find(
          (item) => item.value === userInfo.country
        );
      }

      if (matchedCountry) {
        setCountrySelected(matchedCountry);
        setCountry(matchedCountry.value);
      }
      */
      return;
    }
    if (isHybridUser) {
      setFirstName(userInfo.firstname);
      setPhonenumber(userInfo.mobile);
      return;
    }
  }, [userInfo]);

  useEffect(() => {
    if (!parentInfo) {
      return;
    }
    if (isFullUser) {
      setSSNID(parentInfo['SSN ID']);
      setCardID(parentInfo['Membership card ID']);
      setUsername(parentInfo.username);
      setFirstName(parentInfo.firstname);
      setLastName(parentInfo.lastname);
      setAddress1(parentInfo.address);
      setAddress2(parentInfo.address2);
      setPostalCode(parentInfo['Postal Code']);
      setRegion(parentInfo.City);
      setPhonenumber(parentInfo.mobile);
      setBirthdate(parentInfo.birthdate);
      setEmail(parentInfo.email);
      if (userInfo['Verification ID']) {
        setVerificationID(parentInfo['Verification ID']);
      } else {
        setVerificationID(Date.now().toString());
      }
      setTerms(parentInfo['Accepts terms & conditions']);
      setIsPEP(parentInfo['Is PEP']);
      setInternetAccess(
        parentInfo['flag:Forbidden internet access managed by operator']
      );

      if (parentInfo['Verification method'] === 'Other') {
        setVerificationMethodSelected({
          value: 'Annet',
          label: t('sales.other'),
        });
        setVerificationMethod('Annet');
      }

      //if (parentInfo["Verification method"] === "Pass") {
      if (
        parentInfo['Verification method'] === 'Electronic (BankID)' ||
        parentInfo['Verification method'] === 'Electronic(BankID)'
      ) {
        setDisableEdit(true);
        setOptions([
          { value: 'Pass', label: t('sales.passport') },
          { value: 'Førerkort', label: t('sales.driverLicense') },
          { value: 'Electronic(BankID)', label: t('sales.electronicID') },
          { value: 'Bankkort', label: t('sales.bankCard') },
          { value: 'Annet', label: t('sales.other') },
        ]);
      }

      setTimeout(() => {
        if (parentInfo['Verification method'] === 'Electronic (BankID)') {
          const matchedMethod = optionsRef.current.find(
            (item) => item.value === 'Electronic(BankID)'
          );

          // Check the result
          if (matchedMethod) {
            setVerificationMethodSelected(matchedMethod);
            setVerificationMethod(matchedMethod.value);
          }

          return;
        }

        const matchedMethod = optionsRef.current.find(
          (item) => item.value === parentInfo['Verification method']
        );

        // Check the result
        if (matchedMethod) {
          setVerificationMethodSelected(matchedMethod);
          setVerificationMethod(matchedMethod.value);
        }
      }, 350);

      /*
        var matchedCountry = {};
        if (currentLanguage === "no") {
          matchedCountry = countriesDataNO.find(
            (item) => item.value === parentInfo.country
          );
        } else {
          matchedCountry = countriesDataEN.find(
            (item) => item.value === parentInfo.country
          );
        }
  
        if (matchedCountry) {
          setCountrySelected(matchedCountry);
          setCountry(matchedCountry.value);
        }
        */
      return;
    }
    if (isHybridUser) {
      setFirstName(parentInfo.firstname);
      setPhonenumber(parentInfo.mobile);
      return;
    }
  }, [parentInfo]);

  const [isPEP, setIsPEP] = useState(false);
  const handleIsPEPChange = (event) => {
    setIsPEP(event.target.checked);
  };
  const isPEPRef = useRef(isPEP);
  useEffect(() => {
    isPEPRef.current = isPEP;
  }, [isPEP]);

  //* Style for the select box
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '247.74px',
      height: '56px',
      minHeight: '58px',
      border: state.isFocused ? 'noone' : '1px solid #464646',
      boxShadow: 'none',
      '&:hover': { borderColor: '#ced4da' },
      borderColor: state.isFocused ? '#90caf9' : provided.borderColor,
      outline: state.isFocused ? '1px solid #90caf9' : 'none',
      outlineOffset: '0px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above other content
    }),
  };

  // #region SOCKETS
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  const socketNFC = useContext(SocketNFCContext);

  useEffect(() => {
    const handleSearchMemberPreFullRegister = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      if (
        data.phonenumber &&
        phonenumberRef.current !== userInfo.mobile &&
        phonenumberRef.current !== parentInfo.mobile
      ) {
        toast.error(t('error.errorPhonenumberExists'));
        return;
      }
      if (
        data.username &&
        usernameRef.current !== userInfo.username &&
        usernameRef.current !== parentInfo.username
      ) {
        toast.error(t('error.errorUsernameExists'));
        return;
      }
      if (
        data.cardID &&
        cardIDRef.current !== userInfo['Membership card ID'] &&
        cardIDRef.current !== parentInfo['Membership card ID']
      ) {
        toast.error(t('error.errorCardIDExists'));
        return;
      }
      if (
        data.ssnid &&
        ssnidRef.current !== userInfo['SSN ID'] &&
        ssnidRef.current !== parentInfo['SSN ID']
      ) {
        toast.error(t('error.errorSSNIDExists'));
        return;
      }

      if (
        (parentInfoRef.current &&
          parentInfoRef.current.mobile !== phonenumberRef.current) ||
        (userInfoRef.current &&
          userInfoRef.current.mobile !== phonenumberRef.current)
      ) {
        var dataToSend = {
          phonenumber: phonenumberRef.current,
          username: usernameRef.current,
          sessionID: sessionRef.current,
          action: 'sms-hybrid',
        };
        socket.emit('smsFullRegistration', dataToSend);

        setShowSMS(true);
        socket.emit('activateSMS', {
          bingoName: hallRef.current,
        });
      } else {
        setIsUserConfirmed(true);
      }
    };

    const handleNewMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        setShowSMS(false);
        setIsUserConfirmed(false);
        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
        return;
      }

      var data = {
        auth_value: usernameRef.current,
        isExact: true,
        searchByUsername: true,
        action: 'searchMember',
        session: sessionRef.current,
      };
      socket.emit('searchMember', data);
    };

    const handleSearchMember = (data) => {
      if (
        !data ||
        !data.records ||
        data.records.length === 0 ||
        data.records.length > 1
      ) {
        toast.error(t('error.error'));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]['7 digit PIN']);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === 'anon' ||
        (data.records[0].firstname === '' && data.records[0].mobile === '')
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            searchByID: true,
            action: 'searchMemberParent',
            session: sessionRef.current,
          };
          socket.emit('searchMemberParent', data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: sessionRef.current,
        user7Pincode: dataToRegisterObj[0]['7 digit PIN'],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ''
            ? 'Uregistrert'
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);
      updateIsFullUserEditing(false);

      updateFocusOnMainScreen(true);
    };

    const handleSendPincodeTerminal = (data) => {
      if (smsMemberRegistrationRef.current === '') {
        return;
      }

      if (parseInt(data.pincode) !== smsMemberRegistrationRef.current) {
        toast.error(t('error.errorUserFailCode'));
        return;
      }
      setIsUserConfirmed(true);
      socket.emit('deactivateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleSmsFullRegistration = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      setSmsMemberRegistration(data);
      socket.emit('activateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleDeleteMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      setIsUserDeletedConfirm(false);
      setIsUserDeleted(true);
    };

    socket.on('searchMemberPreFullRegister', handleSearchMemberPreFullRegister);
    socket.on('newMember', handleNewMember);
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);
    socket.on('smsFullRegistration', handleSmsFullRegistration);
    socket.on('searchMember', handleSearchMember);
    socket.on('deleteMember', handleDeleteMember);

    return () => {
      socket.off(
        'searchMemberPreFullRegister',
        handleSearchMemberPreFullRegister
      );
      socket.off('newMember', handleNewMember);
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
      socket.off('smsFullRegistration', handleSmsFullRegistration);
      socket.off('searchMember', handleSearchMember);
      socket.off('deleteMember', handleDeleteMember);
    };
  }, [socket]);

  useEffect(() => {
    /*
    const handleCardDetected = (data) => {
      setCardID(data);
    };

    socketNFC.on('cardDetected', handleCardDetected);

    return () => {
      socketNFC.off('cardDetected', handleCardDetected);
    };
    */

    const handleScanDetected = async (data) => {
      //console.log('scanDetected', data);

      const str = data;
      const regex = /[Mm]\d{7}/;
      const match = str.match(regex);

      //console.log(match ? match[0] : 'No match found');
      updateIsLoadingScreen(false);
      updateShowWaitingForScan(false);

      setCardID(match[0]);
    };

    socketNFC.on('scanDetected', handleScanDetected);

    return () => {
      socketNFC.off('scanDetected', handleScanDetected);
    };
  }, [socketNFC]);

  // #region BUTTONS AND LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmRegister = async () => {
    const validation = NorwegianId(ssnidRef.current);
    if (
      !validation.isValid() &&
      enviroment === 'prod' &&
      ssnidRef.current !== ''
    ) {
      toast.error(t('error.errorSSNIDInvalid'));
      return;
    }
    if (
      validation.age() < 18 &&
      enviroment === 'prod' &&
      ssnidRef.current !== ''
    ) {
      toast.error(t('error.errorSSNIDUnderAge'));
      return;
    }

    const isPhonenumber = await isEightDigitNumber(phonenumber);
    if (!isPhonenumber) {
      toast.error(t('error.errorPhonenumberInput'));
      return;
    }
    if (!cardIDRef.current) {
      toast.error(t('error.errorCardIDEmpty'));
      return;
    }
    console.log(cardIDRef.current);
    console.log(cardIDRef.current.length);
    if (cardIDRef.current.length < 6) {
      toast.error(t('error.errorCardIDFormat'));
      return;
    }
    if (!usernameRef.current) {
      toast.error(t('error.errorUsernameEmpty'));
      return;
    }
    if (usernameRef.current.length < 2) {
      toast.error(t('error.errorUsernameTooShort'));
      return;
    }
    if (usernameRef.current.length > 12) {
      toast.error(t('error.errorUsernameTooLong'));
      return;
    }
    if (!firstnameRef.current) {
      toast.error(t('error.errorFirstNameEmpty'));
      return;
    }
    if (!lastnameRef.current) {
      toast.error(t('error.errorLastNameEmpty'));
      return;
    }
    if (!address1Ref.current) {
      toast.error(t('error.errorAddress1Empty'));
      return;
    }
    if (!postalCodeRef.current) {
      toast.error(t('error.errorPostalCodeEmpty'));
      return;
    }
    if (!regionRef.current) {
      toast.error(t('error.errorRegionEmpty'));
      return;
    }
    if (!birthdateRef.current) {
      toast.error(t('error.errorBirthdateEmpty'));
      return;
    }
    if (birthdateRef.current.length !== 10) {
      toast.error(t('error.errorBirthdateFormat'));
      return;
    }
    if (!verificationIDRef.current) {
      toast.error(t('error.errorVerificationIDEmpty'));
      return;
    }
    if (!termsRef.current) {
      toast.error(t('error.errorTermsEmpty'));
      return;
    }
    if (!verificationMethodRef.current) {
      toast.error(t('error.errorVerificationMethodEmpty'));
      return;
    }

    //* Search if phonenumber or user already existed
    /*
    if (
      phonenumberRef.current === userInfo.mobile &&
      usernameRef.current === userInfo.username &&
      cardIDRef.current === userInfo["Membership card ID"]
    ) {
      registerAndEmitUser();
    }
    */

    var dataToSend = {
      phonenumber: phonenumberRef.current,
      username: usernameRef.current,
      cardID: cardIDRef.current,
      action: 'searchMemberPreFullRegister',
      session: sessionRef.current,
      ssnid: ssnidRef.current,
    };
    socket.emit('searchMemberPreFullRegister', dataToSend);
  };

  //* CONFIRM SMS REGISTRATION (and register the user)
  const confirmSMS = () => {
    registerAndEmitUser();
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    if (isVerificationNeeded) {
      updateIsLimitSurpassed(false);
      updateIsDailyLimitSurpassed(false);
      updateIsMonthlyLimitSurpassed(false);

      logoutUser();
      updateShowUserInfo(false);
      updatePrint(false);
      resetTransactions();
      updateReceiptToPrint(false);
      updateIsCard(false);
      updateIsCash(false);
      updateTotal(0);
      updatePayCard(0);
      updatePayCash(0);
      updatePaySpillerkonto(0);
      updateChange(0);
      updateCurrentUser('');
      updateCurrentUserID('');
      updateUserLogin(false);
      updatePaymentType('');
      updateIsPaymentOpen(false);
      updateIsSellerOpen(false);
      logoutUser();
      updateCurrentUser('');
      updateCurrentUserID('');
      updateUserLogin(false);
      updateUserInfo({});
      updateIsFastUserLogin(false);
      updateIsAnonUser(false);
      updateIsHybridUser(false);
      updateIsFullUser(false);
      updateUserParent(false);
      updateUserChildren([]);
      updateUserSiblings([]);
      resetTicketsCH();
      updateIsVerificationNeeded(false);
      updateShowModalIsVerificationNeeded(false);
      updateFocusOnMainScreen(true);
      updateIsFullUserEditing(false);
      return;
    }
    updateIsFullUserEditing(false);

    updateFocusOnMainScreen(true);
  };

  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    if (data.operator === '') {
      return;
    }
    socket.emit('registerLastUsers', data);
  };

  //* Emit the user for the register
  const registerAndEmitUser = () => {
    var data = {
      action: 'newMember',
      id: parentInfo ? parentInfo.id : userInfo.id,
      ssnid: ssnidRef.current,
      cardID: cardIDRef.current,
      username: usernameRef.current,
      firstname: firstnameRef.current,
      lastname: lastnameRef.current,
      address1: address1Ref.current,
      address2: address2Ref.current,

      country: countryRef.current,
      postalCode: postalCodeRef.current,
      region: regionRef.current,
      phonenumber: phonenumberRef.current,
      email: emailRef.current,
      birthdate: birthdateRef.current,
      terms: termsRef.current,
      internetAccess: internetAccessRef.current,
      session: sessionRef.current,
      operator: operator,
      userInfo: parentInfo ? parentInfo : userInfo,
      isPEP: isPEPRef.current,
    };

    if (!disableEdit) {
      data.verificationMethod = verificationMethodRef.current
        ? verificationMethodRef.current
        : false;
      data.verificationID = verificationIDRef.current;
    }

    if (isFullUser) {
      data.type = 'editMember';
    }
    if (isHybridUser) {
      data.type = 'editHybrid';
    }
    socket.emit('newMember', data);
  };

  const handleUserLimits = () => {
    updateIsFullUserEditing(false);
    updateShowModalEditUserLimits(true);
  };

  const requestDeletedUser = () => {
    setIsUserDeletedConfirm(true);
  };

  const cancelDeletedUser = () => {
    setIsUserDeletedConfirm(false);
  };

  const handleDelete = () => {
    var data = {
      action: 'deleteMember',
      id: parentInfo ? parentInfo.id : userInfo.id,
      ssnid: ssnidRef.current,
      cardID: cardIDRef.current,
      username: usernameRef.current,
      firstname: firstnameRef.current,
      lastname: lastnameRef.current,
      address1: address1Ref.current,
      address2: address2Ref.current,

      country: countryRef.current,
      postalCode: postalCodeRef.current,
      region: regionRef.current,
      phonenumber: phonenumberRef.current,
      email: emailRef.current,
      birthdate: birthdateRef.current,
      terms: termsRef.current,
      internetAccess: internetAccessRef.current,
      session: sessionRef.current,
      operator: operator,
      userInfo: parentInfo ? parentInfo : userInfo,
      isPEP: isPEPRef.current,
    };

    if (!disableEdit) {
      data.verificationMethod = verificationMethodRef.current
        ? verificationMethodRef.current
        : false;
      data.verificationID = verificationIDRef.current;
    }

    if (isFullUser) {
      data.type = 'editMember';
    }
    if (isHybridUser) {
      data.type = 'editHybrid';
    }
    socket.emit('deleteMember', data);
  };

  const handleUserDeleted = () => {
    updateShowUserInfo(false);
    updatePrint(false);
    resetTransactions();
    updateReceiptToPrint(false);
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updatePaySpillerkonto(0);
    updateChange(0);
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updatePaymentType('');
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();

    updateFocusOnMainScreen(true);
    updateIsFullUserEditing(false);
  };

  const handleScanCard = (cardID) => {
    updateShowWaitingForScan(true);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          {isUserConfirmed ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.userConfirm')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    confirmSMS();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : isUserDeletedConfirm ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.deleteUser')}</h1>
              <p>{t('sales.deleteUserTitle')}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleDelete();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancelDeletedUser();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.cancel')}
                </button>
              </div>
            </div>
          ) : isUserDeleted ? (
            // Render for user deleted
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.deleteUserConfirm')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleUserDeleted();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : !showSMS ? (
            // Render when SMS is not shown
            <div
              className="react-confirm-alert-body"
              style={{ width: '  1000px' }}
            >
              <h1>{t('sales.editMember')}</h1>

              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: '5px',
                }}
              >
                <Grid
                  xs={6}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <TextField
                    autoComplete="off"
                    label={`${t('sales.cardID')}* (Mxxxxxxx)`}
                    value={cardID}
                    variant="outlined"
                    onChange={handleCardIDChange}
                    className="modal-textfield"
                    inputRef={inputRef}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          style={{
                            borderRight: '1px solid #ccc', // Add right border
                            paddingRight: '8px', // Add some space after the icon
                          }}
                          onClick={() => {
                            handleScanCard();
                          }}
                        >
                          <ScanIcon style={{ color: 'black' }} />{' '}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    autoComplete="off"
                    label={'Fødselsnummer'}
                    value={ssnid}
                    variant="outlined"
                    onChange={handleSSNIDChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.username') + '*'}
                    value={username}
                    variant="outlined"
                    onChange={handleUsernameChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.firstname') + '*'}
                    value={firstname}
                    variant="outlined"
                    onChange={handleFirstNameChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.lastname') + '*'}
                    value={lastname}
                    variant="outlined"
                    onChange={handleLastNameChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.address1') + '*'}
                    value={address1}
                    variant="outlined"
                    onChange={handleAddress1Change}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.address2')}
                    value={address2}
                    variant="outlined"
                    onChange={handleAddress2Change}
                    className="modal-textfield"
                  />
                </Grid>

                <Grid
                  xs={6}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Select
                    className="modal-textfield-select"
                    styles={customStyles}
                    value={countrySelected}
                    onChange={handleCountryChange}
                    options={
                      currentLanguage === 'no'
                        ? countriesDataNO
                        : countriesDataEN
                    }
                    isClearable={false}
                    placeholder={t('sales.country') + '*'}
                  />
                  <TextField
                    autoComplete="off"
                    label={t('sales.postalCode') + '*'}
                    value={postalCode}
                    variant="outlined"
                    onChange={handlePostalCodeChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.region') + '*'}
                    value={region}
                    variant="outlined"
                    onChange={handleRegionChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.phonenumber') + '* (xxx xx xxx)'}
                    value={phonenumber}
                    variant="outlined"
                    onChange={handlePhonenumberChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.birthdate') + '* (dd.mm.yyyy)'}
                    value={birthdate}
                    variant="outlined"
                    onChange={handleBirthdateChange}
                    className="modal-textfield"
                  />{' '}
                  <TextField
                    autoComplete="off"
                    label={t('sales.email')}
                    value={email}
                    variant="outlined"
                    onChange={handleEmailChange}
                    className="modal-textfield"
                  />
                  <TextField
                    autoComplete="off"
                    label={t('sales.verificationID') + '*'}
                    value={verificationID}
                    variant="outlined"
                    onChange={handleVerificationIDChange}
                    className="modal-textfield"
                    disabled={disableEdit}
                    sx={{
                      display: 'none !important',
                      '& .MuiInputBase-root.Mui-disabled': {
                        backgroundColor: 'lightgrey', // Background color when disabled
                        color: 'black', // Text color when disabled
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: 'grey', // Label color when disabled
                      },
                      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
                        {
                          borderColor: 'grey', // Border color when disabled
                        },
                    }}
                  />
                  <Select
                    className="modal-textfield-select"
                    styles={customStyles}
                    value={verificationMethodSelected}
                    onChange={handleVerificationMethodChange}
                    options={optionsRef.current}
                    isClearable={false}
                    placeholder={t('sales.verificationMethod') + '*'}
                    isDisabled={disableEdit}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPEP}
                        onChange={handleIsPEPChange}
                        name="checked"
                        color="primary"
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        style={{
                          color: 'black',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      >
                        P.E.P.
                      </Typography>
                    }
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={terms}
                        onClick={handleTermsChange}
                        name="checkedTerms"
                        color="primary"
                        sx={{
                          color: 'black',
                          '&.Mui-checked': {
                            color: 'primary.main',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        style={{
                          color: 'black',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      >
                        {t('sales.terms')}
                      </Typography>
                    }
                  />
                </Grid>

                <Grid
                  xs={12}
                  item
                  sx={{
                    height: '100%',
                    padding: '5px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={internetAccess}
                        onChange={handleInternetAccessChange}
                        name="checkedInternetAccess"
                        color="primary"
                        sx={{
                          color:
                            operatorInfo.hall.defaultInternetAccess !==
                            'permitted'
                              ? 'grey'
                              : 'black',
                          '&.Mui-checked': {
                            color:
                              operatorInfo.hall.defaultInternetAccess !==
                              'permitted'
                                ? 'grey'
                                : 'primary.main',
                          },
                          '&.Mui-disabled': {
                            color: 'grey',
                          },
                          '&.MuiCheckbox-root.Mui-disabled': {
                            color: 'grey',
                          },
                        }}
                        disabled={
                          operatorInfo.hall.defaultInternetAccess !==
                          'permitted'
                            ? true
                            : false
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        style={{
                          color: 'black',
                          marginLeft: '10px',
                          marginTop: '5px',
                        }}
                      >
                        {t('sales.internetAccess')}
                      </Typography>
                    }
                  />
                </Grid>

                <div className="react-confirm-alert-button-group">
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      confirmRegister();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.editMember')}
                  </button>
                  {enviroment !== 'prod' && (
                    <button
                      className="btn-custom-thirdOption"
                      onClick={() => {
                        handleUserLimits();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <PriceChangeIcon style={{ fontSize: '1.2em' }} />

                      {t('header.userLimits')}
                    </button>
                  )}
                  <button
                    className="btn-custom-delete-modal"
                    onClick={() => {
                      requestDeletedUser();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <PersonOffIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.deleteUser')}
                  </button>
                  <button
                    className="btn-custom-no"
                    onClick={() => {
                      cancel();
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px', // Space between icon and text
                    }}
                  >
                    <CancelIcon style={{ fontSize: '1.2em' }} />

                    {t('sales.cancel')}
                  </button>
                </div>
              </Grid>
            </div>
          ) : (
            // Render SMS confirmation
            <div
              className="react-confirm-alert-body"
              style={{ width: '  1000px' }}
            >
              <h1>{t('sales.smsTitle')}</h1>
              <p>{t('sales.smsText1')}</p>
              <p>{t('sales.smsText2')}</p>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancel();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.cancel')}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalFullUserEdit;
