import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/Context";

import { SocketContext } from "../../context/SocketContext";
import { useTranslation } from "react-i18next";
import "react-calendar/dist/Calendar.css";

function SisteKortTransaksjoner() {
  const { socket } = useContext(SocketContext);
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isLogin, isBlocking, BlockingScreen, hall, printer } =
    useContext(AppContext);

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, []);

  const [rows, setRows] = useState(false);

  useEffect(() => {
    const handleShoppingCarts = (data) => {
      setRows(data);
    };

    socket.on("getLastCardTransactions", handleShoppingCarts);

    return () => {
      socket.off("getLastCardTransactions", handleShoppingCarts);
    };
  }, [socket]);

  useEffect(() => {
    socket.emit("getLastCardTransactions", { bingoName: hall });
  }, [hall]);

  function convertToMySQLDateTime(isoString) {
    const date = new Date(isoString);

    // Padding function to add leading zeros if necessary
    const pad = (number) => (number < 10 ? "0" + number : number);

    // Format the date using UTC methods
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // getUTCMonth() returns month from 0-11
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const handlePrint = (shoppingCart) => {
    socket.emit("printReceiptOnDemand", {
      printer: printer,
      action: "printReceiptOnDemand",
      printInfo: shoppingCart.netsTicket,
    });
  };

  return (
    <>
      {isBlocking && <BlockingScreen />}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/*TABLE*/}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                padding: "10px",
                height: "100%",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "calc(100% - 20px)",
                  marginBottom: "200px",
                  overflowY: "scroll", // Enables vertical scrolling
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Opera
                  },
                }}
              >
                <Table
                  sx={{
                    color: "white",
                    background: "#1f2c67",
                  }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      background: "#1f2c67",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">
                        {t("sisteTrans.date")}
                      </TableCell>
                      <TableCell align="center">
                        {t("sisteTrans.operator")}
                      </TableCell>
                      <TableCell align="center">
                        {t("sisteTrans.ticket7Pincode")}
                      </TableCell>
                      <TableCell align="center">
                        {t("sisteTrans.ticketID")}
                      </TableCell>
                      <TableCell align="center">
                        {t("sisteTrans.username")}
                      </TableCell>
                      <TableCell align="center">
                        {t("sisteTrans.amount")}
                      </TableCell>
                      <TableCell align="center">
                        {t("sisteTrans.description")}
                      </TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  {rows && (
                    <TableBody>
                      {rows.map((row, index) => (
                        <>
                          {row.bingoName !== hall ? (
                            <></>
                          ) : (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                "&:nth-of-type(odd)": {
                                  backgroundColor: "#232a45",
                                },
                                "&:nth-of-type(even)": {
                                  backgroundColor: "#1a2036",
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                              >
                                {convertToMySQLDateTime(row.date)}
                              </TableCell>

                              <TableCell align="center">
                                {row.operator}
                              </TableCell>

                              <TableCell align="center">
                                {row.ticket7Pincode}
                              </TableCell>
                              <TableCell align="center">
                                {row.ticketID}
                              </TableCell>
                              <TableCell align="center">
                                {row.userInfo.firstname
                                  ? row.userInfo.firstname
                                  : "-"}
                              </TableCell>
                              <TableCell align="center">{row.amount}</TableCell>
                              <TableCell align="center">
                                {row.description}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    handlePrint(row);
                                  }}
                                >
                                  {t("sisteSalgs.printAgain")}
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default SisteKortTransaksjoner;
