import React, { useContext } from "react";
import { Grid, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

function TotalButton() {
  const { t } = useTranslation();

  const {
    updateReceiptToPrint,
    logoutUser,
    resetTicketsCH,
    withdraw,
    transactions,
    resetTransactions,
    updateUserLogin,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserInfo,
    updateIsFullUser,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateIsPaymentOpen,
    updateIsSellerOpen,
    updatePaymentType,
    total,
    payCard,
    payCash,
    change,
    updateTotal,
    updatePayCard,
    updatePayCash,
    paySpillerkonto,
    updatePaySpillerkonto,
    updateChange,
    updateIsCard,
    updateIsCash,
    updatePrint,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    updateShowUserInfo,
    isSellerOpen,
    isPaymentOpen,
  } = useContext(AppContext);

  const CustomButton = styled(Button)({
    maxWidth: "300px",
    width: "100%",
    height: "75px",
    backgroundColor: "#42a5f5",
    /*opacity: total === 0 && transactions.length === 0 ? "0.25" : "1",*/
    color: "white",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginTop: "55px",
    textAlign: "center",
    position: "relative", // Enable absolute positioning for children
  });

  const Label = styled("div")({
    position: "absolute",
    padding: "0 8px", // Adjust padding based on your needs
  });

  const TopLeftLabel = styled(Label)({
    top: 5,
    left: 3,
    fontSize: "16px",
  });

  const BottomRightLabel = styled(Label)({
    bottom: 5,
    right: 0,
    fontSize: "23px",
  });

  return (
    <Grid
      item
      xs={12}
      sx={{
        width: "100%",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "-140px",
        }}
      >
        <Grid
          item
          xs={10}
          sx={{
            height: "90px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            padding: "10px",
            marginLeft: "-15px",
          }}
        >
          {!isPaymentOpen && (
            <CustomButton
              variant="contained"
              onClick={() => {
                //modalEnd();
                if (isSellerOpen) {
                  toast.error(t("error.errorNavigateTransactions"), {
                    duration: 5000,
                  });
                  return;
                }
                if (transactions.length === 0) {
                  updateShowUserInfo(false);
                  updatePrint(false);
                  resetTransactions();
                  updateReceiptToPrint(false);
                  updateIsCard(false);
                  updateIsCash(false);
                  updateTotal(0);
                  updatePayCard(0);
                  updatePayCash(0);
                  updatePaySpillerkonto(0);
                  updateChange(0);
                  updateCurrentUser("");
                  updateCurrentUserID("");
                  updateUserLogin(false);
                  updatePaymentType("");
                  updateIsPaymentOpen(false);
                  updateIsSellerOpen(false);
                  logoutUser();
                  updateCurrentUser("");
                  updateCurrentUserID("");
                  updateUserLogin(false);
                  updateUserInfo({});
                  updateIsFastUserLogin(false);
                  updateIsAnonUser(false);
                  updateIsHybridUser(false);
                  updateIsFullUser(false);
                  updateUserParent(false);
                  updateUserChildren([]);
                  updateUserSiblings([]);
                  resetTicketsCH();
                  return;
                }

                updateIsCard(false);
                updateIsCash(true);
                updateIsPaymentOpen(true);
                updatePaymentType("cash");
              }}
            >
              <TopLeftLabel>
                {total - payCard - payCash - paySpillerkonto - withdraw >= 0
                  ? t("sales.total")
                  : t("sales.change")}
              </TopLeftLabel>
              <BottomRightLabel>
                {total - payCard - payCash - paySpillerkonto - withdraw >= 0
                  ? total - payCard - payCash - paySpillerkonto - withdraw
                  : change}
              </BottomRightLabel>
            </CustomButton>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TotalButton;
