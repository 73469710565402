import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import MoneyIcon from '@mui/icons-material/Money';

function ModalUserInfoSibling() {
  const { t } = useTranslation();

  const {
    updateScannedTicket,
    scannedTicket,
    updateTransactionsArray,
    updateShowUserInfoSibling,
    parentInfo,

    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    updateAdoptedTicket,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  const [isTerminalBusy, setIsTerminalBusy] = useState(false);

  useEffect(() => {
    const handleCashout = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        setIsTerminalBusy(false);
        //deleteTransactions(transactions[transactions.length - 1]);
        return;
      }

      updateTransactionsArray([
        {
          name: 'Utbetaling - CH',
          //amount: parseInt(amount),
          amount: data.amount * -1,
          type: 'transaction',
          ticketData: data,
        },
      ]);

      if (parentInfo) {
        socket.emit('checkLimitStatus', parentInfo);
      } else {
        socket.emit('checkLimitStatus', userInfo);
      }

      setTimeout(() => {
        updateScannedTicket({});
        updateShowUserInfoSibling(false);
        setIsTerminalBusy(false);
        updateShowUserInfoSibling(false);
        updateFocusOnMainScreen(true);
      }, 300);
    };

    socket.on('cashoutTicket', handleCashout);

    return () => {
      socket.off('cashoutTicket', handleCashout);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const handlePayOut = async () => {
    setIsTerminalBusy(true);
    if (isTerminalBusy) {
      return;
    }
    var dataNewTicket = {
      name: 'Utbetaling - CH',
      amount: parseInt(scannedTicket.balances.wallet),
      type: 'transaction',
    };

    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: 'CH',
      description: 'Utbetaling',
      amount: parseInt(scannedTicket.balances.wallet),
      user7Pincode: parentInfo
        ? parentInfo['7 digit PIN']
        : userInfo['7 digit PIN'],
      userInfo: parentInfo ? parentInfo : userInfo,
      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      playerID: scannedTicket.id,
      cashoutType: 'cash_from_wallet',
      ticketID: scannedTicket.id,
      ticketUsername: scannedTicket.username,
      ticket7Pincode: parentInfo
        ? parentInfo['7 digit PIN']
        : userInfo['7 digit PIN'],

      action: 'cashoutTicket',
    };

    data.transactions.push(dataNewTicket);
    socket.emit('cashoutTicket', data);
  };

  return (
    <div
      style={{
        width: '100%',
        height: '200px',
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <div
        style={{
          width: '600px',
          height: '200px',
          background: 'white',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          zIndex: '9999998',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ color: 'black' }}>{t('sales.ticket7Pincode')}</p>
          <span
            style={{
              fontSize: '22px',
              color: '#42a5f5',
              fontWeight: 'bold',
            }}
          >
            {scannedTicket['7 digit PIN']}
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ color: 'black' }}>{t('sales.currentSaldo')}</p>
          <span
            style={{
              fontSize: '22px',
              color: '#42a5f5',
              fontWeight: 'bold',
            }}
          >
            {scannedTicket.balances.wallet} NOK
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="react-confirm-alert-button-group">
            <button
              className="btn-custom-no"
              onClick={() => {
                if (scannedTicket.balances.wallet < 1) {
                  return;
                }
                handlePayOut();
              }}
              style={{
                backgroundColor: 'black !important',
                opacity: scannedTicket.balances.wallet < 1 ? '0.3' : 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px', // Space between icon and text
              }}
            >
              <MoneyIcon style={{ fontSize: '1.2em' }} />

              {t('sales.payout')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalUserInfoSibling;
