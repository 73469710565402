import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  CameraAlt as CameraAltIcon,
  RestartAlt as RestartAltIcon,
  SyncAlt as SyncAltIcon,
  AddAlert as AddAlertIcon,
} from '@mui/icons-material/';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';

function ModalSignatureID() {
  const { t } = useTranslation();
  const {
    parentInfo,
    userInfo,
    updateShowModalSignaturePostponed,
    updateSignatureTemp,
    hall,
    operator,
    session,
    updateIsLoadingScreen,
    updateIsPostponedID,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);

  const videoRef = useRef(null);
  const [activeCameraIndex, setActiveCameraIndex] = useState(0); // State to track the active camera (0 or 1)
  const [streams, setStreams] = useState([]);
  const [capturedImages, setCapturedImages] = useState([]);
  const [cameraDevices, setCameraDevices] = useState([]); // Store video devices

  const capturedImagesRef = useRef(capturedImages);
  useEffect(() => {
    capturedImagesRef.current = capturedImages;
  }, [capturedImages]);

  useEffect(() => {
    const handleIdPostponedRegister = (data) => {
      updateIsLoadingScreen(false);

      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      stopVideo();
      updateIsPostponedID(false);
      updateShowModalSignaturePostponed(false);
    };

    socket.on('idPostponedRegister', handleIdPostponedRegister);

    return () => {
      socket.off('idPostponedRegister', handleIdPostponedRegister);
    };
  }, [socket]);

  // Function to start the media stream for the active camera
  const startVideo = async (cameraIndex) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === 'videoinput'
      );
      setCameraDevices(videoDevices); // Save the camera devices for switching later

      if (videoDevices.length > 0) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: videoDevices[cameraIndex].deviceId },
        });

        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }

        setStreams((prevStreams) => [...prevStreams, stream]);
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
    }
  };

  const stopVideo = () => {
    streams.forEach((stream) => {
      stream.getTracks().forEach((track) => track.stop());
    });
    setStreams([]);
  };

  useEffect(() => {
    // Start video with the first camera (activeCameraIndex = 0)
    startVideo(activeCameraIndex);

    return () => {
      stopVideo();
    };
  }, [activeCameraIndex]);

  // Function to handle camera switching
  const switchCamera = () => {
    // Toggle between 0 and 1 (assuming two cameras)
    const nextCameraIndex = (activeCameraIndex + 1) % cameraDevices.length;
    stopVideo();
    setActiveCameraIndex(nextCameraIndex);
  };

  const handleCapture = () => {
    const video = videoRef.current;
    if (!video) return;

    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const newCapturedImage = canvas.toDataURL('image/png');
    setCapturedImages([newCapturedImage]);
  };

  const handleUpload = async () => {
    if (capturedImages.length === 0) return;

    const imageUrl = capturedImages[0];
    const byteString = atob(imageUrl.split(',')[1]);
    const intArray = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const currentDateTimeString = getCurrentDateTimeString();

    socket.emit('idPostponedRegister', {
      fileBuffer: intArray,
      fileName: parentInfo
        ? parentInfo.id + `_${currentDateTimeString}.png`
        : userInfo.id + `_${currentDateTimeString}.png`,
      userInfo: parentInfo ? parentInfo : userInfo,
      operator: operator,
      bingoName: hall,
      sessionID: session,
    });
    updateIsLoadingScreen(true);

    /*
    stopVideo();
    updateShowModalSignaturePostponed(false);
    */
  };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
  };

  const handleCancel = async () => {
    updateSignatureTemp(false);
    updateShowModalSignaturePostponed(false);
  };

  return (
    <>
      <div id="react-confirm-alert">
        <div className="react-confirm-alert-overlay">
          <div className="react-confirm-alert">
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.photoUploadTitle')}</h1>
              <p>{t('sales.photoUploadTextSignature')}</p>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: '90%',
                    height: '750px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {capturedImages.length > 0 ? (
                    <img
                      src={capturedImages[0]}
                      alt="Captured"
                      style={{ width: '100%', height: '500px' }}
                    />
                  ) : (
                    <video
                      ref={videoRef}
                      autoPlay
                      playsInline
                      style={{ width: '100%', height: '500px' }}
                    >
                      {streams.length > 0
                        ? 'Loading video...'
                        : 'Video stream not available.'}
                    </video>
                  )}
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    <button
                      className="btn-custom-camara"
                      onClick={
                        capturedImages.length > 0
                          ? () => setCapturedImages([])
                          : handleCapture
                      }
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Align icon and text in the center
                        padding: '10px 20px', // Adjust padding as necessary
                        gap: '8px', // Add space between icon and text
                      }}
                    >
                      {capturedImages.length > 0 ? (
                        <>
                          <RestartAltIcon style={{ fontSize: '1.2em' }} />
                          {t('report.resetCamera')}
                        </>
                      ) : (
                        <>
                          <CameraAltIcon style={{ fontSize: '1.2em' }} />
                          {t('report.capturePicture')}
                        </>
                      )}
                    </button>

                    <button
                      className="btn-custom-camara btn-yellow"
                      onClick={switchCamera}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Center the content
                        padding: '10px 20px', // Adjust padding as necessary
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <SyncAltIcon style={{ fontSize: '1.2em' }} />
                      {t('sales.switchCamera')}
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="react-confirm-alert-button-group"
                style={{ marginTop: '55px' }}
              >
                <button
                  className="btn-custom-yes"
                  onClick={handleUpload}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                    opacity: capturedImages.length === 0 ? '0.5' : '1',
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                  {t('sales.confirm')}
                </button>

                <button
                  className="btn-custom-no"
                  onClick={() => handleCancel()}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.cancel')}
                </button>
              </div>

              <div style={{ marginTop: '55px' }}>
                <p
                  style={{
                    fontSize: '12px',
                    color: '#666',
                    textAlign: 'left',
                    fontWeight: 'bold',
                  }}
                >
                  {t('sales.termsSignature')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalSignatureID;
