import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AddCardIcon from "@mui/icons-material/AddCard";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

function OrcScreenSummary({
  amount,
  updateAmount,
  updateOcrOpen,
  textOcr,
  imgSrc,
  load,
}) {
  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {load ? (
              <p>Loading...</p>
            ) : imgSrc ? (
              <>
                <p>Result</p>

                <p>{textOcr}</p>
              </>
            ) : (
              <p>No data preview</p>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/*Buttons*/}
      <Grid
        item
        xs={12}
        sx={{
          padding: "10px",
          width: "100%",
          maxWidth: "100% !important",
        }}
      >
        <Grid
          container
          flexDirection="row"
          sx={{
            height: "125px",
            width: "100%",
            marginTop: 0,
            marginLeft: 0,
            padding: "10px",
          }}
        >
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              component="label"
              sx={{
                width: "200px",
                height: "90px",
                backgroundColor: "#fc86c1",
                color: "white",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
              onClick={() => {
                updateOcrOpen(false);
              }}
            >
              Avbryt
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              component="label"
              sx={{
                width: "200px",
                height: "90px",
                backgroundColor: "#009f83",
                color: "white",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
              onClick={() => {
                console.log("confirm");
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default OrcScreenSummary;
