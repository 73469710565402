import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { AppContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import { SocketContext } from "../../context/SocketContext";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "../../assets/css/buttons.css";
import {
  PointOfSale as PointOfSaleIcon,
  AddCard as AddCardIcon,
} from "@mui/icons-material";

function ModalEndShift() {
  const { t } = useTranslation();

  const {
    hall,
    operator,
    session,
    updateShowModalEndShift,
    isDailyReportCompleted,
    isCardReportCompleted,
    updateIsDailyReportCompleted,
    updateIsCardReportCompleted,
    printer,
  } = useContext(AppContext);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;

    socket.emit("closeShiftCardNumbers", {
      action: "closeShiftCardNumbers",
      bingoName: hall,
    });
  }, [hall]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const { socket } = useContext(SocketContext);

  const [showCardReport, setShowCardReport] = useState(false);
  const [cardNumbers, setCardNumbers] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleReconciliation = (data) => {
      if (!data) {
        return;
      }
      setIsLoading(false);
      updateShowModalEndShift(false);
      updateIsCardReportCompleted(true);
    };

    const handleCloseCardShiftNumbers = (data) => {
      setCardNumbers(data);
    };

    const handleDailyReportPrint = (data) => {
      setIsLoading(false);
      updateIsDailyReportCompleted(true);
      setShowCardReport(false);
    };

    socket.on("dailyReportPrint", handleDailyReportPrint);
    socket.on("reconciliation", handleReconciliation);
    socket.on("closeShiftCardNumbers", handleCloseCardShiftNumbers);

    return () => {
      socket.off("dailyReportPrint", handleDailyReportPrint);
      socket.off("reconciliation", handleReconciliation);
      socket.off("closeShiftCardNumbers", handleCloseCardShiftNumbers);
    };
  }, [socket]);

  const handleCancel = () => {
    updateShowModalEndShift(false);
  };

  const handleCardReport = () => {
    setShowCardReport(true);
  };

  const handleDailyReport = () => {
    socket.emit("dailyReportPrint", {
      action: "dailyReportPrint",
      bingoName: hall,
      sessionID: session,
    });
  };

  const handleCancelCardReport = () => {
    setShowCardReport(false);
  };

  const handleCardReportTask = () => {
    if (!cardNumbers || cardNumbers.cardNeto === 0) {
      return;
    }
    var data = {
      operator: operatorRef.current,
      bingoName: hallRef.current,
      sessionID: sessionRef.current,
      printer: printer,
      action: "reconciliation",
    };
    setIsLoading(true);

    socket.emit("reconciliation", data);
  };

  const handleFinishConfirm = () => {
    updateShowModalEndShift(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          {isLoading ? (
            <div
              class="react-confirm-alert-body"
              style={{ width: "1000px", height: "300px" }}
            >
              <h1>{t("endShift.waiting")}</h1>
            </div>
          ) : showCardReport ? (
            <div class="react-confirm-alert-body" style={{ width: "1000px" }}>
              <h1>{t("endShift.confirmCardReport")}</h1>

              <Grid
                container
                sx={{
                  width: "100%",
                  height: "200px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    width: "100%",
                    height: "205px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",

                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "19px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ marginTop: "20px" }}>
                            {t("endShift.cardPayment")}
                          </li>
                          {/*
                          <li style={{ marginTop: "20px" }}>
                            {t("endShift.cardPayout")}
                          </li>
                          */}

                          <li style={{ marginTop: "20px" }}>
                            {t("endShift.cardTotal")}
                          </li>
                        </ul>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",

                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Grid
                      item
                      xs={6}
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "20px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ marginTop: "20px" }}>
                            {cardNumbers.cardIn}
                          </li>
                          {/*
                          <li style={{ marginTop: "20px" }}>
                            {cardNumbers.cardOut}
                          </li>
*/}
                          <li style={{ marginTop: "20px" }}>
                            {cardNumbers.cardNeto}
                          </li>
                        </ul>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  style={{
                    opacity: cardNumbers.cardNeto > 0 ? 1 : 0.5,
                  }}
                  onClick={() => {
                    handleCardReportTask();
                  }}
                >
                  {t("sales.confirm")}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    handleCancelCardReport();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </div>
          ) : (
            <div class="react-confirm-alert-body" style={{ width: "1000px" }}>
              <h1>{t("endShift.title")}</h1>
              <p>{t("endShift.subtitle")}</p>

              <Grid
                container
                sx={{
                  width: "100%",
                  height: "350px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "100%",
                    height: "65px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={() => {
                    handleCardReport();
                  }}
                >
                  <AddCardIcon
                    style={{
                      fontSize: 150,
                      color: isCardReportCompleted ? "#009e82" : "#fc86c1",
                    }}
                  />
                  <h2>{t("endShift.cardReport")}</h2>
                  <p
                    style={{
                      color: isCardReportCompleted ? "#009e82" : "#fc86c1",
                    }}
                  >
                    {isCardReportCompleted
                      ? t("endShift.completed")
                      : t("endShift.notCompleted")}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "100%",
                    height: "65px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={() => {
                    handleDailyReport();
                  }}
                >
                  <PointOfSaleIcon
                    style={{
                      fontSize: 150,
                      color: isDailyReportCompleted ? "#009e82" : "#fc86c1",
                    }}
                  />
                  <h1>{t("endShift.cashierReport")}</h1>
                  <p
                    style={{
                      color: isDailyReportCompleted ? "#009e82" : "#fc86c1",
                    }}
                  >
                    {isDailyReportCompleted
                      ? t("endShift.completed")
                      : t("endShift.notCompleted")}
                  </p>
                </Grid>
              </Grid>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  style={{
                    opacity:
                      isCardReportCompleted && isDailyReportCompleted ? 1 : 0.2,
                  }}
                  onClick={() => {
                    handleFinishConfirm();
                  }}
                >
                  {t("sales.confirm")}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    handleCancel();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalEndShift;
