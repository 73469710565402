import React, { useEffect, useState, useContext, useRef } from "react";
import { AppContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import "../../assets/css/buttons.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import zIndex from "@mui/material/styles/zIndex";

function Loading() {
  const { t } = useTranslation();

  const { updateFocusOnMainScreen } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      id="react-confirm-alert-loading"
      style={{
        pointerEvents: "all !important",
        zIndex: "9999999 !important",
      }}
    >
      <div
        className="react-confirm-alert-overlay"
        style={{
          pointerEvents: "all !important",
          background: "rgba(0, 0, 0, 0.1) !important",
          zIndex: "9999099 !important",
        }}
      >
        <div
          className="react-confirm-alert"
          style={{
            pointerEvents: "all !important",
            zIndex: "999999 !important",
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Loading;
