import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { AppProvider } from "./context/Context";
import SocketProvider from "./context/SocketContext";
import SocketNFCProvider from "./context/SocketNFC";
import { BrowserRouter } from "react-router-dom";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <ThemeProvider theme={darkTheme}>
    <AppProvider>
      <SocketProvider>
        <SocketNFCProvider>
          <CssBaseline />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SocketNFCProvider>
      </SocketProvider>
    </AppProvider>
  </ThemeProvider>
);
