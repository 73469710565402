import React, { useEffect, useContext, useState } from "react";
import { AppContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import "../../assets/css/buttons.css";
import { SocketContext } from "../../context/SocketContext";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";

function ModalOpenShift() {
  const { t } = useTranslation();

  const { operator, updateIsShiftOpen } = useContext(AppContext);

  const [date, setDate] = useState(getFormattedDate());
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear(); // Gets the current year
    let month = today.getMonth() + 1; // Gets the month (0-indexed, so add 1)
    let day = today.getDate(); // Gets the day of the month

    // Ensure month and day are two digits
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}/${month}/${day}`;
  }
  const [employee, setEmployee] = useState(operator);
  const [miniSafeYesterday, setMiniSafeYesterday] = useState(12300);
  const [cashierYesterday, setCashierYesterday] = useState(21260);
  const [cashierInOpening, setCashierInOpening] = useState(21260);
  const [difference, setDifference] = useState(0);
  const [cashierTotal, setCashierTotal] = useState(43200);
  const [cashierRefill, setCashierRefill] = useState(0);

  // #region SOCKETS
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleOpenShift = (data) => {
      if (!data) {
        toast.error(t("error.error"));
        return;
      }

      updateIsShiftOpen(true);
    };

    socket.on("openShift", handleOpenShift);

    return () => {
      socket.off("openShift", handleOpenShift);
    };
  }, [socket]);

  // #region LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmOpenShift = async () => {
    /*
    var dataToSend = {
      action: "openShift",
      sessionID: session,
      operator: operator,
      bingoName: hall,
    };
    socket.emit("openShift", dataToSend);
    */
    updateIsShiftOpen(true);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("report.startDay")}</h1>

            <TextField
              autoComplete="off"
              label={t("report.date")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={date}
              className="modal-textfield"
              value={date}
            />

            <TextField
              autoComplete="off"
              label={t("report.employee")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={employee}
              className="modal-textfield"
              value={employee}
            />

            <TextField
              autoComplete="off"
              label={t("report.miniSafeYesterday")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={miniSafeYesterday}
              className="modal-textfield"
              value={miniSafeYesterday}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashierYesterday")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={cashierYesterday}
              className="modal-textfield"
              value={cashierYesterday}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashierInOpening")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={cashierInOpening}
              className="modal-textfield"
              value={cashierInOpening}
            />

            <TextField
              autoComplete="off"
              label={t("report.difference")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={difference}
              className="modal-textfield"
              value={difference}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashierTotal")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={cashierTotal}
              className="modal-textfield"
              value={cashierTotal}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashierRefill")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //handleChangePhonenumber(e);
              }}
              placeholder={cashierRefill}
              className="modal-textfield"
              value={cashierRefill}
            />

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmOpenShift();
                }}
              >
                {t("report.startDayButton")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalOpenShift;
