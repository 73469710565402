import React, { useContext, useEffect, useState, useRef } from 'react';
import { Grid, Button, TextField } from '@mui/material';
import { AppContext } from '../../../context/Context';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../assets/css/buttons.css';
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { SocketContext } from '../../../context/SocketContext';
import toast, { Toaster } from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function PaymentScreenButtons({ amount }) {
  const { t } = useTranslation();
  const { socket } = useContext(SocketContext);
  const {
    updateReactivateNeedSignatureModal,
    reactivateNeedSignatureModal,
    updateImageVerificationSignatureTemp,
    imageVerificationSignatureTemp,
    updateSignatureTemp,
    updateIsLoadingScreen,
    updateShowModalSignatureStep1,
    signatureInfo,
    updateSignatureInfo,
    updatePrint,
    printer,
    receiptToPrint,
    updateIsPaymentOpen,
    updateTransactions,
    updatePayCard,
    updatePayCash,
    transactions,
    session,
    userInfo,
    withdraw,
    updateWithdraw,
    total,
    payCard,
    payCash,
    payCashTemp,
    payCardTemp,
    paySpillerkonto,
    paySpillerkontoTemp,
    isAnonUser,
    updateIsFullUser,
    updateIsAnonUser,
    updateIsHybridUser,
    resetTransactions,
    updateIsCard,
    updateIsCash,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserLogin,
    logoutUser,
    updatePayCashTemp,
    updatePayCardTemp,
    operator,
    hall,
    updateUserInfo,
    currentUser,
    updateUserChildren,
    updateUserParent,
    parentInfo,
    updateUserSiblings,
    updateIsFastUserLogin,
    updatePaySpillerkonto,
    updatePaySpillerkontoTemp,
    print,
    isNeedSignature,
    updateIsNeedSignature,
    updateIsSignaturesStored,
  } = useContext(AppContext);

  useEffect(() => {
    if (reactivateNeedSignatureModal) {
      signatureModal();
    }
  }, [reactivateNeedSignatureModal]);

  const onCloseRef = useRef(null);

  const transactionsRef = useRef(transactions);
  const payCashRef = useRef(payCash);

  const [isBigTransaction, setIsBigTransaction] = useState(false);
  const isBigTransactionRef = useRef(isBigTransaction);
  useEffect(() => {
    isBigTransactionRef.current = isBigTransaction;
  }, [isBigTransaction]);

  const [isNetoOverpass, setIsNetoOverpass] = useState(false);
  const isNetoOverpassRef = useRef(isNetoOverpass);
  useEffect(() => {
    isNetoOverpassRef.current = isNetoOverpass;
  }, [isNetoOverpass]);

  const [isNetoOver5, setIsNetoOver5] = useState(false);
  const isNetoOver5Ref = useRef(isNetoOver5);
  useEffect(() => {
    isNetoOver5Ref.current = isNetoOver5;
  }, [isNetoOver5]);

  const signatureInfoRef = useRef(signatureInfo);
  useEffect(() => {
    signatureInfoRef.current = signatureInfo;
  }, [signatureInfo]);

  /*Input values and Refs*/
  const [usernameRegister, setUsernameRegister] = useState('');
  const [phonenumberRegister, setPhonenumberRegister] = useState('');
  const [smsGenerated, setSmsGenerated] = useState('');

  const receiptToPrintRef = useRef(receiptToPrint);
  useEffect(() => {
    receiptToPrintRef.current = receiptToPrint;
  }, [receiptToPrint]);

  const customCloseRef = useRef(null); //Close the confirm modal

  const smsGeneratedRef = useRef(smsGenerated);
  useEffect(() => {
    smsGeneratedRef.current = smsGenerated;
  }, [smsGenerated]);

  const usernameRegisterRef = useRef(usernameRegister);
  useEffect(() => {
    usernameRegisterRef.current = usernameRegister;
  }, [usernameRegister]);

  const phonenumberRegisterRef = useRef(phonenumberRegister);
  useEffect(() => {
    phonenumberRegisterRef.current = phonenumberRegister;
  }, [phonenumberRegister]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const printRef = useRef(print);
  useEffect(() => {
    printRef.current = print;
  }, [print]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);

  const isNeedSignatureRef = useRef(isNeedSignature);
  useEffect(() => {
    isNeedSignatureRef.current = isNeedSignature;
  }, [isNeedSignature]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const signatureModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        onCloseRef.current = onClose;

        updateReactivateNeedSignatureModal(false);

        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.signatureTitle')}</h1>
            <p>{t('sales.signatureText')}</p>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-no"
                onClick={() => {
                  updateReactivateNeedSignatureModal(false);
                  socket.emit('deactivateSignature', {
                    bingoName: hallRef.current,
                  });
                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />
                {t('sales.cancel')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const signaturePictureIDModal = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.signatureTitleID')}</h1>
            <p>{t('sales.signatureTextID')}</p>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  handleConfirmPictureID();
                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>
              <button
                className="btn-custom-no"
                onClick={() => {
                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleConfirmPictureID = () => {
    updateShowModalSignatureStep1(true);
  };

  //* Check user numbers to trigger the signature if it's needed
  useEffect(() => {
    var dataToSend = {
      user7Pincode: currentUser,
    };

    if (parentInfo) {
      dataToSend.user7Pincode = parentInfo['7 digit PIN'];
      socket.emit('checkIsSignatureNeeded', dataToSend);
      return;
    }

    dataToSend.user7Pincode = currentUser;
    socket.emit('checkIsSignatureNeeded', dataToSend);
  }, []);

  //* Check if transactions has any above 10.0
  useEffect(() => {
    let flag = false;

    transactions.forEach((transaction) => {
      const positiveAmount = Math.abs(transaction.amount);

      if (positiveAmount >= 10000) {
        flag = true;
        setIsBigTransaction(true);
      }

      if (transaction.name.startsWith('Avbryt') && positiveAmount >= 10000) {
        flag = false;
        setIsBigTransaction(false);
      }

      if (transaction.name.startsWith('Avbryt') && positiveAmount <= 10000) {
        flag = false;
        setIsBigTransaction(false);
      }
    });

    if (flag) {
      updateIsNeedSignature(true);
    }
  }, [transactions]);

  /*End input values and Refs*/

  useEffect(() => {
    // Define the event listener as a function
    const onEditPlayerAnonToHybrid = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      updateIsHybridUser(true);
      updateIsAnonUser(false);
      updateIsFullUser(false);
      let userInfoTemp = { ...userInfo }; // Create a shallow copy of userInfo
      userInfoTemp.username = data.username;
      userInfoTemp.firstname = data.firstname;
      userInfoTemp.phonenumber = data.mobile;
      updateUserInfo(userInfoTemp); // This might trigger a re-render

      let dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: userInfoTemp['7 digit PIN'],
        username: userInfoTemp.username,
        firstname:
          !userInfoTemp.firstname || userInfoTemp.firstname === ''
            ? 'Uregistrert'
            : userInfoTemp.firstname,
        userInfo: userInfoTemp,
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);
    };

    const handleSearchMemberPreRegister = (data) => {
      //Phone already registered or error
      if (data.records && data.records.length > 0) {
        //console.log('records found, show errow');
        return;
      }

      //Create code, send SMS and SMS modal
      var dataToSend = {
        phonenumber: phonenumberRegisterRef.current,
        sessionID: session,
        action: 'sms-hybrid',
      };
      socket.emit('sms', dataToSend);
      socket.emit('activateSMS', { bingoName: hall });

      modalWaitingForSMS();
    };

    const handleNewHybridMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      var data = {
        auth_value: data.username,
        action: 'searchMember',
        session: session,
      };
      socket.emit('searchMember', data);
    };

    const handleSearchMember = (data) => {
      setUsernameRegister('');
      setPhonenumberRegister('');

      if (!data || !data.records) {
        toast.error(t('error.error'));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]['7 digit PIN']);
      updateCurrentUserID(data.records[0]['id']);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === 'anon' ||
        (data.records[0].firstname === '' && data.records[0].mobile === '')
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            action: 'searchMemberParent',
            session: session,
          };
          socket.emit('searchMemberParent', data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: dataToRegisterObj[0]['7 digit PIN'],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ''
            ? 'Uregistrert'
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);
    };

    const handleSendPincodeTerminal = (data) => {
      if (!data) {
        toast.error('Feil Kode');
      }
      if (parseInt(data.pincode) === smsGeneratedRef.current) {
        modalUserConfirmed();
      }
    };

    const handleSms = (data) => {
      setSmsGenerated(data);

      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      setSmsGenerated(data);
    };

    //Check the current user data on the component render
    const handleCheckIsSignatureNeeded = (data) => {
      if (data.isOver5) {
        setIsNetoOver5(true);
      }
      if (data.isOver10) {
        updateIsNeedSignature(true);
        setIsNetoOverpass(true);
      }

      if (data.isSignatureStored) {
        updateIsSignaturesStored({
          url: data.signatureInfo.blobUrl,
          date: data.signatureInfo.date,
          orginalFilename: data.signatureInfo.originalFilename,
          image: data.signatureInfo.image,
          mimeType: data.signatureInfo.mimeType,
          blobName: data.signatureInfo.blobName,
        });
      }
    };

    //New signature detected
    const handleNewSignature = (data) => {
      updateSignatureTemp(data.imageInfo);

      updateShowModalSignatureStep1(true);
      if (onCloseRef.current) {
        onCloseRef.current();
      }

      if (!isNeedSignatureRef.current) {
        return;
      }
      socket.emit('deactivateSignature', {
        bingoName: hallRef.current,
      });

      updateSignatureInfo({
        uploadID: data.uploadID,
        url: data.url,
        isBigTransaction: isBigTransactionRef.current,
      });

      /*THIS WAS WORKING; CHECK FOR USER LATER ON THE MODAL
      updateSignatureInfo({
        uploadID: data.uploadID,
        url: data.url,
        isBigTransaction: isBigTransactionRef.current,
      });

      setTimeout(() => {
        updateIsNeedSignature(false);
      }, 200);

      setTimeout(() => {
        handleFinishShoppingCart();
      }, 300);

      */

      //signaturePictureIDModal();

      /*
      setTimeout(() => {
        socket.emit("signatureRegister", {
          operator: operator,
          bingoName: hall,
          sessionID: session,
          ticketID: userInfo.id,
          ticket7Pincode: currentUser,
          ticketUsername: userInfo.username,
          userInfo: !parentInfo ? userInfo : parentInfo,
          user7Pincode: !parentInfo ? currentUser : parentInfo["7 digit PIN"],
          uploadID: data.uploadID,
          url: data.url,
          isBigTransaction: isBigTransactionRef.current,
          isNetoOverpass: isNetoOverpassRef.current,
        });
      }, 800);
      */
    };

    //Signature register from the mini terminal
    const handleSignatureRegister = (data) => {
      if (data) {
        //updateIsNeedSignature(false);
        //handleFinishShoppingCart();
        //signaturePictureIDModal();
      }
    };

    const handleShoppingCarts = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        updateIsLoadingScreen(false);
        return;
      }
      if (printRef.current) {
        //Sent emit to print
        socket.emit('printShoppingCarts', {
          hall: hall,
          printer: printerRef.current,
          action: 'printShoppingCarts',
          printInfo: receiptToPrintRef.current,
          transactions: transactions,
        });
      }
      updateSignatureTemp(false);
      updateImageVerificationSignatureTemp(false);
      updateSignatureInfo(false);
      resetTransactions();
      updateIsCard(false);
      updateIsCash(false);
      updateCurrentUser('');
      updateCurrentUserID('');
      updateUserLogin(false);
      logoutUser();
      updatePayCard(0);
      updatePayCashTemp(0);
      updatePayCash(0);
      updatePayCardTemp(0);
      updateWithdraw(0);
      updatePaySpillerkonto(0);
      updatePaySpillerkontoTemp(0);

      setTimeout(() => {
        updateIsLoadingScreen(false);
        updateIsPaymentOpen(false);
        updatePrint(false);

        if (customCloseRef.current) {
          customCloseRef.current();
        }
      }, 400);
    };

    socket.on('signatureRegister', handleSignatureRegister);
    socket.on('newSignature', handleNewSignature);
    socket.on('checkIsSignatureNeeded', handleCheckIsSignatureNeeded);
    socket.on('editPlayerAnonToHybrid', onEditPlayerAnonToHybrid);
    socket.on('searchMemberPreRegister', handleSearchMemberPreRegister);
    socket.on('newHybridMember', handleNewHybridMember);
    socket.on('searchMember', handleSearchMember);
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);
    socket.on('sms', handleSms);
    socket.on('newShoppingCarts', handleShoppingCarts);

    return () => {
      socket.off('signatureRegister', handleSignatureRegister);
      socket.off('newSignature', handleNewSignature);
      socket.off('checkIsSignatureNeeded', handleCheckIsSignatureNeeded);
      socket.off('editPlayerAnonToHybrid', onEditPlayerAnonToHybrid);
      socket.off('searchMemberPreRegister', handleSearchMemberPreRegister);
      socket.off('newHybridMember', handleNewHybridMember);
      socket.off('searchMember', handleSearchMember);
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
      socket.off('sms', handleSms);
      socket.off('newShoppingCarts', handleShoppingCarts);
    };
  }, [socket]);

  useEffect(() => {
    transactionsRef.current = transactions;
    payCashRef.current = payCash;
  }, [transactions, payCash]);

  const registerLastUsers = (data) => {
    if (data.operator === '') {
      return;
    }
    socket.emit('registerLastUsers', data);
  };

  const handleFinishShoppingCart = () => {
    if (isNeedSignature) {
      socket.emit('activateSignature', { bingoName: hallRef.current });

      signatureModal();
      return;
    }

    var money = 0;

    if (
      parseInt(amount) > 0 &&
      total -
        (payCash + payCashTemp) -
        (payCard + payCardTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw >=
        0
    ) {
      money = parseInt(amount);
    }

    if (
      parseInt(amount) === 0 &&
      total -
        (payCash + payCashTemp) -
        (payCard + payCardTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw >=
        0
    ) {
      money = parseInt(
        total -
          (payCash + payCashTemp) -
          (payCard + payCardTemp) -
          (paySpillerkonto + paySpillerkontoTemp) -
          withdraw
      );
    }

    if (
      parseInt(amount) > 0 &&
      total -
        (payCash + payCashTemp) -
        (payCard + payCardTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw <
        0
    ) {
      money =
        total -
        (payCash + payCashTemp) -
        (payCard + payCardTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw;
    }

    if (
      parseInt(amount) === 0 &&
      total -
        (payCash + payCashTemp) -
        (payCard + payCardTemp) -
        (paySpillerkonto + paySpillerkontoTemp) -
        withdraw <
        0
    ) {
      money = parseInt(
        total -
          (payCash + payCashTemp) -
          (payCard + payCardTemp) -
          (paySpillerkonto + paySpillerkontoTemp) -
          withdraw
      );
    }

    if (money < 0) {
      money = money * -1;
    }

    confirmAlert({
      customUI: ({ onClose }) => {
        customCloseRef.current = onClose;

        const handleTransaction = () => {
          // Empty amount, automatic fulfilled total
          if (parseInt(amount) === 0) {
            updateTransactions({
              name: 'Betal med cash',
              amount: total - payCash - payCard - paySpillerkonto - withdraw,
              type: 'paymentCash',
            });

            var dataToSend = {
              operator: operator,
              bingoName: hall,
              sessionID: session,
              provider: '',
              description: 'Betal med Cash',
              amount: parseInt(
                total -
                  payCard -
                  payCardTemp -
                  payCash -
                  payCashTemp -
                  paySpillerkonto -
                  paySpillerkontoTemp -
                  withdraw
              ),
              action: 'newTransaction',
              ticketID: userInfo.id,
              ticketUsername: userInfo.username,
              ticket7Pincode: currentUser,
              user7Pincode: !parentInfo
                ? currentUser
                : parentInfo['7 digit PIN'],
              userInfo: !parentInfo ? userInfo : parentInfo,
              netsTicket: receiptToPrintRef.current,
            };

            updatePayCash(
              total -
                payCard -
                payCardTemp -
                payCash -
                payCashTemp -
                paySpillerkonto -
                paySpillerkontoTemp -
                withdraw
            );
            updatePayCashTemp(0);

            socket.emit('newTransaction', dataToSend);

            setTimeout(() => {
              var dataSignature = false;

              if (
                isNeedSignatureRef.current ||
                isBigTransactionRef.current ||
                isNetoOverpassRef.current
              ) {
                dataSignature = {
                  operator: operator,
                  bingoName: hall,
                  sessionID: session,
                  ticketID: userInfo.id,
                  ticket7Pincode: currentUser,
                  ticketUsername: userInfo.username,
                  userInfo: !parentInfo ? userInfo : parentInfo,
                  user7Pincode: !parentInfo
                    ? currentUser
                    : parentInfo['7 digit PIN'],
                  uploadID: signatureInfoRef.uploadID,
                  url: signatureInfoRef.current.url,
                  isBigTransaction: isBigTransactionRef.current,
                  isNetoOverpass: isNetoOverpassRef.current,
                  isNetoOver5: isNetoOver5Ref.current,
                };
              }
              var data = {
                operator: operator,
                bingoName: hall,
                sessionID: session,
                total: total,
                payCard: payCard,
                payCash: payCashRef.current,
                paySpillerkonto: paySpillerkonto,
                exchange: 0,
                user7Pincode: !parentInfo
                  ? currentUser
                  : parentInfo['7 digit PIN'],
                userInfo: !parentInfo ? userInfo : parentInfo,
                transactions: transactionsRef.current,
                print: 0,
                action: 'newShoppingCarts',
                netsTicket: receiptToPrint,
                dataSignature: dataSignature,
                isNetoOver5: isNetoOver5Ref.current,
                imageVerificationSignatureTemp: imageVerificationSignatureTemp,
              };
              socket.emit('newShoppingCarts', data);
              updateIsLoadingScreen(true);
              //customCloseRef.current(); // Call the renamed close function here
            }, 500);

            return;
          }

          // Total fulfilled or exceeded
          if (
            parseInt(amount) !== 0 &&
            total <=
              parseInt(amount) + payCard + payCash + paySpillerkonto + withdraw
          ) {
            var exchange = 0;
            if (
              total <
              parseInt(amount) + payCard + payCash + paySpillerkonto + withdraw
            ) {
              exchange =
                total -
                (parseInt(amount) +
                  payCard +
                  payCash +
                  paySpillerkonto +
                  withdraw);
              exchange = exchange * -1;
            }

            updateTransactions({
              name: 'Betal med cash',
              amount: parseInt(amount),
              type: 'paymentCash',
            });

            var dataToSend = {
              operator: operator,
              bingoName: hall,
              sessionID: session,
              provider: '',
              description: 'Betal med Cash',
              amount: parseInt(amount),
              user7Pincode: !parentInfo
                ? currentUser
                : parentInfo['7 digit PIN'],
              userInfo: !parentInfo ? userInfo : parentInfo,
              action: 'newTransaction',
              ticketID: userInfo.id,
              ticketUsername: userInfo.username,
              ticket7Pincode: currentUser,
              netsTicket: receiptToPrintRef.current,
            };

            socket.emit('newTransaction', dataToSend);

            if (exchange > 0) {
              var dataToSend = {
                operator: operator,
                bingoName: hall,
                sessionID: session,
                provider: '',
                description: 'Tilbake Betal med Cash',
                amount: exchange * -1,
                ticketID: userInfo.id,
                ticketUsername: userInfo.username,
                ticket7Pincode: currentUser,
                user7Pincode: !parentInfo
                  ? currentUser
                  : parentInfo['7 digit PIN'],
                userInfo: !parentInfo ? userInfo : parentInfo,
                action: 'newTransaction',
                netsTicket: receiptToPrintRef.current,
              };

              socket.emit('newTransaction', dataToSend);
            }

            updatePayCash(payCash + payCashTemp);
            updatePayCashTemp(0);

            updateIsLoadingScreen(true);

            setTimeout(() => {
              var dataSignature = false;

              if (
                isNeedSignatureRef.current ||
                isBigTransactionRef.current ||
                isNetoOverpassRef.current
              ) {
                dataSignature = {
                  operator: operator,
                  bingoName: hall,
                  sessionID: session,
                  ticketID: userInfo.id,
                  ticket7Pincode: currentUser,
                  ticketUsername: userInfo.username,
                  userInfo: !parentInfo ? userInfo : parentInfo,
                  user7Pincode: !parentInfo
                    ? currentUser
                    : parentInfo['7 digit PIN'],
                  uploadID: signatureInfoRef.uploadID,
                  url: signatureInfoRef.current.url,
                  isBigTransaction: isBigTransactionRef.current,
                  isNetoOverpass: isNetoOverpassRef.current,
                  isNetoOver5: isNetoOver5Ref.current,
                };
              }

              var data = {
                operator: operator,
                bingoName: hall,
                sessionID: session,
                total: total,
                payCard: payCard,
                payCash: payCashRef.current,
                paySpillerkonto: paySpillerkonto,
                exchange: exchange,
                user7Pincode: !parentInfo
                  ? currentUser
                  : parentInfo['7 digit PIN'],
                userInfo: !parentInfo ? userInfo : parentInfo,
                transactions: transactionsRef.current,
                print: 0,
                action: 'newShoppingCarts',
                netsTicket: receiptToPrintRef.current,
                dataSignature: dataSignature,
                isNetoOver5: isNetoOver5Ref.current,
                imageVerificationSignatureTemp: imageVerificationSignatureTemp,
              };
              socket.emit('newShoppingCarts', data);
              //customCloseRef.current(); // Call the renamed close function here
            }, 500);

            return;
          }

          // Total not fulfilled
          if (
            parseInt(amount) !== 0 &&
            total >
              parseInt(amount) + payCard + payCash + paySpillerkonto + withdraw
          ) {
            updatePayCash(payCash + payCashTemp + withdraw);
            updatePayCashTemp(0);
            updateTransactions({
              name: 'Betal med cash',
              amount: amount,
              type: 'paymentCash',
            });

            var dataToSend = {
              operator: operator,
              bingoName: hall,
              sessionID: session,
              provider: '',
              description: 'Betal med Cash',
              amount: parseInt(amount),
              action: 'newTransaction',
              ticketID: userInfo.id,
              ticketUsername: userInfo.username,
              ticket7Pincode: currentUser,
              user7Pincode: !parentInfo
                ? currentUser
                : parentInfo['7 digit PIN'],
              userInfo: !parentInfo ? userInfo : parentInfo,
              netsTicket: receiptToPrintRef.current,
            };

            socket.emit('newTransaction', dataToSend);

            onClose();

            return;
          }
        };

        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>
              {total -
                (payCash + payCashTemp) -
                (payCard + payCardTemp) -
                (paySpillerkonto + paySpillerkontoTemp) -
                withdraw >=
              0
                ? t('sales.sellMessageCash1') +
                  money +
                  t('sales.sellMessageCash2')
                : t('sales.sellMessageReturnCash1') +
                  money +
                  t('sales.sellMessageReturnCash2')}
            </h1>
            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  handleTransaction();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>
              <button
                className="btn-custom-no"
                onClick={onClose}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const registerAnonUser = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.paymentAnon')}</h1>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  onClose();
                  modalNewHybridUser();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.yes')}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const modalNewHybridUser = () => {
    //updateFocusOnMainScreen(false);

    confirmAlert({
      customUI: ({ onClose }) => {
        let phonenumberText = '';
        let usernameText = '';
        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.registerNewHybridUser')}</h1>
            <TextField
              autoComplete="off"
              label={t('sales.phonenumber') + ' ' + t('sales.optional')}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\D/g, '');

                //updateFocusOnMainScreen(false);

                handleChangePhonenumber(e);
                phonenumberText = handleChangePhonenumber(e);
                setPhonenumberRegister(phonenumberText);
              }}
              placeholder={phonenumberRegisterRef.current}
            />

            <TextField
              autoComplete="off"
              label={t('sales.nameAndSurname')}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                //updateFocusOnMainScreen(false);

                usernameText = e.target.value;
                setUsernameRegister(e.target.value);
              }}
              placeholder={usernameRegisterRef.current}
            />

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={async () => {
                  if (phonenumberText === '') {
                    var data = {
                      action: 'newHybridMember',
                      username: usernameRegisterRef.current,
                      phonenumber: '',
                      session: sessionRef.current,
                    };
                    socket.emit('newHybridMember', data);
                    onClose();
                  } else {
                    const isPhonenumber =
                      await isEightDigitNumber(phonenumberText);

                    if (!isPhonenumber) {
                      toast.error(t('error.errorPhonenumberInput'));
                      return;
                    }
                    if (usernameText < 10) {
                      toast.error(t('error.errorUsernameInput'));
                      return;
                    }

                    var dataToSend = {
                      auth_value: phonenumberText,
                      action: 'searchMemberPreRegister',
                      session: session,
                    };
                    socket.emit('searchMemberPreRegister', dataToSend);

                    /*
                    
                    */
                  }
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.registerNewUserConfirm')}
              </button>
              <button
                className="btn-custom-no"
                onClick={() => {
                  //updateFocusOnMainScreen(true);
                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const modalWaitingForSMS = () => {
    //updateFocusOnMainScreen(false);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.smsTitle')}</h1>
            <p>{t('sales.smsText1')}</p>
            <p>{t('sales.smsText2')}</p>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-no"
                onClick={() => {
                  socket.emit('deactivateSMS', { bingoName: hall });

                  onClose();
                  //updateFocusOnMainScreen(true);
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const modalUserConfirmed = () => {
    //updateFocusOnMainScreen(false);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.userConfirm')}</h1>

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  //updateFocusOnMainScreen(true);
                  var data = {
                    action: 'newHybridMember',
                    username: usernameRegisterRef.current,
                    phonenumber: phonenumberRegisterRef.current,
                    session: sessionRef.current,
                  };
                  socket.emit('newHybridMember', data);

                  onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  async function isEightDigitNumber(value) {
    if (value[0] !== '4' && value[0] !== '9') {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  const handleChangePhonenumber = (event) => {
    // Remove all non-digit characters
    let input = event.target.value.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6, 9);
    }

    return input;
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        width: '100%',
        maxWidth: '100% !important',
        height: '100%',
      }}
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: '9999999 !important',
            fontSize: '24px',
          },
        }}
        containerStyle={{
          zIndex: 99999999, // Ensure this value is higher than the modal's z-index
        }}
      />
      <Grid
        container
        flexDirection="row"
        sx={{
          height: '125px',
          width: '100%',
          marginTop: 0,
          marginLeft: 0,
          padding: '10px',
        }}
      >
        {/*Input*/}

        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              width: '200px',
              height: '90px',

              backgroundColor: '#009f83',
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              if (isAnonUser && !parentInfo) {
                registerAnonUser();
                return;
              }
              handleFinishShoppingCart();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px', // Space between icon and text
            }}
          >
            <CheckCircleIcon style={{ fontSize: '1.2em' }} />

            {t('sales.confirm')}
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              width: '200px',
              height: '90px',
              backgroundColor: '#fc86c1',
              color: 'white',
              fontWeight: 'bold',
              textTransform: 'uppercase',
            }}
            onClick={() => {
              updatePayCashTemp(0);
              updateIsPaymentOpen(false);
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px', // Space between icon and text
            }}
          >
            <CancelIcon style={{ fontSize: '1.2em' }} />

            {t('sales.cancel')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentScreenButtons;
