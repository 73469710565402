import React, { useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import "../../assets/css/buttons.css";

function ModalCamera({ updateShowCamera, currentTicket }) {
  const { t } = useTranslation();
  const {
    updateTicketPictureOK,
    updateTicketPictureWG,
    updateTicketPictureNT,
  } = useContext(AppContext);

  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  // Function to start the media stream
  const startVideo = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      setStream(mediaStream);
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        videoRef.current.play(); // Ensure the video plays after setting srcObject
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
    }
  };

  useEffect(() => {
    startVideo();

    // Cleanup function to stop the media stream
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []); // Dependency on `stream` removed to avoid effect loops

  // Function to handle the capture and stop the camera
  const handleCapture = () => {
    const video = videoRef.current;
    if (!video) return;

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Stop all video streams
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null); // Clear the stream from state
    }

    // Convert canvas to an image URL and store it
    setCapturedImage(canvas.toDataURL("image/png"));
  };

  // Function to restart the camera
  const handleRestart = () => {
    setCapturedImage(null); // Clear the captured image
    startVideo(); // Restart the video stream
  };

  const cancel = () => {
    updateShowCamera(false);
  };

  const confirmPicture = () => {
    if (currentTicket === "WG") {
      updateTicketPictureWG(capturedImage);
    }
    if (currentTicket === "OK") {
      updateTicketPictureOK(capturedImage);
    }
    if (currentTicket === "NT") {
      updateTicketPictureNT(capturedImage);
    }
    updateShowCamera(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <div>
              {capturedImage ? (
                <img
                  src={capturedImage}
                  alt="Captured"
                  style={{ width: "100%" }}
                />
              ) : (
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  style={{ width: "100%" }}
                >
                  Video stream not available.
                </video>
              )}

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={capturedImage ? handleRestart : handleCapture}
                >
                  {capturedImage
                    ? t("report.resetCamera")
                    : t("report.capturePicture")}
                </button>

                {capturedImage && (
                  <button
                    className="btn-custom-yes"
                    onClick={() => {
                      confirmPicture();
                    }}
                  >
                    {t("sales.confirm")}
                  </button>
                )}

                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancel();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCamera;
