import React, { useEffect, useContext } from "react";
import { AppContext } from "../../context/Context";
import { useTranslation } from "react-i18next";
import "../../assets/css/buttons.css";

function ModalDisconnect() {
  const { t } = useTranslation();

  const { updateFocusOnMainScreen } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("socketStatus.disconnectTitle")}</h1>
            <p>{t("socketStatus.disconnectText")}</p>
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: "55px" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDisconnect;
