import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import VendorItemHeader from './VendorItemHeader';
import VendorItemOptions from './VendorItemOptions';
import { AppContext } from '../../../context/Context';
import useDb from '../../../data/db';

function VendorItem() {
  const { providerIndex, enviroment, isNTEnabled, isKioskEnabled } =
    useContext(AppContext);

  const db = useDb();

  return (
    <>
      {db[0].vendors.map((vendor, index) => {
        if (
          index > providerIndex * 6 - 1 ||
          index < providerIndex * 6 - 6 ||
          (vendor.name === 'EBS' && enviroment === 'prod')
        ) {
          return;
        }

        if (vendor.name === 'Norsk Tipping' && !isNTEnabled) {
          return;
        }

        return (
          <Grid
            item
            xs={4}
            sx={{
              padding: '10px',
            }}
            key={index}
          >
            <Grid
              container
              sx={{
                marginTop: 0,
                marginLeft: 0,
                backgroundColor: '#232a45',
                height: '100%',
              }}
            >
              <VendorItemHeader vendor={vendor} />
              <VendorItemOptions vendor={vendor} />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default VendorItem;
