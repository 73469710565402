import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import ClubHouseLogo from "../../assets/img/clubhouse_logo.png";

function Logo() {
  return (
    <Grid container sx={{ height: "100%", width: "100%" }}>
      <Grid
        item
        sx={{
          paddingTop: "0px !important",
          height: "100%",
          width: "100%",
          paddingLeft: "0px !important!",
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
          alignContent: "center",
          padding: "20px !important",
        }}
      >
        <Box
          component="img"
          sx={{
            height: "100px",
            width: "auto",
            paddingTop: 0,
          }}
          alt=""
          src={ClubHouseLogo}
        />
      </Grid>
    </Grid>
  );
}

export default Logo;
