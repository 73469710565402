import React, { createContext } from "react";
import io from "socket.io-client";

export const SocketNFCContext = createContext();

const SocketNFCProvider = ({ children }) => {
  const socketNFC = io("http://localhost:3111");

  return (
    <SocketNFCContext.Provider value={socketNFC}>
      {children}
    </SocketNFCContext.Provider>
  );
};

export default SocketNFCProvider;
