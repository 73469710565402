import React from "react";
import { Grid } from "@mui/material";
import Logo from "./Logo";
import MenuButtons from "./MenuButtons";
import UserButtons from "./UserButtons";

function Header() {
  return (
    <Grid
      item
      xs={2}
      sx={{
        maxWidth: "100% !important",
        minHeight: "100%",
      }}
    >
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          maxWidth: "100% !important",
        }}
      >
        <Grid
          item
          sx={{
            height: "180px",
            width: "100% !important",
            maxWidth: "100% !important",
          }}
        >
          <Logo />
        </Grid>
        <Grid
          item
          sx={{
            height: "calc(100vh - 390px)",
            width: "100% !important",
            maxWidth: "100% !important",
          }}
        >
          <MenuButtons />
        </Grid>
        <Grid
          item
          sx={{
            height: "150px",
            width: "100% !important",
            maxWidth: "100% !important",
            display: "flex",
            marginTop: "10px",
          }}
        >
          <UserButtons />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Header;
