import React, { useEffect, useState, useContext, useRef } from 'react';
import { Grid, TextField, Typography, Button, Box } from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import Calculator from '../../../components/Calculator/Calculator';
import BankAxept from '../../../assets/img/bankaxept.png';
import { SocketContext } from '../../../context/SocketContext';
import { Toaster } from 'react-hot-toast';
import useId from 'react-use-uuid';

import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  AttachMoney as AttachMoneyIcon,
} from '@mui/icons-material/';

function CardPayment() {
  var uuidTrans = useId();

  const { t } = useTranslation();
  const { socket } = useContext(SocketContext);

  const {
    updateShowUserInfo,
    updateUserInfo,

    isSingleTransaction,
    updateIsSingleTransaction,
    print,
    printer,
    payCash,
    paySpillerkonto,
    payCard,
    payCardTemp,
    updatePayCard,
    updatePayCash,
    updatePayCardTemp,
    updatePayCashTemp,
    updateShowCard,
    userLogin,
    session,
    hall,
    operator,
    currentUser,
    userInfo,
    total,
    transactions,
    updateTransactions,
    logoutUser,
    updateCurrentUser,
    updateUserLogin,
    resetTransactions,
    updateIsPaymentOpen,
    updateIsCard,
    updateIsCash,
    cardID,
    parentInfo,
    updatePaySpillerkonto,
    updatePaySpillerkontoTemp,
    receiptToPrint,
    updateReceiptToPrint,
  } = useContext(AppContext);

  const [isWaiting, setIsWaiting] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [amountModal, setAmountModal] = useState(0);

  useEffect(() => {
    updateShowUserInfo(false);

    if (!currentUser) {
      updateCurrentUser('Gjest');
      updateUserLogin(true);
      updateUserInfo({ username: 'Gjest', firstname: 'Gjest' });
    }
  }, []);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const receiptToPrintRef = useRef(receiptToPrint);
  useEffect(() => {
    receiptToPrintRef.current = receiptToPrint;
  }, [receiptToPrint]);

  const printRef = useRef(print);
  useEffect(() => {
    printRef.current = print;
  }, [print]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);
  const transactionsRef = useRef(transactions);

  useEffect(() => {
    transactionsRef.current = transactions;
  }, [transactions]);

  useEffect(() => {
    updatePayCashTemp(0);
    updatePayCardTemp(0);
    updatePaySpillerkontoTemp(0);
    if (total > 0 && !isSingleTransaction) {
      setAmountModal(
        total - payCash - paySpillerkonto - payCard - parseInt(payCardTemp)
      );
      updatePayCardTemp(
        total - payCash - paySpillerkonto - payCard - parseInt(payCardTemp)
      );
    }
  }, []);

  useEffect(() => {
    if (total > 0 && !isSingleTransaction) {
      setAmountModal(
        total - payCash - paySpillerkonto - payCard - parseInt(payCardTemp)
      );
      updatePayCardTemp(
        total - payCash - paySpillerkonto - payCard - parseInt(payCardTemp)
      );
    }
  }, [total]);

  useEffect(() => {
    if (parseInt(amountModal) > total - payCash - payCard - paySpillerkonto) {
      setAmountModal(total - payCash - payCard - paySpillerkonto);
    }

    if (amountModal < 0) {
      setAmountModal(0);
    }
  }, [amountModal]);

  useEffect(() => {
    const handleNetsTransaction = (data) => {
      if (!data) {
        setIsFail(true);
        setIsWaiting(false);
        updatePayCardTemp(0);
        return;
      }

      if (isSingleTransaction) {
        socket.emit('printReceipt', {
          printer: printerRef.current,
          action: 'printReceipt',
          printInfo: data.result[0].customerReceipt,
        });
        updateReceiptToPrint(data.result[0].customerReceipt);
      } else {
        updateReceiptToPrint(data.result[0].customerReceipt);
      }

      setIsSuccess(true);
      setIsWaiting(false);
    };

    const handleShoppingCarts = (data) => {
      if (printRef.current) {
        //Sent emit to print
        socket.emit('printShoppingCarts', {
          hall: hall,
          printer: printerRef.current,
          action: 'printShoppingCarts',
          printInfo: receiptToPrintRef.current,
          transactions: transactions,
        });
      }

      updateIsPaymentOpen(false);
      resetTransactions();
      updateIsCard(false);
      updateIsCash(false);
      updateCurrentUser('');
      updateUserLogin(false);
      logoutUser();
      updatePayCard(0);
      updatePayCashTemp(0);
      updatePayCash(0);
      updatePayCardTemp(0);
      updatePaySpillerkonto(0);
      updatePaySpillerkontoTemp(0);
    };

    socket.on('netsTransaction', handleNetsTransaction);
    socket.on('newShoppingCarts', handleShoppingCarts);
    return () => {
      socket.off('netsTransaction', handleNetsTransaction);
      socket.off('newShoppingCarts', handleShoppingCarts);
    };
  }, [socket]);

  const finishTransaction = async () => {
    //Register single transaction

    var dataToSend = {
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: '',
      description: 'Betal med kort',
      amount: parseInt(payCardTemp),
      cardID: cardID,
      action: 'newTransaction',
      ticketID: userInfoRef.current.id ? userInfoRef.current.id : 0,
      ticketUsername:
        userInfoRef.current.username != '' ? userInfoRef.current.username : '-',
      ticket7Pincode: currentUser,
      user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
      userInfo: !parentInfo ? userInfo : parentInfo,
      netsTicket: receiptToPrintRef.current,
    };

    if (!currentUser || isSingleTransaction) {
      dataToSend = {
        operator: operator,
        bingoName: hall,
        sessionID: session,
        provider: '',
        description: 'Kontant uttak',
        amount: parseInt(payCardTemp),
        cardID: cardID,
        action: 'newTransaction',
        ticketID: userInfoRef.current.id ? userInfoRef.current.id : 0,
        ticketUsername: '-',
        ticket7Pincode: 0,
        user7Pincode: 0,
        userInfo: !parentInfo ? userInfo : parentInfo,
        netsTicket: receiptToPrintRef.current,
      };
    }

    if (cardID !== '') {
      socket.emit('newTransaction', dataToSend);
    }

    if (isSingleTransaction) {
      updateTransactions({
        name: 'Kontant uttak',
        amount: payCardTemp,
        type: 'paymentCard',
      });

      updatePayCard(0);
      updatePayCardTemp(0);
    }

    //Payment remaining
    if (
      total > parseInt(payCardTemp) + payCash + payCard + paySpillerkonto &&
      !isSingleTransaction
    ) {
      updateTransactions({
        name: 'Betal med kort',
        amount: payCardTemp,
        type: 'paymentCard',
      });

      updatePayCard(payCardTemp);
      updatePayCardTemp(0);

      return;
    }

    //Fulfilled Total
    if (
      total === payCardTemp + payCash + payCard + paySpillerkonto &&
      !isSingleTransaction
    ) {
      updateTransactions({
        name: 'Betal med kort',
        amount: payCardTemp,
        type: 'paymentCard',
      });

      setTimeout(() => {
        var data = {
          operator: operator,
          bingoName: hall,
          sessionID: session,
          total: total,
          payCard: payCardTemp,
          payCash: payCash,
          paySpillerkonto: paySpillerkonto,
          exchange: 0,
          user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
          userInfo: !parentInfo ? userInfo : parentInfo,
          transactions: transactionsRef.current,
          print: 0,
          action: 'newShoppingCart',
          ticketID: userInfoRef.current.id,
          ticketUsername: userInfoRef.current.username,
          ticket7Pincode: currentUser,
          netsTicket: receiptToPrint,
        };
        socket.emit('newShoppingCarts', data);
      }, 500);

      return;
    }
  };

  //Input text filter
  const updateAmountModal = (value, isTotal) => {
    if (
      (amountModal === 0 || amountModal === '0') &&
      (value === '0' || value === '00')
    ) {
      return;
    }

    if ((amountModal === '0' || amountModal === 0) && value !== 'backspace') {
      setAmountModal(value);
      updatePayCardTemp(value);

      const tempAmount = parseInt(amountModal + value);
      if (total > 0 && tempAmount > total - payCash - paySpillerkonto) {
        setAmountModal(total - payCash - paySpillerkonto);
        updatePayCardTemp(total - payCash - paySpillerkonto);
      }
      return;
    }
    if ((amountModal === '0' || amountModal === 0) && value === 'backspace') {
      return;
    }
    if (
      value === 'backspace' &&
      amountModal.toString().length === 1 &&
      (amountModal !== '0' || amountModal !== 0)
    ) {
      setAmountModal('0');
      updatePayCardTemp(0);
      return;
    }
    if (
      value === 'backspace' &&
      amountModal.toString().length > 1 &&
      (amountModal !== '0' || amountModal !== 0)
    ) {
      setAmountModal(
        amountModal.toString().substring(0, amountModal.toString().length - 1)
      );
      updatePayCardTemp(
        parseInt(
          amountModal.toString().substring(0, amountModal.toString().length - 1)
        )
      );

      return;
    }

    if (isTotal) {
      setAmountModal(value.toString());
      updatePayCardTemp(value);
      return;
    }

    const tempAmount = parseInt(amountModal + value);
    if (
      total > 0 &&
      tempAmount > total - payCash - paySpillerkonto &&
      !isSingleTransaction
    ) {
      setAmountModal(total - payCash - paySpillerkonto - payCard);
      updatePayCardTemp(total - payCash - paySpillerkonto - payCard);
      return;
    }

    setAmountModal(amountModal + value);
    updatePayCardTemp(amountModal + value);
  };

  return (
    <div className="modal-overlay">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: '9999999 !important',
            fontSize: '24px',
          },
        }}
        containerStyle={{
          zIndex: 99999999, // Ensure this value is higher than the modal's z-index
        }}
      />
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <div
            className="custom-modal-2"
            style={{
              //background: isFail ? "red" : isSuccess ? "green" : "white",
              background: 'white',
              borderRadius: '40px',
              width: '1165px',
              height: '839px',
            }}
          >
            {isWaiting && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: '100%',
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h1
                      style={{
                        color: '#464646',
                      }}
                    >
                      {t('sales.waiting')}
                    </h1>
                    <p>{/* Message here, if any */}</p>

                    <button
                      onClick={() => {
                        var data = {
                          action: 'cancelTransaction',
                          amount: amountModal,
                          operator: operator,
                          bingoName: hall,
                          sessionID: session,
                          user7Pincode: !parentInfo
                            ? currentUser
                            : parentInfo['7 digit PIN'],
                          userInfo: !parentInfo ? userInfo : parentInfo,
                          uuid: uuidTrans,
                        };
                        socket.emit('cancelTransaction', data);
                        setIsWaiting(false);
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                      className="btn-custom-no"
                    >
                      <CancelIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.cancelShop')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {isFail && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: '100%',
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h1
                      style={{
                        color: '#464646',
                      }}
                    >
                      {t('sales.transactionFail')}
                    </h1>
                    <p>{/* Message here, if any */}</p>

                    <button
                      onClick={() => {
                        setIsFail(false);
                        setIsWaiting(false);
                        setIsSuccess(false);

                        updatePayCardTemp(0);
                        updateIsSingleTransaction(false);
                        updateShowCard(false);
                        updateReceiptToPrint(false);
                      }}
                      className="btn-custom-no"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CancelIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.backToPayment')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {isSuccess && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: '100%',
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <h1
                      style={{
                        color: '#464646',
                      }}
                    >
                      {t('sales.transactionDone')}
                    </h1>
                    <p>{/* Message here, if any */}</p>

                    <button
                      onClick={async () => {
                        await finishTransaction();
                        updateIsSingleTransaction(false);

                        updateShowCard(false);
                        //updateReceiptToPrint(false);
                        setIsSuccess(false);
                      }}
                      className="btn-custom-yes"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.confirm')}
                    </button>
                  </div>
                </Grid>
              </Grid>
            )}

            {!isWaiting && !isFail && !isSuccess && (
              <Grid
                item
                xs={12}
                sx={{
                  padding: '10px',
                }}
              >
                {/*Header*/}
                <Grid
                  container
                  sx={{
                    height: '100%',
                    width: '100%',
                    marginTop: 0,
                    marginLeft: 0,
                    justifyContent: 'center',
                    alignContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        padding: '10px',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <Grid
                        container
                        flexDirection="row"
                        sx={{
                          height: '100%',
                          width: '100%',
                          marginTop: 0,
                          marginLeft: 0,
                          justifyContent: 'center',
                          alignContent: 'center',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'row',
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: '10px',
                            height: '75px',
                            width: '100%',
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: 'flex-start',
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <h2
                            style={{
                              color: '#464646',
                            }}
                          >
                            {t('sales.paymentCardScreen')}
                          </h2>
                        </Grid>

                        <Grid
                          container
                          flexDirection="row"
                          sx={{
                            height: 'calc(100% - 125px)',
                            width: '100%',
                            alignContent: 'space-around',
                          }}
                        >
                          {/*Input*/}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: '10px',
                              height: '100px',
                              width: '100%',
                              marginTop: 0,
                              marginLeft: 0,
                              justifyContent: 'center',
                              alignContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Grid
                              container
                              flexDirection="row"
                              sx={{
                                height: '100%',
                                width: '100%',
                                marginTop: 0,
                                marginLeft: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  padding: '10px',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '75px',
                                  fontSize: '19px',
                                  fontWeight: 'bold',
                                  flexDirection: 'row',
                                }}
                              >
                                <Grid
                                  container
                                  flexDirection="row"
                                  sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    marginLeft: '-35px',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={3}
                                    sx={{
                                      padding: '10px',
                                      alignContent: 'center',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      height: '75px',
                                      fontSize: '19px',
                                      fontWeight: 'bold',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <AttachMoneyIcon
                                      sx={{
                                        fontSize: '35px',
                                        margin: '-5px 0 0 10px',
                                        color: '#464646',
                                      }}
                                    />{' '}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={9}
                                    sx={{
                                      padding: '10px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'flex-start',
                                      height: '75px',
                                      fontSize: '19px',
                                      fontWeight: 'bold',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        marginLeft: '-30px',
                                        textTransform: 'uppercase',
                                        color: '#464646',
                                      }}
                                    >
                                      {t('sales.amount')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  marginTop: 0,
                                  padding: '10px',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <TextField
                                  value={amountModal == 0 ? '' : amountModal}
                                  sx={{
                                    '& label': {
                                      position: 'relative !important',
                                      fontSize: '22px !important',
                                      color: '#464646 !important',
                                    },
                                    '& label.Mui-focused': {
                                      position: 'relative !important',
                                      color: '#464646 !important',
                                    },
                                    '& input': {
                                      color: '#464646 !important',
                                      border: '3px solid #464646',
                                      borderRadius: '4px',
                                      height: '50px !important',
                                      width: '275px !important',
                                      fontSize: '40px !important',
                                      textAlign: 'center',
                                    },
                                    '& input.Mui-focused': {
                                      color: '#464646 !important',
                                      border: '3px solid #464646',
                                      borderRadius: '4px',
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          {/*Shortcuts*/}
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: '10px',
                              height: '335px',
                              width: '100%',
                              marginLeft: 0,
                              justifyContent: 'center',
                              alignContent: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              marginTop: '61px',
                            }}
                          >
                            <h2
                              style={{
                                width: '100%',
                                textAlign: 'left',
                                color: '#464646',
                              }}
                            >
                              {t('sales.shortcuts')}
                            </h2>
                            <Grid
                              container
                              flexDirection="row"
                              sx={{
                                height: '100%',
                                width: '100%',
                                marginTop: 0,
                                marginLeft: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  border: '2px solid black',
                                  width: '100%',
                                  maxWidth: '100% !important',
                                  height: '100%',
                                  backgroundColor: '#f0f0f0',
                                }}
                              >
                                <Grid
                                  container
                                  flexDirection="row"
                                  sx={{
                                    height: '100%',
                                    width: '100%',
                                    marginTop: 0,
                                    marginLeft: 0,
                                    display: 'flex',
                                    alignContent: 'space-around',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        height: '100px',
                                        width: '100px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                          height: '100%',
                                          width: '100%',
                                          fontSize: '35px',
                                          fontWeight: 'bold',
                                          backgroundColor: '#889cab',
                                          color: 'white',
                                          marginTop: '0px',
                                        }}
                                        onClick={() => {
                                          setAmountModal(10);
                                          updatePayCardTemp(10);
                                          if (
                                            total > 0 &&
                                            10 >
                                              total -
                                                payCash -
                                                paySpillerkonto &&
                                            !isSingleTransaction
                                          ) {
                                            setAmountModal(
                                              total - payCash - paySpillerkonto
                                            );
                                            updatePayCardTemp(
                                              total - payCash - paySpillerkonto
                                            );
                                          }
                                        }}
                                      >
                                        10
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        height: '100px',
                                        width: '100px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                          height: '100%',
                                          width: '100%',
                                          fontSize: '35px',
                                          fontWeight: 'bold',
                                          backgroundColor: '#889cab',
                                          color: 'white',
                                          marginTop: '0px',
                                        }}
                                        onClick={() => {
                                          setAmountModal(20);
                                          updatePayCardTemp(20);
                                          if (
                                            total > 0 &&
                                            20 >
                                              total -
                                                payCash -
                                                paySpillerkonto &&
                                            !isSingleTransaction
                                          ) {
                                            setAmountModal(
                                              total - payCash - paySpillerkonto
                                            );
                                            updatePayCardTemp(
                                              total - payCash - paySpillerkonto
                                            );
                                          }
                                        }}
                                      >
                                        20
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        height: '100px',
                                        width: '100px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                          height: '100%',
                                          width: '100%',
                                          fontSize: '35px',
                                          fontWeight: 'bold',
                                          backgroundColor: '#889cab',
                                          color: 'white',
                                          marginTop: '0px',
                                        }}
                                        onClick={() => {
                                          setAmountModal(50);
                                          updatePayCardTemp(50);
                                          if (
                                            total > 0 &&
                                            50 >
                                              total -
                                                payCash -
                                                paySpillerkonto &&
                                            !isSingleTransaction
                                          ) {
                                            setAmountModal(
                                              total - payCash - paySpillerkonto
                                            );
                                            updatePayCardTemp(
                                              total - payCash - paySpillerkonto
                                            );
                                          }
                                        }}
                                      >
                                        50
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        height: '100px',
                                        width: '100px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                          height: '100%',
                                          width: '100%',
                                          fontSize: '35px',
                                          fontWeight: 'bold',
                                          backgroundColor: '#889cab',
                                          color: 'white',
                                          marginTop: '0px',
                                        }}
                                        onClick={() => {
                                          setAmountModal(100);
                                          updatePayCardTemp(100);
                                          if (
                                            total > 0 &&
                                            100 >
                                              total -
                                                payCash -
                                                paySpillerkonto &&
                                            !isSingleTransaction
                                          ) {
                                            setAmountModal(
                                              total - payCash - paySpillerkonto
                                            );
                                            updatePayCardTemp(
                                              total - payCash - paySpillerkonto
                                            );
                                          }
                                        }}
                                      >
                                        100
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        height: '100px',
                                        width: '100px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                          height: '100%',
                                          width: '100%',
                                          fontSize: '35px',
                                          fontWeight: 'bold',
                                          backgroundColor: '#889cab',
                                          color: 'white',
                                          marginTop: '0px',
                                        }}
                                        onClick={() => {
                                          setAmountModal(200);
                                          updatePayCardTemp(200);
                                          if (
                                            total > 0 &&
                                            200 >
                                              total -
                                                payCash -
                                                paySpillerkonto &&
                                            !isSingleTransaction
                                          ) {
                                            setAmountModal(
                                              total - payCash - paySpillerkonto
                                            );
                                            updatePayCardTemp(
                                              total - payCash - paySpillerkonto
                                            );
                                          }
                                        }}
                                      >
                                        200
                                      </Button>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sx={{
                                        height: '100px',
                                        width: '100px',
                                        display: 'flex',
                                        alignContent: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                          height: '100%',
                                          width: '100%',
                                          fontSize: '35px',
                                          fontWeight: 'bold',
                                          backgroundColor: '#889cab',
                                          color: 'white',
                                          marginTop: '0px',
                                        }}
                                        onClick={() => {
                                          setAmountModal(500);
                                          updatePayCardTemp(500);
                                          if (
                                            total > 0 &&
                                            500 >
                                              total -
                                                payCash -
                                                paySpillerkonto &&
                                            !isSingleTransaction
                                          ) {
                                            setAmountModal(
                                              total - payCash - paySpillerkonto
                                            );
                                            updatePayCardTemp(
                                              total - payCash - paySpillerkonto
                                            );
                                          }
                                        }}
                                      >
                                        500
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {/*END Shortcuts*/}

                          {/* Bank Axept */}
                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              component="img"
                              sx={{
                                height: '70px',
                                width: 'auto',
                                paddingTop: 0,
                              }}
                              alt=""
                              src={BankAxept}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sx={{
                        padding: '10px',
                      }}
                    >
                      <Calculator
                        updateAmount={updateAmountModal}
                        isColorCard={true}
                      />
                      <Grid
                        item
                        xs={12}
                        sx={{
                          width: '100%',
                          maxWidth: '100% !important',
                          height: '100%',
                        }}
                      >
                        <Grid
                          container
                          flexDirection="row"
                          sx={{
                            height: '125px',
                            width: '100%',
                            marginTop: 0,
                            marginLeft: 0,
                            padding: '10px',
                          }}
                        >
                          {/*Input*/}

                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                width: '200px',
                                height: '90px',
                                backgroundColor:
                                  !userLogin &&
                                  (amountModal === 0 || amountModal === '0')
                                    ? 'grey'
                                    : '#009f83',
                                opacity:
                                  !userLogin &&
                                  (amountModal === 0 || amountModal === '0')
                                    ? '0.25'
                                    : '1',
                                color: 'white',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                              onClick={() => {
                                if (
                                  !userLogin &&
                                  (amountModal === 0 || amountModal === '0')
                                ) {
                                  return;
                                }
                                //Modal de confirmacion sin vuelta
                                //Añadir transaccion de pago
                                //Imprimir

                                if (
                                  total > 0 &&
                                  (amountModal === 0 || amountModal === '0')
                                ) {
                                  setAmountModal(
                                    total -
                                      (payCash + payCard + paySpillerkonto)
                                  );
                                  updatePayCardTemp(
                                    total -
                                      (payCash + payCard + paySpillerkonto)
                                  );

                                  //* ENABLE CARD PAYMENT
                                  if (cardID !== '') {
                                    //if (false) {
                                    var data = {
                                      amount:
                                        total -
                                        (payCash + payCard + paySpillerkonto),
                                      operator: operator,
                                      bingoName: hall,
                                      sessionID: session,
                                      user7Pincode: !parentInfo
                                        ? currentUser
                                        : parentInfo['7 digit PIN'],
                                      userInfo: !parentInfo
                                        ? userInfo
                                        : parentInfo,
                                      uuid: uuidTrans,
                                      cardID: cardID,
                                      action: 'netsTransaction',
                                    };
                                    socket.emit('netsTransaction', data);
                                    setIsWaiting(true);
                                  } else {
                                    setIsSuccess(true);
                                    var dataToSend = {
                                      operator: operator,
                                      bingoName: hall,
                                      sessionID: session,
                                      provider: '',
                                      description: 'Betal med kort',
                                      amount:
                                        total -
                                        (payCash + payCard + paySpillerkonto),
                                      cardID: cardID,
                                      action: 'newTransaction',
                                      ticketID: userInfoRef.current.id
                                        ? userInfoRef.current.id
                                        : '',
                                      ticketUsername: userInfoRef.current
                                        .username
                                        ? userInfoRef.current.username
                                        : '',
                                      ticket7Pincode: currentUser,
                                      user7Pincode: !parentInfo
                                        ? currentUser
                                        : parentInfo['7 digit PIN'],
                                      userInfo: !parentInfo
                                        ? userInfo
                                        : parentInfo,
                                      netsTicket: receiptToPrintRef.current,
                                    };

                                    socket.emit('newTransaction', dataToSend);
                                  }

                                  return;
                                }

                                if (cardID !== '') {
                                  var data = {
                                    amount: amountModal,
                                    operator: operator,
                                    bingoName: hall,
                                    sessionID: session,
                                    user7Pincode: !parentInfo
                                      ? currentUser
                                      : parentInfo['7 digit PIN'],
                                    userInfo: !parentInfo
                                      ? userInfo
                                      : parentInfo,
                                    uuid: uuidTrans,
                                    cardID: cardID,
                                    action: 'netsTransaction',
                                    ticketID: userInfoRef.current.id
                                      ? userInfoRef.current.id
                                      : '',
                                    ticketUsername: userInfoRef.current.username
                                      ? userInfoRef.current.username
                                      : '',
                                    ticket7Pincode: currentUser,
                                  };
                                  socket.emit('netsTransaction', data);

                                  setIsWaiting(true);
                                } else {
                                  setIsSuccess(true);
                                  var dataToSend = {
                                    operator: operator,
                                    bingoName: hall,
                                    sessionID: session,
                                    provider: '',
                                    description: 'Betal med kort',
                                    amount: parseInt(amountModal),
                                    user7Pincode: !parentInfo
                                      ? currentUser
                                      : parentInfo['7 digit PIN'],
                                    userInfo: !parentInfo
                                      ? userInfo
                                      : parentInfo,
                                    cardID: cardID,
                                    action: 'newTransaction',
                                    ticketID: userInfoRef.current.id
                                      ? userInfoRef.current.id
                                      : '',
                                    ticketUsername: userInfoRef.current.username
                                      ? userInfoRef.current.username
                                      : '',
                                    ticket7Pincode: currentUser,
                                    netsTicket: receiptToPrintRef.current,
                                  };

                                  socket.emit('newTransaction', dataToSend);
                                }
                                return;
                              }}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px', // Space between icon and text
                              }}
                            >
                              <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                              {t('sales.confirm')}
                            </Button>
                          </Grid>

                          <Grid
                            item
                            xs={6}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              variant="contained"
                              component="label"
                              sx={{
                                width: '200px',
                                height: '90px',
                                backgroundColor: '#fc86c1',
                                color: 'white',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                              }}
                              onClick={() => {
                                updateIsSingleTransaction(false);

                                updateShowCard(false);
                                updateReceiptToPrint(false);
                                updatePayCardTemp(0);
                              }}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px', // Space between icon and text
                              }}
                            >
                              <CancelIcon style={{ fontSize: '1.2em' }} />

                              {t('sales.cancel')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardPayment;
