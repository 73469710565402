import React, { useEffect, useContext } from 'react';
import { Typography, Grid, Button, Box } from '@mui/material';

import {
  PointOfSale as PointOfSaleIcon,
  Money as MoneyIcon,
  Receipt as ReceiptIcon,
  AddCard,
  AccountCircle,
  QrCodeScanner as QrCodeScannerIcon,
  Coffee as CoffeeIcon,
  LocalPrintshop as LocalPrintshopIcon,
} from '@mui/icons-material';

import { AppContext } from '../../../context/Context';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { SocketContext } from '../../../context/SocketContext';
import { confirmAlert } from 'react-confirm-alert';

function VendorItemOptions({ vendor }) {
  const {
    isKioskEnabled,
    session,
    userLogin,
    updateCurrentUser,
    updateUserLogin,
    isBlockProviderOK,
    isBlockProviderWG,
    isBlockProviderNT,
    isBlockProviderEBS,
    updateIsSellerOpen,
    updateIsOcrOpen,
    updateVendorInfo,
    updateSellType,
    isFullUser,
    parentInfo,
    updateShowCardToCash,
    updateShowSpillerkonto,
    updateIsSingleTransaction,
    hall,
    printer,
    currentUser,
    updateUserInfo,
    updateShowKiosk,
    isLimitSurpassed,
    isDailyLimitSurpassed,
    isMonthlyLimitSurpassed,
    updateShowIsLimitSurpassed,
    isVerificationNeeded,
    updateIsVerificationNeeded,
    showModalIsVerificationNeeded,
    updateShowModalIsVerificationNeeded,
    isOKBingoEnabled,
  } = useContext(AppContext);

  const { socket } = useContext(SocketContext);
  const { t } = useTranslation();

  const getNewAnonPlayerTask = () => {
    var data = {
      action: 'getNewAnonPlayer',
      session: session,
    };
    socket.emit('getNewAnonPlayer', data);
  };

  useEffect(() => {
    const handleGetNewAnonPlayer = (data) => {
      if (!data) {
        toast.error(t('error.login'));
        return;
      }
      updateUserLogin(true);
      updateCurrentUser(data['7 digit PIN']);
    };

    socket.on('getNewAnonPlayer', handleGetNewAnonPlayer);

    return () => {
      socket.off('getNewAnonPlayer', handleGetNewAnonPlayer);
    };
  }, [socket]);

  const checkColor = (type, originalColor) => {
    if (
      type === 9 ||
      type === 10 ||
      type === 12 ||
      type === 13 ||
      type === 14
    ) {
      return originalColor;
    }
    if (!userLogin || (userLogin && currentUser === 'Gjest')) {
      return 'grey';
    }

    //Full member as parent, loggin with children tickets

    if (
      type === 11 &&
      !isFullUser &&
      (!parentInfo ||
        (parentInfo &&
          (parentInfo.username.startsWith('anon-') ||
            parentInfo.username.startsWith('hybrid-'))))
    ) {
      return 'grey';
    }

    if (!isFullUser && !parentInfo && (type == 1 || type == 2 || type == 11)) {
      return 'grey';
    }
    return originalColor;
  };

  const checkOpacity = (type, originalColor) => {
    if (
      type === 9 ||
      type === 10 ||
      type === 12 ||
      type === 13 ||
      type === 14
    ) {
      return '1';
    }
    if (!userLogin || (userLogin && currentUser === 'Gjest')) {
      return '0.25';
    }

    //Full member as parent, loggin with children tickets
    if (
      type === 11 &&
      !isFullUser &&
      (!parentInfo ||
        (parentInfo &&
          (parentInfo.username.startsWith('anon-') ||
            parentInfo.username.startsWith('hybrid-'))))
    ) {
      return '0.25';
    }

    if (!isFullUser && !parentInfo && (type == 1 || type == 2 || type == 11)) {
      return '0.25';
    }
    return '1';
  };

  const checkLogin = (type) => {
    if (
      type === 9 ||
      type === 10 ||
      type === 12 ||
      type === 13 ||
      type === 14
    ) {
      return false;
    }
    if (!userLogin || (userLogin && currentUser === 'Gjest')) {
      //CHANGE THIS FOR MODAL
      toast.error(t('error.userNotLogin'));
      return false;
    }

    if (
      type === 11 &&
      !isFullUser &&
      (!parentInfo || (parentInfo && parentInfo.username.startsWith('anon-')))
    ) {
      toast.error(t('error.userNotMember'));
      return false;
    }

    if (!isFullUser && !parentInfo && (type == 1 || type == 2)) {
      toast.error(t('error.userNotMember'));
      return false;
    }
    return true;
  };

  const modalConfirm = (providerCode, vendor, option) => {
    var confirmMessage = t('sales.confirmOK');

    switch (providerCode) {
      case 'OK':
        confirmMessage = t('sales.confirmOK');
        break;
      case 'WG':
        confirmMessage = t('sales.confirmWG');
        break;
      case 'NT':
        confirmMessage = t('sales.confirmNT');
        break;
      default:
        break;
    }

    confirmAlert({
      title: confirmMessage,
      message: '',
      buttons: [
        {
          label: t('sales.confirm'),
          onClick: () => {
            updateIsSellerOpen(true);
            updateVendorInfo(vendor);
            updateSellType(option.type);
          },
          className: 'btn-custom-yes',
        },

        {
          label: t('sales.cancel'),
          onClick: () => {},
          className: 'btn-custom-no',
        },
      ],
    });
  };

  async function startsWithPrefix(str) {
    return str.startsWith('anon-') || str.startsWith('hybrid-');
  }

  const handlePrint = () => {
    socket.emit('printLastShoppingCart', { printer: printer, bingoName: hall });
  };

  const handleClickButton = async (option, providerCode) => {
    if (isVerificationNeeded && currentUser && currentUser !== 'Gjest') {
      updateShowModalIsVerificationNeeded(true);
      return;
    }
    if (
      isLimitSurpassed &&
      currentUser &&
      currentUser !== 'Gjest' &&
      (option.type === 1 ||
        option.type === 3 ||
        option.type === 4 ||
        option.type === 6 ||
        option.type === 24 ||
        option.type === 26)
    ) {
      updateShowIsLimitSurpassed(true);
      return;
    }
    var isParentAnon = true;

    if (parentInfo !== false) {
      isParentAnon = await startsWithPrefix(parentInfo.username);
    }

    //Show modal for payment with card
    if (option.type === 10) {
      updateShowCardToCash(true);
      updateIsSingleTransaction(true);
      return;
    }

    //Print last shopping cart
    if (option.type === 12) {
      handlePrint();
      return;
    }

    //Norks Tipping
    if (option.type === 13 || option.type === 14) {
      updateIsSellerOpen(true);
      updateVendorInfo(vendor);
      updateSellType(option.type);

      if (!currentUser) {
        updateCurrentUser('Gjest');
        updateUserLogin(true);
        updateUserInfo({ username: 'Gjest', firstname: 'Gjest' });
      }
    }

    //Kiosk
    if (option.type === 9) {
      if (!currentUser) {
        updateCurrentUser('Gjest');
        updateUserLogin(true);
        updateUserInfo({ username: 'Gjest', firstname: 'Gjest' });
      }
      updateShowKiosk(true);
      return;
    }

    if (
      option.type === 11 &&
      (isFullUser || (parentInfo !== false && !isParentAnon))
    ) {
      updateShowSpillerkonto(true);
      return;
    }
    if (!checkLogin(option.type)) {
      return;
    }
    /*OCR SCREEN*/
    if (option.type === 8) {
      if (!userLogin) {
        toast.error(t('sales.scanMessage'));

        return;
      }
      updateIsOcrOpen(true);
      updateVendorInfo(vendor);
      updateSellType(option.type);
      return;
    }

    /* SELL TICKET CH,WG,OK */
    if (
      option.type === 4 ||
      option.type === 5 ||
      option.type === 24 ||
      option.type === 25
    ) {
      if (
        ((isBlockProviderOK && providerCode === 'OK') ||
          (isBlockProviderWG && providerCode === 'WG') ||
          (isBlockProviderEBS && providerCode === 'EBS')) &&
        (option.type === 4 || option.type === 24)
      ) {
        toast.error(t('error.blockProvider'));
        return;
      }

      if (
        ((providerCode === 'WG' ||
          providerCode === 'NT' ||
          providerCode === 'EBS') &&
          option.type === 5) ||
        option.type === 13 ||
        option.type === 14
      ) {
        modalConfirm(providerCode, vendor, option);
        return;
      }

      if (
        (providerCode === 'OK' && !isOKBingoEnabled && option.type === 5) ||
        option.type === 13 ||
        option.type === 14
      ) {
        modalConfirm(providerCode, vendor, option);
        return;
      }

      updateIsSellerOpen(true);
      updateVendorInfo(vendor);
      updateSellType(option.type);
    }
    /*DEPOSIT,WITHDRAW,BANKTRANSFER CH*/
    if (
      option.type === 1 ||
      option.type === 2 ||
      option.type === 3 ||
      option.type === 6 ||
      option.type === 26
    ) {
      if (!userLogin) {
        toast.error(t('sales.scanMessage'));
        return;
      }

      if (
        (providerCode === 'OK' || providerCode === 'WG') &&
        option.type === 5
      ) {
        modalConfirm(providerCode, vendor, option);
        return;
      }

      updateIsSellerOpen(true);
      updateVendorInfo(vendor);
      updateSellType(option.type);
    }
    if (option.type === 9) {
      /* */
    }
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        height: 'calc(100% - 100px)',
        width: '100%',
        marginTop: 0,
        marginLeft: 0,
      }}
    >
      <Toaster position="top-center" reverseOrder={false} />

      {/*BUTTONS CONTAINER CONTAINER*/}

      <Grid
        container
        justifyContent="flex-start"
        alignContent="flex-start"
        flexDirection="row"
        sx={{
          width: '100%',
          height: '100%',
          marginTop: 0,
          marginLeft: 0,
          flexGrow: 1,
          paddingTop: '0px !important',
        }}
      >
        {vendor.options.map((option, index) => {
          var providerCode = '';
          if (vendor.name === 'WinGames') {
            providerCode = 'WG';
          } else if (vendor.name === 'OK Databingo') {
            providerCode = 'OK';
          } else if (vendor.name === 'Norsk Tipping') {
            providerCode = 'NT';
          } else if (vendor.name === 'EBS') {
            providerCode = 'EBS';
          }
          if (option.type === 8) {
            return;
          }
          if (option.type === 9 && !isKioskEnabled) {
            return;
          }
          /*
          if (option.type === 10) {
            return;
          }
          */
          return (
            <Grid
              item
              key={index}
              xs={6}
              sx={{
                width: '100%',
                marginLeft: 0,
                display: 'flex',
                alignContent: 'flex-start',
                alignItems: 'flex-start',
                justifyContent: 'center',
                padding: '15px',
                marginTop: 0,
              }}
            >
              <Button
                variant="contained"
                component="label"
                sx={{
                  height: '100px',
                  width: '125px',
                  marginTop: '0px',
                  padding: 0,
                  marginLeft: 0,
                  backgroundColor: checkColor(option.type, option.color),
                  opacity: checkOpacity(option.type, option.color),
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                  fontSize: '17px',
                  textAlign: 'center',
                }}
                onClick={async () => {
                  await handleClickButton(option, providerCode);
                }}
              >
                <Box display="flex" flexDirection="column" alignItems="center">
                  {option.type === 1 && <PointOfSaleIcon />}
                  {option.type === 2 && <MoneyIcon />}
                  {/*option.type === 11 && <SavingsIcon />*/}
                  {option.type === 3 && <ReceiptIcon />}
                  {(option.type === 4 ||
                    option.type === 24 ||
                    option.type === 13) && <ReceiptIcon />}
                  {(option.type === 5 ||
                    option.type === 25 ||
                    option.type === 14) && <MoneyIcon />}
                  {(option.type === 6 || option.type === 26) && <ReceiptIcon />}
                  {option.type === 7 && <ReceiptIcon />}
                  {option.type === 8 && <QrCodeScannerIcon />}
                  {option.type === 9 && <CoffeeIcon />}
                  {option.type === 10 && <AddCard />}
                  {option.type === 11 && <AccountCircle />}
                  {option.type === 12 && <LocalPrintshopIcon />}
                  <Typography
                    variant="subtitle1"
                    component="span"
                    sx={{
                      marginTop: '10px',
                      color: 'white',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      fontSize: '12px',
                    }}
                  >
                    {option.option}
                  </Typography>
                </Box>
              </Button>
            </Grid>
          );
        })}
        {/*BUTTON ITEM*/}
      </Grid>
    </Grid>
  );
}

export default VendorItemOptions;
