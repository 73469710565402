import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalOpenSession() {
  const { t } = useTranslation();

  const {
    maxAllowed,
    updateFocusOnMainScreen,
    updateShowIsLimitSurpassedTransaction,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {}, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmClose = async () => {
    updateFocusOnMainScreen(true);
    updateShowIsLimitSurpassedTransaction(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.isLimitSurpassed')} </h1>{' '}
            <p>{t('sales.isLimitSurpassedTransaction1')}</p>
            <p>
              {t('sales.isLimitSurpassedTransaction2')}
              <span
                style={{
                  fontSize: '22px',
                  color: '#42a5f5',
                  fontWeight: 'bold',
                }}
              >
                {maxAllowed + ' kr.'}
              </span>
            </p>
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: '55px' }}
            >
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalOpenSession;
