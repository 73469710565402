import React, { useContext } from "react";
import { Grid, Button, Typography } from "@mui/material";
import {
  ArrowRight as ArrowRightIcon,
  ArrowLeft as ArrowLeftIcon,
  FastForward as FastForwardIcon,
  FastRewind as FastRewindIcon,
} from "@mui/icons-material";

import db from "../../../../data/db";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/Context";

function VendorSlider({ providerIndex, updateProviderIndex }) {
  const { t } = useTranslation();
  const { isAnonUser, isHybridUser, isFullUser } = useContext(AppContext);
  return (
    <>
      {/* Main Footer Container */}
      <Grid
        container
        sx={{
          height: "200px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
        }}
      >
        {/* Left side */}
        <Grid
          item
          xs={4}
          sx={{
            padding: "10px",
          }}
        >
          <Grid
            container
            sx={{
              height: "100%",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
            }}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/* Button 1st position */}
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "80px",
                  width: "80px",
                  backgroundColor: "#889cab",
                  "&:hover": {
                    backgroundColor: "#e566a7",
                  },
                  fontWeight: "bold",
                  color: "white",
                  opacity: "0.25",
                }}
                onClick={() => {
                  updateProviderIndex(1);
                }}
              >
                <FastRewindIcon sx={{ fontSize: "50px" }} />
              </Button>
            </Grid>

            {/* Button Back */}
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "80px",
                  width: "80px",
                  backgroundColor: "#889cab",
                  "&:hover": {
                    backgroundColor: "#e566a7",
                  },
                  fontWeight: "bold",
                  color: "white",
                  opacity: "0.25",
                }}
                onClick={() => {
                  if (providerIndex === 1) {
                    updateProviderIndex(parseInt(db[0].vendors.length / 6 + 1));
                    return;
                  }

                  updateProviderIndex(providerIndex - 1);
                }}
              >
                <ArrowLeftIcon sx={{ fontSize: "70px" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Offset / Messages */}

        <Grid
          item
          xs={4}
          sx={{
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ color: "gold", fontSize: "21px", textAlign: "center" }}
          >
            {!isAnonUser &&
              !isFullUser &&
              !isHybridUser &&
              t("sales.scanMessage")}
          </Typography>
        </Grid>

        {/* Right Side */}
        <Grid
          item
          xs={4}
          sx={{
            padding: "10px",
          }}
        >
          <Grid
            container
            sx={{
              height: "100%",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
            }}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/* Button Forward */}
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "80px",
                  width: "80px",
                  backgroundColor: "#889cab",
                  "&:hover": {
                    backgroundColor: "#e566a7",
                  },
                  fontWeight: "bold",
                  color: "white",
                  opacity: "0.25",
                }}
                onClick={() => {
                  let prevNextIndex = providerIndex + 1;

                  if (db[0].vendors.length < prevNextIndex * 6 - 2) {
                    updateProviderIndex(1);
                    return;
                  }
                  updateProviderIndex(providerIndex + 1);
                }}
              >
                <ArrowRightIcon sx={{ fontSize: "70px" }} />
              </Button>
            </Grid>

            {/* Button Last position */}
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  height: "80px",
                  width: "80px",
                  backgroundColor: "#889cab",
                  "&:hover": {
                    backgroundColor: "#e566a7",
                  },
                  fontWeight: "bold",
                  color: "white",
                  opacity: "0.25",
                }}
                onClick={() => {
                  updateProviderIndex(parseInt(db[0].vendors.length / 6 + 1));
                }}
              >
                <FastForwardIcon sx={{ fontSize: "50px" }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default VendorSlider;
