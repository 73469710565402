import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalAnonUserRegistration() {
  const { t } = useTranslation();

  const {
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsFullUser,
    printer,
    updateIsAnonUserRegistration,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleSearchMember = (data) => {
      if (
        !data ||
        !data.records ||
        data.records.length === 0 ||
        data.records.length > 1
      ) {
        toast.error(t('error.error'));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]['7 digit PIN']);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === 'anon' ||
        (data.records[0].firstname === '' && data.records[0].mobile === '')
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            action: 'searchMemberParent',
            session: session,
          };
          socket.emit('searchMemberParent', data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: dataToRegisterObj[0]['7 digit PIN'],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ''
            ? 'Uregistrert'
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);

      updateFocusOnMainScreen(true);
      updateIsAnonUserRegistration(false);
    };

    const handleGetNewAnonHybridMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      var data = {
        auth_value: data.username,
        action: 'searchMember',
        session: session,
      };
      socket.emit('searchMember', data);

      //Print presentation ticket
      setTimeout(() => {
        var dataPrint = {
          text: '',
          name: printer.name,
          id: printer.id,
          ip: printer.ip,
          dataToPrint: data['7 digit PIN'],
          action: 'print',
        };

        //socket.emit("print", dataPrint);
      }, 2000);
    };

    socket.on('searchMember', handleSearchMember);
    socket.on('getNewAnonHybridMember', handleGetNewAnonHybridMember);

    return () => {
      socket.off('searchMember', handleSearchMember);
      socket.off('getNewAnonHybridMember', handleGetNewAnonHybridMember);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmRegister = async () => {
    var data = {
      action: 'getNewAnonHybridMember',
      session: session,
      printer: printer,
    };
    socket.emit('getNewAnonHybridMember', data);
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    updateFocusOnMainScreen(true);
    updateIsAnonUserRegistration(false);
  };
  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    if (data.operator === '') {
      return;
    }
    socket.emit('registerLastUsers', data);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.newUser')}</h1>
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: '55px' }}
            >
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmRegister();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.registerNewUserConfirm')}
              </button>
              <button
                className="btn-custom-no"
                onClick={() => {
                  cancel();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CancelIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalAnonUserRegistration;
