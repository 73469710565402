import React, { createContext } from "react";
import io from "socket.io-client";

export const SocketContext = createContext();

const SocketProvider = ({ children }) => {
  const hostname = window.location.hostname;
  var socket;
  var socketPW;

  if (hostname === "localhost") {
    socket = io("https://stage01-operator.vpfscandinavia.com");
    socketPW = io("https://stage01-powerbingo.vpfscandinavia.com");
  } else if (hostname.includes("stage01")) {
    socket = io("https://stage01-operator.vpfscandinavia.com");
    socketPW = io("https://stage01-powerbingo.vpfscandinavia.com");
  } else if (hostname.includes("stage02")) {
    socket = io("https://stage02-operator.vpfscandinavia.com");
    socketPW = io("https://stage02-powerbingo.vpfscandinavia.com");
  } else {
    socket = io("https://operator.vpfscandinavia.com");
    socketPW = io("https://powerbingo.vpfscandinavia.com");
  }

  return (
    <SocketContext.Provider value={{ socket, socketPW }}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
