import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast, { Toaster } from 'react-hot-toast';
import { Box } from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  NextPlan as NextPlanIcon,
} from '@mui/icons-material/';

function ModalSignatureID() {
  const { t } = useTranslation();

  const {
    updateImageVerificationSignatureTemp,
    hall,
    session,
    updateShowModalSignatureStep1,
    updateShowModalSignatureStep2,
    updateIsNeedSignature,
    signatureTemp,
    updateSignatureTemp,
    updateSignatureInfo,
    updateReactivateNeedSignatureModal,
  } = useContext(AppContext);
  const { socket } = useContext(SocketContext);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    if (signatureTemp && signatureTemp.fileBuffer && signatureTemp.filename) {
      // Convert ArrayBuffer to Blob
      const blob = new Blob([signatureTemp.fileBuffer], { type: 'image/png' });

      // Create object URL for the image
      const imageUrl = URL.createObjectURL(blob);
      setImageSrc(imageUrl);
    }
  }, [signatureTemp]);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleConfirmSignatureCheck = async () => {
    updateShowModalSignatureStep2(true);
    updateShowModalSignatureStep1(false);
  };

  const handleCancelSignatureCheck = async () => {
    updateSignatureTemp(false);
    updateIsNeedSignature(true);
    updateShowModalSignatureStep1(false);
  };

  const handleRestart = async () => {
    socket.emit('activateSignature', { bingoName: hallRef.current });
    updateSignatureTemp(false);
    updateSignatureInfo(false);
    updateIsNeedSignature(true);
    updateImageVerificationSignatureTemp(true);
    updateReactivateNeedSignatureModal(true);
    updateShowModalSignatureStep1(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <>
              <h1>{t('sales.userSignatureCheckTitle')} </h1>
              <p>{t('sales.userSignatureCheckText')} </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    maxWidth: 300,
                  }}
                  alt={signatureTemp.fileName}
                  src={imageSrc || 'placeholder_image_url'} // Provide a fallback placeholder if needed
                />
              </div>

              <div
                className="react-confirm-alert-button-group"
                style={{ marginTop: '55px' }}
              >
                <button
                  className="btn-custom-yes"
                  style={{
                    backgroundColor: '#009e82 !important',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                  onClick={() => {
                    handleConfirmSignatureCheck();
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                  {t('sales.confirm')}
                </button>
                <button
                  className="btn-custom-thirdOption"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                  onClick={() => {
                    handleRestart();
                  }}
                >
                  <NextPlanIcon style={{ fontSize: '1.2em' }} />
                  {t('sales.restart')}
                </button>
                <button
                  className="btn-custom-no"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                  onClick={() => {
                    handleCancelSignatureCheck();
                  }}
                >
                  <CancelIcon style={{ fontSize: '1.2em' }} />
                  {t('sales.cancel')}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalSignatureID;
