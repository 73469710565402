import React, { useEffect, useState, useContext, useRef } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalCashoutPending() {
  const { t } = useTranslation();

  const {
    updateTransactions,
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    updateIsCashoutPending,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  const [amount, setAmount] = useState(0);
  const amounRef = useRef();
  useEffect(() => {
    amounRef.current = amount;
  }, [amount]);

  useEffect(() => {
    //TEST
    //setAmount(10);
    setAmount(parseInt(userInfo.balances.cashout));
  }, [userInfo]);

  useEffect(() => {
    const handleCashoutLoginTask = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      updateTransactions({
        name: 'Utbetaling fra terminal - CH',
        amount: amounRef.current * -1,
        type: 'transaction',
      });

      updateIsCashoutPending(false);
      updateFocusOnMainScreen(true);
    };

    socket.on('cashoutLoginTask', handleCashoutLoginTask);

    return () => {
      socket.off('cashoutLoginTask', handleCashoutLoginTask);
    };
  }, [socket]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmCashout = () => {
    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: 'CH',
      description: 'Utbetaling fra terminal',
      amount: parseInt(userInfo.balances.cashout),
      //amount: amounRef.current,
      user7Pincode: currentUser,
      userInfo: userInfo,
      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      playerID: userInfo.id,
      cashoutType: 'cash_from_cashout_wallet',
      //cashoutType: "cash_from_wallet",
      ticketID: userInfo.id,
      ticketUsername: userInfo.username,
      ticket7Pincode: currentUser,

      action: 'cashoutLoginTask',
    };

    socket.emit('cashoutLoginTask', data);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>
              {t('sales.cashoutPending') + ' ' + userInfo.balances.cashout}{' '}
            </h1>
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: '55px' }}
            >
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmCashout();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.cashoutNow')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalCashoutPending;
