import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../../../context/Context";
import { useTranslation } from "react-i18next";
import "../../../assets/css/buttons.css";
import { SocketContext } from "../../../context/SocketContext";
import toast, { Toaster } from "react-hot-toast";

function ModalProfileImage() {
  const { t } = useTranslation();

  const {
    hall,
    session,
    operator,
    userInfo,
    parentInfo,
    updateFocusOnMainScreen,
    updateShowModalProfileImage,
    userImageInfo,
    updateShowModalPictureVerification,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  useEffect(() => {}, [socket]);

  const cancel = async () => {
    updateFocusOnMainScreen(true);
    updateShowModalProfileImage(false);
  };

  const handleNewImage = async () => {
    updateFocusOnMainScreen(true);
    updateShowModalProfileImage(false);
    updateShowModalPictureVerification(true);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <>
              <h1>{t("sales.userVerificationImage")} </h1>
              <div
                style={{
                  width: "auto",
                  height: "500px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "auto",
                    height: "100%",
                    objectFit: "contain",
                    padding: "15px",
                  }}
                  src={userImageInfo.imageUrl}
                  alt={userImageInfo.blobName}
                />
              </div>

              <div
                className="react-confirm-alert-button-group"
                style={{ marginTop: "55px" }}
              >
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleNewImage();
                  }}
                >
                  {t("sales.userVerificationImageNew")}
                </button>
                <button
                  className="btn-custom-no"
                  onClick={() => {
                    cancel();
                  }}
                >
                  {t("sales.cancel")}
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalProfileImage;
