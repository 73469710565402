import React, { useContext } from "react";
import English from "../../assets/img/english.png";
import Norsk from "../../assets/img/norsk.png";
import { Grid, Box, Typography } from "@mui/material";
import {
  Logout as LogoutIcon,
  AccountCircle as AccountCircleIcon,
  PointOfSale,
  Refresh as RefreshIcon,
} from "@mui/icons-material/";
import { useTranslation } from "react-i18next";
import "../../locales/i18n.js";
import { AppContext } from "../../context/Context";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function UserButtons() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const {
    modifyLogin,
    modifyOperator,
    modifyOperatorFull,
    modifyHall,
    operatorFull,
    hall,
    updateSession,
    transactions,
  } = useContext(AppContext);

  return (
    <Grid
      container
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignContent: "space-around",
        alignItems: "space-around",
        padding: "10px",
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "0px !important",
          paddingLeft: "0px !important!",
          display: "flex",
          justifyContent: "center",
          alignContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "20px",
        }}
      >
        <AccountCircleIcon />
        <Typography sx={{ marginLeft: "20px", fontSize: "18px" }}>
          {operatorFull}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "0px !important",
          paddingLeft: "0px !important!",
          display: "flex",
          justifyContent: "center",
          alignContent: "flex-start",
          alignItems: "flex-start",
          marginTop: "15px",
        }}
      >
        <PointOfSale />
        <Typography sx={{ marginLeft: "20px", fontSize: "18px" }}>
          {hall}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "0px !important",
          paddingLeft: "0px !important!",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
          sx={{
            height: "100%",
            width: "100%",
            marginTop: "15px",
          }}
        >
          <Grid
            item
            sx={{
              paddingTop: "0px !important",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItem: "center",
            }}
            xs={3}
          >
            <Box
              component="img"
              sx={{
                height: "auto",
                width: 30,
                paddingTop: 0,
                cursor: "pointer",
              }}
              alt=""
              src={Norsk}
              onClick={() => {
                i18n.changeLanguage("no");
                localStorage.setItem("language", "no");
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              paddingTop: "0px !important",
              paddingTop: "0px !important",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItem: "center",
            }}
            xs={3}
          >
            <Box
              component="img"
              sx={{
                height: "auto",
                width: 30,
                paddingTop: 0,
                cursor: "pointer",
              }}
              alt=""
              onClick={() => {
                i18n.changeLanguage("en");
                localStorage.setItem("language", "en");
              }}
              src={English}
            />
          </Grid>
          <Grid
            item
            sx={{
              paddingTop: "0px !important",
              paddingTop: "0px !important",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItem: "center",
            }}
            xs={3}
            onClick={() => {
              modifyLogin(false);
              modifyLogin(false);
              modifyOperator("");
              modifyOperatorFull("");
              modifyHall("");
              updateSession("");
              navigate("/login");
            }}
          >
            <LogoutIcon
              sx={{ fontSize: "35px", cursor: "pointer" }}
              onClick={() => {
                modifyLogin(false);
                modifyLogin(false);
                modifyOperator("");
                modifyOperatorFull("");
                modifyHall("");
                updateSession("");
                navigate("/login");
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              paddingTop: "0px !important",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItem: "center",
            }}
            xs={3}
            onClick={() => {
              window.location.reload();
            }}
          >
            <RefreshIcon
              sx={{ fontSize: "35px", cursor: "pointer" }}
              onClick={() => {
                if (transactions.length > 0) {
                  toast.error(t("errorRefreshTransactions"));
                  return;
                }
                window.location.reload();
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserButtons;
