import React, { useEffect, useState, useContext, useRef } from 'react';
import { Grid, TextField, Typography, Button, Box } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import Calculator from '../../../components/Calculator/Calculator';
import BankAxept from '../../../assets/img/bankaxept.png';
import { SocketContext } from '../../../context/SocketContext';
import { Toaster } from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function CardPayment() {
  const { t } = useTranslation();
  const { socket } = useContext(SocketContext);

  const {
    updateShowUserInfo,
    updateUserInfo,

    updateIsSingleTransaction,
    print,
    printer,

    updateShowCardToCash,
    userLogin,
    session,
    hall,
    operator,
    currentUser,
    userInfo,
    transactions,
    updateTransactions,
    updateCurrentUser,
    updateUserLogin,

    parentInfo,
    receiptToPrint,
    updateReceiptToPrint,
  } = useContext(AppContext);

  const [amountModal, setAmountModal] = useState(0);

  useEffect(() => {
    updateShowUserInfo(false);

    if (!currentUser) {
      updateCurrentUser('Gjest');
      updateUserLogin(true);
      updateUserInfo({ username: 'Gjest', firstname: 'Gjest' });
    }
  }, []);

  const userInfoRef = useRef(userInfo);
  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  const receiptToPrintRef = useRef(receiptToPrint);
  useEffect(() => {
    receiptToPrintRef.current = receiptToPrint;
  }, [receiptToPrint]);

  const printRef = useRef(print);
  useEffect(() => {
    printRef.current = print;
  }, [print]);

  const printerRef = useRef(printer);
  useEffect(() => {
    printerRef.current = printer;
  }, [printer]);
  const transactionsRef = useRef(transactions);

  useEffect(() => {
    transactionsRef.current = transactions;
  }, [transactions]);

  useEffect(() => {
    //socket.on("netsTransaction", handleNetsTransaction);
    //return () => {
    //socket.off("netsTransaction", handleNetsTransaction);
    //};
  }, [socket]);

  //Input text filter
  const updateAmountModal = (value, isTotal) => {
    if (
      (amountModal === 0 || amountModal === '0') &&
      (value === '0' || value === '00')
    ) {
      return;
    }

    if ((amountModal === '0' || amountModal === 0) && value !== 'backspace') {
      setAmountModal(value);

      return;
    }
    if ((amountModal === '0' || amountModal === 0) && value === 'backspace') {
      return;
    }
    if (
      value === 'backspace' &&
      amountModal.toString().length === 1 &&
      (amountModal !== '0' || amountModal !== 0)
    ) {
      setAmountModal('0');
      return;
    }
    if (
      value === 'backspace' &&
      amountModal.toString().length > 1 &&
      (amountModal !== '0' || amountModal !== 0)
    ) {
      setAmountModal(
        amountModal.toString().substring(0, amountModal.toString().length - 1)
      );

      return;
    }

    setAmountModal(amountModal + value);
  };

  const handleConfirm = () => {
    updateTransactions({
      name: 'Kontant uttak',
      amount: parseInt(amountModal),
      //amount: data.balance,
      type: 'transaction',
    });

    var dataNewTicket = {
      name: 'Kontant uttak',
      amount: parseInt(amountModal),
      type: 'transaction',
    };

    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: '',
      description: 'Kontant uttak',
      amount: parseInt(amountModal),

      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      ticketID: 0,
      ticketUsername: '',
      ticket7Pincode: '',
      user7Pincode: !parentInfo ? currentUser : parentInfo['7 digit PIN'],
      userInfo: !parentInfo ? userInfo : parentInfo,

      action: 'cashinExternal',
    };

    socket.emit('cashinExternal', data);

    updateShowCardToCash(false);
  };

  return (
    <div className="modal-overlay">
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          style: {
            zIndex: '9999999 !important',
            fontSize: '24px',
          },
        }}
        containerStyle={{
          zIndex: 99999999, // Ensure this value is higher than the modal's z-index
        }}
      />
      <div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <div
            className="custom-modal-2"
            style={{
              //background: isFail ? "red" : isSuccess ? "green" : "white",
              background: 'white',
              borderRadius: '40px',
              width: '1165px',
              height: '839px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                padding: '10px',
              }}
            >
              {/*Header*/}
              <Grid
                container
                sx={{
                  height: '100%',
                  width: '100%',
                  marginTop: 0,
                  marginLeft: 0,
                  justifyContent: 'center',
                  alignContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}
              >
                <>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: '10px',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Grid
                      container
                      flexDirection="row"
                      sx={{
                        height: '100%',
                        width: '100%',
                        marginTop: 0,
                        marginLeft: 0,
                        justifyContent: 'center',
                        alignContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{
                          padding: '10px',
                          height: '75px',
                          width: '100%',
                          marginTop: 0,
                          marginLeft: 0,
                          justifyContent: 'flex-start',
                          alignContent: 'center',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <h2
                          style={{
                            color: '#464646',
                          }}
                        >
                          {t('sales.cardToCash')}
                        </h2>
                      </Grid>

                      <Grid
                        container
                        flexDirection="row"
                        sx={{
                          height: 'calc(100% - 125px)',
                          width: '100%',
                          alignContent: 'space-around',
                        }}
                      >
                        {/*Input*/}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: '10px',
                            height: '100px',
                            width: '100%',
                            marginTop: 0,
                            marginLeft: 0,
                            justifyContent: 'center',
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: '100%',
                              width: '100%',
                              marginTop: 0,
                              marginLeft: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                padding: '10px',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '75px',
                                fontSize: '19px',
                                fontWeight: 'bold',
                                flexDirection: 'row',
                              }}
                            >
                              <Grid
                                container
                                flexDirection="row"
                                sx={{
                                  height: '100%',
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: '-35px',
                                }}
                              >
                                <Grid
                                  item
                                  xs={3}
                                  sx={{
                                    padding: '10px',
                                    alignContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '75px',
                                    fontSize: '19px',
                                    fontWeight: 'bold',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <AttachMoneyIcon
                                    sx={{
                                      fontSize: '35px',
                                      margin: '-5px 0 0 10px',
                                      color: '#464646',
                                    }}
                                  />{' '}
                                </Grid>
                                <Grid
                                  item
                                  xs={9}
                                  sx={{
                                    padding: '10px',
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    height: '75px',
                                    fontSize: '19px',
                                    fontWeight: 'bold',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '20px',
                                      fontWeight: 'bold',
                                      marginLeft: '-30px',
                                      textTransform: 'uppercase',
                                      color: '#464646',
                                    }}
                                  >
                                    {t('sales.amount')}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                marginTop: 0,
                                padding: '10px',
                                alignContent: 'center',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <TextField
                                value={amountModal == 0 ? '' : amountModal}
                                sx={{
                                  '& label': {
                                    position: 'relative !important',
                                    fontSize: '22px !important',
                                    color: '#464646 !important',
                                  },
                                  '& label.Mui-focused': {
                                    position: 'relative !important',
                                    color: '#464646 !important',
                                  },
                                  '& input': {
                                    color: '#464646 !important',
                                    border: '3px solid #464646',
                                    borderRadius: '4px',
                                    height: '50px !important',
                                    width: '275px !important',
                                    fontSize: '40px !important',
                                    textAlign: 'center',
                                  },
                                  '& input.Mui-focused': {
                                    color: '#464646 !important',
                                    border: '3px solid #464646',
                                    borderRadius: '4px',
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        {/*Shortcuts*/}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            padding: '10px',
                            height: '335px',
                            width: '100%',
                            marginLeft: 0,
                            justifyContent: 'center',
                            alignContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            marginTop: '61px',
                          }}
                        >
                          <h2
                            style={{
                              width: '100%',
                              textAlign: 'left',
                              color: '#464646',
                            }}
                          >
                            {t('sales.shortcuts')}
                          </h2>
                          <Grid
                            container
                            flexDirection="row"
                            sx={{
                              height: '100%',
                              width: '100%',
                              marginTop: 0,
                              marginLeft: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                border: '2px solid black',
                                width: '100%',
                                maxWidth: '100% !important',
                                height: '100%',
                                backgroundColor: '#f0f0f0',
                              }}
                            >
                              <Grid
                                container
                                flexDirection="row"
                                sx={{
                                  height: '100%',
                                  width: '100%',
                                  marginTop: 0,
                                  marginLeft: 0,
                                  display: 'flex',
                                  alignContent: 'space-around',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: '100px',
                                      width: '100px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        fontSize: '35px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#889cab',
                                        color: 'white',
                                        marginTop: '0px',
                                      }}
                                      onClick={() => {
                                        setAmountModal(10);
                                      }}
                                    >
                                      10
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: '100px',
                                      width: '100px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        fontSize: '35px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#889cab',
                                        color: 'white',
                                        marginTop: '0px',
                                      }}
                                      onClick={() => {
                                        setAmountModal(20);
                                      }}
                                    >
                                      20
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: '100px',
                                      width: '100px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        fontSize: '35px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#889cab',
                                        color: 'white',
                                        marginTop: '0px',
                                      }}
                                      onClick={() => {
                                        setAmountModal(50);
                                      }}
                                    >
                                      50
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: '100px',
                                      width: '100px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        fontSize: '35px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#889cab',
                                        color: 'white',
                                        marginTop: '0px',
                                      }}
                                      onClick={() => {
                                        setAmountModal(100);
                                      }}
                                    >
                                      100
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: '100px',
                                      width: '100px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        fontSize: '35px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#889cab',
                                        color: 'white',
                                        marginTop: '0px',
                                      }}
                                      onClick={() => {
                                        setAmountModal(200);
                                      }}
                                    >
                                      200
                                    </Button>
                                  </Grid>
                                </Grid>

                                <Grid
                                  item
                                  xs={4}
                                  sx={{
                                    display: 'flex',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    item
                                    sx={{
                                      height: '100px',
                                      width: '100px',
                                      display: 'flex',
                                      alignContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      component="label"
                                      sx={{
                                        height: '100%',
                                        width: '100%',
                                        fontSize: '35px',
                                        fontWeight: 'bold',
                                        backgroundColor: '#889cab',
                                        color: 'white',
                                        marginTop: '0px',
                                      }}
                                      onClick={() => {
                                        setAmountModal(500);
                                      }}
                                    >
                                      500
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/*END Shortcuts*/}

                        {/* Bank Axept */}
                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: '70px',
                              width: 'auto',
                              paddingTop: 0,
                            }}
                            alt=""
                            src={BankAxept}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      padding: '10px',
                    }}
                  >
                    <Calculator
                      updateAmount={updateAmountModal}
                      isColorCard={true}
                    />
                    <Grid
                      item
                      xs={12}
                      sx={{
                        width: '100%',
                        maxWidth: '100% !important',
                        height: '100%',
                      }}
                    >
                      <Grid
                        container
                        flexDirection="row"
                        sx={{
                          height: '125px',
                          width: '100%',
                          marginTop: 0,
                          marginLeft: 0,
                          padding: '10px',
                        }}
                      >
                        {/*Input*/}

                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              width: '200px',
                              height: '90px',
                              backgroundColor:
                                !userLogin &&
                                (amountModal === 0 || amountModal === '0')
                                  ? 'grey'
                                  : '#009f83',
                              opacity:
                                !userLogin &&
                                (amountModal === 0 || amountModal === '0')
                                  ? '0.25'
                                  : '1',
                              color: 'white',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                            }}
                            onClick={() => {
                              handleConfirm();

                              return;
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '8px', // Space between icon and text
                            }}
                          >
                            <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                            {t('sales.confirm')}
                          </Button>
                        </Grid>

                        <Grid
                          item
                          xs={6}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            variant="contained"
                            component="label"
                            sx={{
                              width: '200px',
                              height: '90px',
                              backgroundColor: '#fc86c1',
                              color: 'white',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                            }}
                            onClick={() => {
                              updateIsSingleTransaction(false);

                              updateShowCardToCash(false);
                              updateReceiptToPrint(false);
                            }}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              gap: '8px', // Space between icon and text
                            }}
                          >
                            <CancelIcon style={{ fontSize: '1.2em' }} />

                            {t('sales.cancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardPayment;
