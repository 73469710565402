import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  createRef,
} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import TextField from "@mui/material/TextField";

import Buttons from "./Buttons";
import OrcScreenSummary from "./OrcScreenSummary";

import BarcodeReader from "react-barcode-reader";
import Webcam from "react-webcam";
import axios from "axios";
import Button from "@mui/material/Button";

function OcrScreen({
  providerInfo,
  updateSellerOpen,
  updateOcrOpen,
  updateTransactions,
  vendorInfo,
  sellType,
}) {
  const [result, setResult] = useState("No result");

  const handleScan = (data) => {
    setResult(data);
    alert(data);
  };

  const handleError = (err) => {
    console.error(err);
  };

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [textOcr, setTextOcr] = useState(null);
  const [load, setLoad] = useState(false);
  let fileInputRef = createRef();

  const capture = useCallback(() => {
    setLoad(true);
    const imageSrc = webcamRef.current.getScreenshot();
    // console.log(imageSrc)
    let url = "http://localhost:5000/capture";
    let config = {
      headers: { "Content-Type": "application/json" }, // x-www-form-urlencoded
    };
    let dataBody = {
      img: imageSrc,
    };
    axios
      .post(url, dataBody, config)
      .then((res) => {
        console.log(res.data);
        setTextOcr(res.data.text);
        setImgSrc(imageSrc);
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [webcamRef, setImgSrc]);

  const upload = (file) => {
    setLoad(true);
    var url = "http://localhost:5000/upload";
    var formData = new FormData();
    formData.append("file", file);
    var config = {
      headers: { "Content-Type": "multipart/form-data" },
    };
    return axios.post(url, formData, config).then((res) => {
      console.log(res.data);
      setTextOcr(res.data.text);
      setImgSrc(res.data.image);
      setLoad(false);
    });
  };

  const [amount, setAmount] = useState("0");

  const updateAmount = (value) => {
    if (amount === "0" && value !== "backspace") {
      setAmount(value);
      return;
    }
    if (amount === "0" && value === "backspace") {
      return;
    }
    if (value === "backspace" && amount.length === 1 && amount !== 0) {
      setAmount("0");
      return;
    }
    if (value === "backspace" && amount.length > 1 && amount !== 0) {
      setAmount(amount.substring(0, amount.length - 1));
      return;
    }

    setAmount(amount + value);
  };

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "10px",
      }}
    >
      {/*Header*/}
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        {!load && !imgSrc && !textOcr && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
                width: "100%",
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/*Buttons*/}
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
                width: "100%",
                maxWidth: "100% !important",
              }}
            >
              <Grid
                container
                flexDirection="row"
                sx={{
                  height: "125px",
                  width: "100%",
                  marginTop: 0,
                  marginLeft: 0,
                  padding: "10px",
                }}
              >
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      width: "200px",
                      height: "90px",
                      backgroundColor: "#fc86c1",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      updateOcrOpen(false);
                    }}
                  >
                    Avbryt
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      width: "200px",
                      height: "90px",
                      backgroundColor: "#009f83",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      /*capture();*/
                      /*
                updateSellerOpen(false);
                updateTransactions({ name: "Transaksjon", amount: amount });
                */
                    }}
                  >
                    Ta et bilde
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      width: "200px",
                      height: "90px",
                      backgroundColor: "#009f83",
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    Laste opp
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {load && (
          <Grid
            item
            xs={12}
            sx={{
              padding: "10px",
              width: "100%",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <h2>Loading</h2>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!load && imgSrc && textOcr && (
          <OrcScreenSummary
            updateOcrOpen={updateOcrOpen}
            textOcr={textOcr}
            imgSrc={imgSrc}
            load={load}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default OcrScreen;
