import React, { useEffect, useState, useContext, useRef } from 'react';
import { TextField } from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import { NorwegianId } from 'norwegian-national-id-validator';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalHybridUserRegistration() {
  const { t } = useTranslation();

  const {
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsFullUser,
    updateIsHybridUserRegistration,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    showModalPictureVerificationPreRegister,
    updateShowModalPictureVerificationPreRegister,
    updateImageVerificationTemp,
    imageVerificationTemp,
    enviroment,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON, FOCUS ON CARDID
  const inputRef = useRef(null);

  useEffect(() => {
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1400);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const [showSMS, setShowSMS] = useState(false);
  const [smsHybridRegistration, setSmsHybridRegistration] = useState('');
  const smsHybridRegistrationRef = useRef(smsHybridRegistration);
  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);
  useEffect(() => {
    smsHybridRegistrationRef.current = smsHybridRegistration;
  }, [smsHybridRegistration]);

  const [isUserConfirmed, setIsUserConfirmed] = useState('');

  // #region HANDLERS
  //* INPUT HANDLERS
  const [ssnid, setSSNID] = useState('');
  const handleSSNIDChange = (event) => {
    setSSNID(event.target.value);
  };
  const ssnidRef = useRef(ssnid);
  useEffect(() => {
    ssnidRef.current = ssnid;
  }, [ssnid]);

  const [username, setUsername] = useState('');
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };
  const usernameRef = useRef(username);
  useEffect(() => {
    usernameRef.current = username;
  }, [username]);

  const [lastname, setLastname] = useState('');
  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };
  const lastnameRef = useRef(lastname);
  useEffect(() => {
    lastnameRef.current = lastname;
  }, [lastname]);

  const [phonenumber, setPhonenumber] = useState('');
  const handleChangePhonenumber = (event) => {
    let input = event.target.value.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6, 9);
    }

    setPhonenumber(input);
  };
  const phonenumberRef = useRef(phonenumber);
  useEffect(() => {
    phonenumberRef.current = phonenumber;
  }, [phonenumber]);

  const imageVerificationTempRef = useRef(imageVerificationTemp);
  useEffect(() => {
    imageVerificationTempRef.current = imageVerificationTemp;
  }, [imageVerificationTemp]);

  const operatorRef = useRef(operator);
  useEffect(() => {
    operatorRef.current = operator;
  }, [operator]);

  const sessionRef = useRef(session);
  useEffect(() => {
    sessionRef.current = session;
  }, [session]);

  async function isEightDigitNumber(value) {
    const digits = value.replace(/\s/g, '_');

    if (digits[0] !== '4' && digits[0] !== '9') {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  // #region SOCKETS
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    const handleSearchMemberPreFullRegister = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      if (data.phonenumber) {
        toast.error(t('error.errorPhonenumberExists'));
        return;
      }
      if (data.ssnid) {
        toast.error(t('error.errorSSNIDExists'));
        return;
      }

      //setIsUserConfirmed(true);
      //* SEND SMS; KEEP FOR THE FUTURE

      var dataToSend = {
        phonenumber: phonenumberRef.current,
        sessionID: session,
        action: 'sms-hybrid',
      };
      socket.emit('smsHybridRegistration', dataToSend);

      setShowSMS(true);
      socket.emit('activateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleNewHybridMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        setShowSMS(false);
        setIsUserConfirmed(false);
        return;
      }
      var data = {
        auth_value: data.username,
        isExact: true,
        searchByUsername: true,
        action: 'searchMember',
        session: session,
      };
      socket.emit('searchMember', data);
    };

    const handleSmsHybridRegistration = (data) => {
      setSmsHybridRegistration(data);

      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      setSmsHybridRegistration(data);
    };

    const handleSearchMember = (data) => {
      if (
        !data ||
        !data.records ||
        data.records.length === 0 ||
        data.records.length > 1
      ) {
        toast.error(t('error.error'));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]['7 digit PIN']);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === 'anon' ||
        (data.records[0].firstname === '' && data.records[0].mobile === '')
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            searchByID: true,
            action: 'searchMemberParent',
            session: session,
          };
          socket.emit('searchMemberParent', data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: dataToRegisterObj[0]['7 digit PIN'],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ''
            ? 'Uregistrert'
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);

      updateFocusOnMainScreen(true);
      updateIsHybridUserRegistration(false);
    };

    const handleSendPincodeTerminal = (data) => {
      if (smsHybridRegistrationRef.current === '') {
        return;
      }

      if (parseInt(data.pincode) !== smsHybridRegistrationRef.current) {
        toast.error(t('error.errorUserFailCode'));
        return;
      }
      setIsUserConfirmed(true);
      socket.emit('deactivateSMS', {
        bingoName: hallRef.current,
      });
    };

    socket.on('searchMemberPreFullRegister', handleSearchMemberPreFullRegister);
    socket.on('smsHybridRegistration', handleSmsHybridRegistration);
    socket.on('searchMember', handleSearchMember);
    socket.on('newHybridMember', handleNewHybridMember);
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);

    return () => {
      socket.off(
        'searchMemberPreFullRegister',
        handleSearchMemberPreFullRegister
      );
      socket.off('smsHybridRegistration', handleSmsHybridRegistration);
      socket.off('searchMember', handleSearchMember);
      socket.off('newHybridMember', handleNewHybridMember);
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
    };
  }, [socket]);

  // #region LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmRegister = async () => {
    const validation = NorwegianId(ssnidRef.current);
    if (
      !validation.isValid() &&
      enviroment === 'prod' &&
      ssnidRef.current !== ''
    ) {
      toast.error(t('error.errorSSNIDInvalid'));
      return;
    }
    if (
      validation.age() < 18 &&
      enviroment === 'prod' &&
      ssnidRef.current !== ''
    ) {
      toast.error(t('error.errorSSNIDUnderAge'));
      return;
    }

    const isPhonenumber = await isEightDigitNumber(phonenumber);

    //* IMAGE VERIFICATION
    /*
    if (!imageVerificationTempRef.current) {
      toast.error(t("error.errorImageRegister"));
      return;
    }
    */

    if (!isPhonenumber) {
      toast.error(t('error.errorPhonenumberInput'));
      return;
    }

    if (username.length < 2) {
      toast.error(t('error.errorUsernameInput'));
      return;
    }

    if (lastname.length < 2) {
      toast.error(t('error.errorLastnameEmpty'));
      return;
    }

    var dataToSend = {
      phonenumber: phonenumberRef.current,
      ssnid: ssnidRef.current,
      action: 'searchMemberPreFullRegister',
      session: sessionRef.current,
    };
    socket.emit('searchMemberPreFullRegister', dataToSend);
  };

  //* CONFIRM SMS REGISTRATION (and register the user)
  const handleConfirmAfterSMS = () => {
    registerAndEmitUser();
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    updateFocusOnMainScreen(true);
    updateImageVerificationTemp(false);
    updateIsHybridUserRegistration(false);
  };

  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    if (data.operator === '') {
      return;
    }
    socket.emit('registerLastUsers', data);
  };

  //* Emit the user for the register
  const registerAndEmitUser = () => {
    var data = {
      action: 'newHybridMember',
      username: usernameRef.current,
      lastname: lastnameRef.current,
      phonenumber: phonenumberRef.current,
      session: session,
      //* IMAGE VERIFICATION
      /*
      image: imageVerificationTempRef.current,
      */
      operator: operatorRef.current,
      bingoName: hallRef.current,
      ssnid: ssnidRef.current,
      type: 'newHybrid',
    };

    socket.emit('newHybridMember', data);
  };

  const handlePicture = () => {
    updateShowModalPictureVerificationPreRegister(true);
  };

  // #region RENDER
  return (
    <div id="react-confirm-alert">
      <div class="react-confirm-alert-overlay">
        <div class="react-confirm-alert">
          {isUserConfirmed ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.userConfirm')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    handleConfirmAfterSMS();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : (
            <>
              {!showSMS ? (
                <div
                  class="react-confirm-alert-body"
                  style={{ width: '1000px' }}
                >
                  <h1>{t('sales.registerNewHybridUser')}</h1>
                  <TextField
                    autoComplete="off"
                    label={'Fødselsnummer'}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => {
                      handleSSNIDChange(e);
                    }}
                    placeholder={ssnid}
                    className="modal-textfield"
                    value={ssnid}
                  />

                  <TextField
                    autoComplete="off"
                    label={t('sales.phonenumber') + '*'}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => {
                      handleChangePhonenumber(e);
                    }}
                    placeholder={phonenumber}
                    className="modal-textfield"
                    value={phonenumber}
                  />

                  <TextField
                    autoComplete="off"
                    label={t('sales.name') + '*'}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => {
                      handleUsernameChange(e);
                    }}
                    placeholder={username}
                    className="modal-textfield"
                    value={username}
                  />

                  <TextField
                    autoComplete="off"
                    label={t('sales.lastname') + '*'}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    onChange={(e) => {
                      handleLastnameChange(e);
                    }}
                    placeholder={lastname}
                    className="modal-textfield"
                    value={lastname}
                  />

                  <div className="react-confirm-alert-button-group">
                    <button
                      className="btn-custom-yes"
                      onClick={() => {
                        confirmRegister();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.registerNewUserConfirm')}
                    </button>

                    {/* IMAGE VERIFICATION */}
                    {/*
                    <button
                      className="btn-custom-thirdOption"
                      onClick={() => {
                        handlePicture();
                      }}
                    >
                      {t("sales.photoUploadTitle")}
                    </button>
                    */}

                    <button
                      className="btn-custom-no"
                      onClick={() => {
                        cancel();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CancelIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.cancel')}
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  class="react-confirm-alert-body"
                  style={{ width: '  1000px' }}
                >
                  <h1>{t('sales.smsTitle')}</h1>
                  <p>{t('sales.smsText1')}</p>
                  <p>{t('sales.smsText2')}</p>

                  <div className="react-confirm-alert-button-group">
                    <button
                      className="btn-custom-no"
                      onClick={() => {
                        cancel();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CancelIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.cancel')}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalHybridUserRegistration;
