import React from "react";
import { Grid, Button } from "@mui/material";
import {
  Backspace as BackspaceIcon,
  Check as CheckIcon,
} from "@mui/icons-material/";

function CalculatorItem({ value, updateAmount }) {
  return (
    <Grid
      item
      xs={3}
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        item
        sx={{
          height: "125px",
          width: "125px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {value !== null && value !== "confirm" && (
          <Button
            variant="contained"
            component="label"
            sx={{
              height: "100%",
              width: "100%",
              fontSize: "35px",
              fontWeight: "bold",
              backgroundColor: value === "backspace" ? "#fc86c1;" : "#889cab",
              color: "white",
              marginTop: "15px",
            }}
            onClick={() => {
              updateAmount(value);
            }}
          >
            {value === "backspace" ? <BackspaceIcon /> : value}
          </Button>
        )}

        {value === "confirm" && (
          <Button
            variant="contained"
            component="label"
            sx={{
              height: "100%",
              width: "100%",
              fontSize: "35px",
              fontWeight: "bold",
              backgroundColor: value === "confirm" ? "#009e82;" : "#889cab",
              color: "white",
              marginTop: "15px",
            }}
            onClick={() => {
              updateAmount(value);
            }}
          >
            {value === "confirm" ? <CheckIcon /> : value}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default CalculatorItem;
