import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/Context";

import { SocketContext } from "../../context/SocketContext";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

function ApneBilleter() {
  const [value, setValue] = useState(new Date());
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDateChange = (newDate) => {
    setValue(newDate);
    handleClose();
  };

  const formatDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isLogin, isBlocking, BlockingScreen, operator, hall, session } =
    useContext(AppContext);

  const { socket } = useContext(SocketContext);

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (isLogin) {
      socket.emit("getTickets", {
        operator: operator,
        bingoName: hall,
        sessionID: session,
      });
    }
  }, [isLogin]);

  const [rows, setRows] = useState(false);

  useEffect(() => {
    const handleGetTickets = (data) => {
      setRows(data);
    };

    socket.on("getTickets", handleGetTickets);

    return () => {
      socket.off("getTickets", handleGetTickets);
    };
  }, [socket]);

  function convertToMySQLDateTime(isoString) {
    const date = new Date(isoString);

    // Padding function to add leading zeros if necessary
    const pad = (number) => (number < 10 ? "0" + number : number);

    // Format the date using UTC methods
    const year = date.getUTCFullYear();
    const month = pad(date.getUTCMonth() + 1); // getUTCMonth() returns month from 0-11
    const day = pad(date.getUTCDate());
    const hours = pad(date.getUTCHours());
    const minutes = pad(date.getUTCMinutes());
    const seconds = pad(date.getUTCSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const navigateToTransactions = (ticket7Pincode) => {
    navigate("/billet-historikk", { state: { value: ticket7Pincode } });
  };

  return (
    <>
      {isBlocking && <BlockingScreen />}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/*BUTTONS*/}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                height: "200px",
                maxHeight: "200px",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "row",
                  padding: "25px",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "100%",
                    height: "200px",
                    padding: "25px",
                    margin: "0px 0 20px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    sx={{
                      width: "100%",
                      height: "75px",
                      margin: "5px 0 10px 0",
                      backgroundColor: "#232a45",

                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#365ab8",
                        },
                        "&:hover fieldset": {
                          borderColor: "#fcad89",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#fcad89",
                        },
                      },

                      "& input": {
                        color: "white !important",
                        fontSize: "30px",
                        borderColor: "black",
                      },
                      "& label": {
                        fontSize: "22px",
                        lineHeight: "2.438em",
                        color: "white !important",
                      },
                      "& label.Mui-focused": {
                        color: "white !important",
                      },
                      "& input.Mui-focused": {
                        color: "white !important",
                        borderColor: "black",
                      },
                    }}
                    aria-describedby={id}
                    label={t("apneBilleter.date")}
                    value={formatDate(value)}
                    onClick={handleClick}
                  >
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <Calendar onChange={handleDateChange} value={value} />
                    </Popover>
                  </TextField>
                </Grid>

                <Grid
                  item
                  xs={6}
                  sx={{
                    width: "100%",
                    height: "200px",
                    padding: "25px",
                    margin: "0px 0 20px",
                    borderRadius: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      width: "180px",
                      height: "80px",
                      fontSize: "21px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      backgroundColor: "#009e82",
                      display: "none",
                    }}
                  >
                    {t("menu.search")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {/*TABLE*/}
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                padding: "10px",
                height: "100%",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  maxHeight: "calc(100% - 200px)",
                  marginBottom: "200px",
                  overflowY: "scroll", // Enables vertical scrolling
                  scrollbarWidth: "none", // For Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // For Chrome, Safari, and Opera
                  },
                }}
              >
                <Table
                  sx={{
                    color: "white",
                    background: "#1f2c67",
                  }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead
                    sx={{
                      background: "#1f2c67",
                    }}
                  >
                    <TableRow>
                      <TableCell align="center">
                        {t("apneBilleter.date")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.operator")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.ticket7Pincode")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.ticketID")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.username")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.amount")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.description")}
                      </TableCell>
                      <TableCell align="center">
                        {t("apneBilleter.seeTransactions")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {rows && (
                    <TableBody>
                      {rows.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            "&:nth-of-type(odd)": {
                              backgroundColor: "#232a45",
                            },
                            "&:nth-of-type(even)": {
                              backgroundColor: "#1a2036",
                            },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            {convertToMySQLDateTime(row.date)}
                          </TableCell>

                          <TableCell align="center">{row.operator}</TableCell>
                          <TableCell align="center">
                            {row.ticket7Pincode}
                          </TableCell>
                          <TableCell align="center">{row.ticketID}</TableCell>
                          <TableCell align="center">
                            {row.userInfo.firstname
                              ? row.userInfo.firstname
                              : "-"}
                          </TableCell>

                          <TableCell align="center">{row.amount}</TableCell>
                          <TableCell align="center">
                            {row.description}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                navigateToTransactions(row.ticket7Pincode);
                              }}
                            >
                              {t("apneBilleter.seeTransactions")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ApneBilleter;
