import React from "react";
import { Grid, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

function Shortcuts({ updateAmountShortcut, isTab }) {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "10px",
        height: "555px",
        width: "100%",
        marginTop: 0,
        marginLeft: 0,
        justifyContent: "center",
        alignContent: "center",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {!isTab && (
        <h2 style={{ textAlign: "left", width: "100%" }}>
          {t("sales.shortcuts")}
        </h2>
      )}
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            border: "2px solid black",
            width: "100%",
            maxWidth: "100% !important",
            height: "100%",
            backgroundColor: "#232a45",
          }}
        >
          <Grid
            container
            flexDirection="row"
            sx={{
              height: "100%",
              width: "100%",
              marginTop: 0,
              marginLeft: 0,
              display: "flex",
              alignContent: "space-around",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(10);
                  }}
                >
                  10
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(20);
                  }}
                >
                  20
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(30);
                  }}
                >
                  30
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(40);
                  }}
                >
                  40
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(50);
                  }}
                >
                  50
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(60);
                  }}
                >
                  60
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(100);
                  }}
                >
                  100
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(150);
                  }}
                >
                  150
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(200);
                  }}
                >
                  200
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(300);
                  }}
                >
                  300
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(500);
                  }}
                >
                  500
                </Button>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  width: "100px",
                  display: "flex",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    height: "100%",
                    width: "100%",
                    fontSize: "35px",
                    fontWeight: "bold",
                    backgroundColor: "#889cab",
                    color: "white",
                    marginTop: "0px",
                  }}
                  onClick={() => {
                    updateAmountShortcut(1000);
                  }}
                >
                  1000
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Shortcuts;
