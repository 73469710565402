import React, { useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import Vendors from "./Vendors/Vendors";
import Transactions from "./Transactions/Transactions";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { AppContext } from "../../context/Context";
import ModalOpenShift from "../AvslutteSkiftet/ModalOpenShift";

function Salgs() {
  const { isShiftOpen, isBlocking, BlockingScreen } = useContext(AppContext);

  const navigate = useNavigate();
  const { isLogin } = useContext(AppContext);

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {isBlocking && <BlockingScreen />}

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          flex: 1,
          marginLeft: 0,
          maxWidth: "100%",
          height: "100%",
          borderLeft: "5px solid black",
        }}
      >
        <Toaster position="top-center" reverseOrder={false} />

        {!isShiftOpen && <ModalOpenShift />}

        <Grid
          item
          xs={9}
          sx={{
            height: "100%",
            padding: "5px",
          }}
        >
          {/*
            <BarcodeReader
              onError={handleError}
              onScan={handleScan}
              minLength={4} // minimum length of the barcode, adjust according to your needs
            />
            <p>{result}</p>
          */}
          <Vendors />
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            height: "100%",
            borderLeft: "5px solid black",
          }}
        >
          <Transactions />
        </Grid>
      </Grid>
    </>
  );
}

export default Salgs;
