import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import toast from 'react-hot-toast';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material/';

function ModalOpenSession() {
  const { t } = useTranslation();

  const {
    session,
    updateFocusOnMainScreen,
    userInfo,
    operator,
    hall,
    currentUser,
    currentUserID,
    updateIsCashoutPending,
    openSessionTerminal,
    updateOpenSessionTerminal,
    logoutUser,
    resetTicketsCH,
    resetTransactions,
    updateUserLogin,
    updateCurrentUser,
    updateCurrentUserID,
    updateUserInfo,
    updateIsFullUser,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,

    updateIsPaymentOpen,
    updateIsSellerOpen,
    updatePaymentType,

    updateTotal,
    updatePayCard,
    updatePayCash,

    updateChange,
    updateIsCard,
    updateIsCash,

    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    updateAdoptedTicket,
    updateShowUserInfoSibling,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON
  useEffect(() => {
    updateFocusOnMainScreen(false);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  const { socketPW } = useContext(SocketContext);

  useEffect(() => {}, [socket]);

  useEffect(() => {
    const handleSessionClosedOnTerminal = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
    };

    socket.on('sessionClosedOnTerminal', handleSessionClosedOnTerminal);

    return () => {
      socket.off('sessionClosedOnTerminal', handleSessionClosedOnTerminal);
    };
  }, [socketPW]);

  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmClose = async () => {
    updateOpenSessionTerminal(false);
    updateAdoptedTicket({});
    updateShowUserInfoSibling(false);
    updateFocusOnMainScreen(true);

    /*
    if (!openSessionTerminal.isPowerBingo) {
      updateOpenSessionTerminal(false);
      updateFocusOnMainScreen(true);

      resetTransactions();
      updateIsCard(false);
      updateIsCash(false);
      updateTotal(0);
      updatePayCard(0);
      updatePayCash(0);
      updateChange(0);
      updateCurrentUser("");
      updateCurrentUserID("");
      updateUserLogin(false);
      updatePaymentType("");
      updateIsPaymentOpen(false);
      updateIsSellerOpen(false);
      logoutUser();
      updateCurrentUser("");
      updateCurrentUserID("");
      updateUserLogin(false);
      updateUserInfo({});
      updateIsFastUserLogin(false);
      updateIsAnonUser(false);
      updateIsHybridUser(false);
      updateIsFullUser(false);
      updateUserParent(false);
      updateUserChildren([]);
      updateUserSiblings([]);
      resetTicketsCH();

      return;
    }

    var data = {
      transactions: [],
      operator: operator,
      bingoName: hall,
      sessionID: session,
      provider: "cashout - cash_from_wallet - terminal",
      description: "cashout - cash_from_wallet - terminal",
      //amount: parseInt(amount),
      amount: 200,
      user7Pincode: currentUser,
      userInfo: userInfo,
      isChild: true,
      usePrinter: false,
      useSMS: false,
      printBarcode: false,
      qrMessage: false,
      session: session,
      playerID: userInfo.id,
      cashoutType: "cash_from_wallet",
      ticketID: userInfo.id,
      ticketUsername: userInfo.username,
      ticket7Pincode: currentUser,

      action: "newSignature",
    };

    console.log("signature", data);
    socket.emit("newSignature", data);
    */
  };

  const cancel = () => {
    //updateIsCashoutPending(false);      updateOpenSessionTerminal(false);
    updateOpenSessionTerminal(false);
    updateFocusOnMainScreen(true);
    updateAdoptedTicket({});

    resetTransactions();
    updateIsCard(false);
    updateIsCash(false);
    updateTotal(0);
    updatePayCard(0);
    updatePayCash(0);
    updateChange(0);
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updatePaymentType('');
    updateIsPaymentOpen(false);
    updateIsSellerOpen(false);
    logoutUser();
    updateCurrentUser('');
    updateCurrentUserID('');
    updateUserLogin(false);
    updateUserInfo({});
    updateIsFastUserLogin(false);
    updateIsAnonUser(false);
    updateIsHybridUser(false);
    updateIsFullUser(false);
    updateUserParent(false);
    updateUserChildren([]);
    updateUserSiblings([]);
    resetTicketsCH();
  };

  const getTerminalNumber = (ip) => {
    const parts = ip.split('.');
    const lastPart = parts[parts.length - 1];
    return lastPart;
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: '1000px' }}>
            <h1>{t('sales.openSessionTitle')} </h1>
            <p>
              {t('sales.openSessionText1')}
              <span
                style={{
                  fontSize: '22px',
                  color: '#42a5f5',
                  fontWeight: 'bold',
                }}
              >
                {getTerminalNumber(openSessionTerminal.terminalIP)}
              </span>
              {t('sales.openSessionText2')}
              <span
                style={{
                  fontSize: '22px',
                  color: '#42a5f5',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                {openSessionTerminal.saldo}
              </span>
              {' NOK'}
            </p>
            {/*openSessionTerminal.isPowerBingo ? (
              <p>{t("sales.openSessionTextClose")}</p>
            ) : (
              <p>{t("sales.openSessionTextUnclosable")}</p>
            )*/}
            <div
              className="react-confirm-alert-button-group"
              style={{ marginTop: '55px' }}
            >
              {/*

              <button
                className="btn-custom-yes"
                onClick={() => {
                cancel();
                }}
              >
                {t("sales.confirm")}
              </button>
              */}
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px', // Space between icon and text
                }}
              >
                <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                {t('sales.confirm')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalOpenSession;
