import React, { useState, useContext, useEffect } from 'react';
import { Grid, Box, Button, TextField, Container } from '@mui/material';

import ClubHouseLogo from '../../assets/img/clubhouse_logo.png';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/Context';
import LoginIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';

import { SocketContext } from '../../context/SocketContext';
import { SocketNFCContext } from '../../context/SocketNFC';

import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import html2canvas from 'html2canvas';

function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isLogin,
    updateLogin,
    modifyOperator,
    isBlocking,
    BlockingScreen,
    updateSession,
    modifyOperatorFull,
    modifyOperatorInfo,
    modifyHall,
    updatePrinter,
    updateCardID,
    updateOKBingoID,
    updateIsOKBingoEnabled,
    enviroment,
    isNTEnabled,
    updateIsNTEnabled,
    isKioskEnabled,
    updateIsKioskEnabled,
  } = useContext(AppContext);

  var isLoading = false;
  const { socket } = useContext(SocketContext);
  const socketNFC = useContext(SocketNFCContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const loginTask = async () => {
    if (isLoading) {
      return;
    }

    if (!username || !password) {
      toast.error(t('error.empty'));
      return;
    }

    isLoading = true;

    let dataRequest = {
      username: username,
      password: password,
    };

    try {
      const hostname = window.location.hostname;
      var url = 'https://stage01-operator.vpfscandinavia.com';

      if (hostname === 'localhost') {
        //url = 'https://stage01-operator.clubhouse.no/api/login/';
        url = 'https://stage01-external-operator.clubhouse.no/api/login';
      } else if (hostname.includes('stage01')) {
        url = 'https://stage01-external-operator.clubhouse.no/api/login/';
      } else if (hostname.includes('stage02')) {
        url = 'https://stage02-operator.clubhouse.no/api/login/';
      } else {
        url = 'https://operator.clubhouse.no/api/login/';
      }

      var response = await axios({
        method: 'post',
        url: url,
        data: dataRequest,
      });

      takeFullScreenshot();

      if (
        response.data.hall.pinPadSerialNumber === null ||
        response.data.hall.pinPadSerialNumber === 'null'
      ) {
        updateCardID('');
      } else {
        updateCardID(response.data.hall.pinPadSerialNumber);
        //updateCardID("");
      }

      var dataPrint = {
        text: '',
        name: response.data.printer.name,
        id: response.data.printer.id,
        ip: response.data.printer.networkAddress,
      };
      updatePrinter(dataPrint);

      //const cookieString = response.headers["set-cookie"][0];
      //const sessValue = cookieString.split(";")[0].split("=")[1];

      const sessValue = response.data.operator.auth;
      response.data.session = sessValue;

      //Temp get countries
      //socket.emit("getCountries", response.data);

      if (!response) {
        isLoading = false;
        toast.error(t('error.login'));
        return;
      }

      updateSession(sessValue);
      modifyOperator(response.data.operator.username);
      modifyOperatorFull(response.data.operator.firstName);
      modifyHall(response.data.hall.name);
      modifyOperatorInfo(response.data);

      isLoading = false;

      var dataSocket = {
        operator: username,
        password: password,
        sessionID: sessValue,
        operatorInfo: response.data,
      };
      socket.emit('registerLogin', dataSocket);

      //navigate("/salgs");
    } catch (err) {
      console.error(err);
      isLoading = false;
      toast.error(t('error.login'));
      return;
    }

    /*
    var data = {
      username: username,
      password: password,
      action: "login",
    };
    socket.emit("login", data);
    */
  };

  useEffect(() => {
    const handleLogin = (data) => {
      if (!data) {
        isLoading = false;
        toast.error(t('error.login'));
        return;
      }

      var dataPrint = {
        text: '',
        name: data.printer.name,
        id: data.printer.id,
        ip: data.printer.networkAddress,
      };
      updatePrinter(dataPrint);

      if (!data) {
        isLoading = false;
        toast.error(t('error.login'));
        return;
      }
      modifyOperator(data.operator.username);
      modifyOperatorFull(data.operator.firstName);
      modifyHall(data.hall.name);
      updateLogin(true);
      updateSession(data.session);
      isLoading = false;

      socket.emit('registerLogin', data);
      //navigate("/salgs");
    };

    const handleGetCountries = (data) => {
      //console.log("countries");
      //console.log(data);
    };

    const handleRegisterLogin = (data) => {
      //console.log('login', data);

      if (
        data.operatorInfo.hall.pinPadSerialNumber &&
        data.operatorInfo.hall.pinPadSerialNumber !== ''
      ) {
        updateCardID(data.bingoName);
      }

      for (var i = 0; i < data.bingoList.length; i++) {
        if (
          data.bingoList[i].bingoName === data.operatorInfo.hall.name &&
          data.bingoList[i].isEnabled
        ) {
          updateOKBingoID(data.bingoList[i].bingoID);
          updateIsOKBingoEnabled(true);
          break;
        }
      }

      for (var i = 0; i < data.isNTEnabled.length; i++) {
        if (
          data.isNTEnabled[i].bingoName === data.operatorInfo.hall.name &&
          data.isNTEnabled[i].isEnabled
        ) {
          updateIsNTEnabled(true);
          break;
        }
      }

      for (var i = 0; i < data.isKioskEnabled.length; i++) {
        if (
          data.isKioskEnabled[i].bingoName === data.operatorInfo.hall.name &&
          data.isKioskEnabled[i].isEnabled
        ) {
          updateIsKioskEnabled(true);
          break;
        }
      }

      updateLogin(true);
      navigate('/salgs');
    };

    socket.on('registerLogin', handleRegisterLogin);
    socket.on('login', handleLogin);
    socket.on('getCountries', handleGetCountries);

    return () => {
      socket.on('registerLogin', handleRegisterLogin);
      socket.off('login', handleLogin);
      socket.off('getCountries', handleGetCountries);
    };
  }, [socket]);

  useEffect(() => {
    return () => {};
  }, [socketNFC]);

  useEffect(() => {
    if (isLogin) {
      navigate('/salgs');
      return;
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleFormSubmit();
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const handleFormSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
  };

  const takeFullScreenshot = () => {
    const formattedTimestamp = getFormattedTimestamp();
    let date = formattedTimestamp[0].trim();
    let time = formattedTimestamp[1].trim();

    var name = `Date-${date}_Time-${time}_Movement-${'login'}`;

    let url =
      'https://terminal.clubhouse.no:4433/Screenshots/Take?gameType=Cashier&fileName=' +
      name +
      '';

    fetch(url)
      .then((response) => {
        // Maneja la respuesta si es necesario
        if (response.ok) {
          // Procesa la respuesta aquí
        } else {
          console.error('Error en la solicitud HTTP:', response.status);
        }
      })
      .catch((error) => {
        console.error('Error en la solicitud:', error);
      });
  };

  function getFormattedTimestamp() {
    const now = new Date();

    // Obtiene la hora, los minutos y los segundos en formato HH:MM:SS
    const time = `${String(now.getHours()).padStart(2, '0')}-${String(
      now.getMinutes()
    ).padStart(2, '0')}-${String(now.getSeconds()).padStart(2, '0')}`;

    // Obtiene el año, el mes y el día en formato YYYY-MM-DD
    const date = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(now.getDate()).padStart(2, '0')}`;

    // Combina la hora y la fecha en un solo timestamp
    const timestamp = `${time} `;
    const datestamp = `${date} `;
    const data = [datestamp, timestamp];

    return data;
  }

  return (
    <>
      {isBlocking && <BlockingScreen />}

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          width: '100vw',
        }}
      >
        <Toaster position="top-center" reverseOrder={false} />

        <Box
          component="img"
          sx={{
            height: '300px',
            width: 'auto',
            paddingTop: 0,
          }}
          alt=""
          src={ClubHouseLogo}
        />

        <form
          noValidate
          autoComplete="off"
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '400px',
            width: '400px',
            minWidth: '400px',
            justifyContent: 'center',
          }}
          onSubmit={handleFormSubmit}
        >
          <Grid container direction={'column'} spacing={5}>
            <Grid item style={{ width: '100%' }}>
              <TextField
                autoFocus
                sx={{
                  width: '100%',
                }}
                label={t('login.username')}
                variant="outlined"
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                sx={{
                  width: '100%',
                }}
                label={t('login.password')}
                type="password"
                autoComplete="current-password"
                variant="outlined"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                color={isLoading ? 'disabled' : 'primary'}
                sx={{
                  height: '60px !important',
                  width: '100%',
                  backgroundColor: '#2f49cf',
                  color: 'white',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                }}
                startIcon={<LoginIcon />}
                onClick={() => {
                  loginTask();
                }}
              >
                {t('login.login')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}

export default Login;
