import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { AppContext } from '../../../context/Context';
import { useTranslation } from 'react-i18next';
import '../../../assets/css/buttons.css';
import { SocketContext } from '../../../context/SocketContext';
import { SocketNFCContext } from '../../../context/SocketNFC';
import toast from 'react-hot-toast';
import Select from 'react-select';

import { countriesDataEN } from '../../../utils/countriesDataEN';
import { countriesDataNO } from '../../../utils/countriesDataNO';

import { NorwegianId } from 'norwegian-national-id-validator';
import {
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  QrCodeScanner as ScanIcon,
} from '@mui/icons-material/';
import InputAdornment from '@mui/material/InputAdornment';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function ModalFullUserRegistration() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    if (currentLanguage === 'no') {
      handleCountryChange({
        value: 'Norway',
        label: 'Norge',
      });
    } else {
      handleCountryChange({
        value: 'Norway',
        label: 'Norway',
      });
    }

    const handleLanguageChange = (lang) => {
      setCurrentLanguage(lang);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  const {
    updateShowWaitingForScan,
    updateIsLoadingScreen,
    enviroment,
    updateImageVerificationTemp,
    imageVerificationTemp,
    operatorInfo,
    session,
    updateCurrentUser,
    updateUserLogin,
    updateIsFullUser,
    updateIsFullUserRegistration,
    updateFocusOnMainScreen,
    operator,
    hall,
    updateUserInfo,
    updateIsAnonUser,
    updateIsHybridUser,
    updateIsFastUserLogin,
    updateUserChildren,
    updateUserParent,
    updateUserSiblings,
    updateShowModalPictureVerificationPreRegister,
  } = useContext(AppContext);

  //* DISABLE FOCUS ON MAIN SCREEN AND PREVENT ESCAPE BUTTON, FOCUS ON CARDID
  const inputRef = useRef(null);

  useEffect(() => {
    updateIsFullUserRegistration(true);
    updateFocusOnMainScreen(false);

    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 1400);

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const imageVerificationTempRef = useRef(imageVerificationTemp);
  useEffect(() => {
    imageVerificationTempRef.current = imageVerificationTemp;
  }, [imageVerificationTemp]);

  const hallRef = useRef(hall);
  useEffect(() => {
    hallRef.current = hall;
  }, [hall]);

  const [showSMS, setShowSMS] = useState(false);
  const [smsMemberRegistration, setSmsMemberRegistration] = useState('');
  const smsMemberRegistrationRef = useRef(smsMemberRegistration);
  useEffect(() => {
    smsMemberRegistrationRef.current = smsMemberRegistration;
  }, [smsMemberRegistration]);

  const [isUserConfirmed, setIsUserConfirmed] = useState('');

  // #region Handles
  //* NEW MEMBER HANDLES
  const [cardID, setCardID] = useState('');
  const handleCardIDChange = (event) => {
    let inputValue = event.target.value;
    // Ensure input is not longer than 8 characters
    if (inputValue.length > 12) return;

    // Check if the input is empty or starts with "m" or "M"
    if (inputValue === '' || inputValue.toLowerCase().startsWith('m')) {
      // Convert first character to uppercase "M" and ensure the rest are digits
      let newValue =
        inputValue.charAt(0).toUpperCase() +
        inputValue.slice(1).replace(/[^\d]/g, '');
      setCardID(newValue);
    }
  };
  const cardIDRef = useRef(cardID);
  useEffect(() => {
    cardIDRef.current = cardID;
  }, [cardID]);

  const [ssnid, setSSNID] = useState('');
  const handleSSNIDChange = (event) => {
    setSSNID(event.target.value);
  };
  const ssnidRef = useRef(ssnid);
  useEffect(() => {
    ssnidRef.current = ssnid;
  }, [ssnid]);

  const [username, setUsername] = useState('');
  const handleUsernameChange = (event) => {
    const newValue = event.target.value.replace(/\s/g, '');
    setUsername(newValue);
  };
  const usernameRef = useRef(username);
  useEffect(() => {
    usernameRef.current = username;
  }, [username]);

  const [firstname, setFirstName] = useState('');
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const firstnameRef = useRef(firstname);
  useEffect(() => {
    firstnameRef.current = firstname;
  }, [firstname]);

  const [lastname, setLastName] = useState('');
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const lastnameRef = useRef(lastname);
  useEffect(() => {
    lastnameRef.current = lastname;
  }, [lastname]);

  const [address1, setAddress1] = useState('');
  const handleAddress1Change = (event) => {
    setAddress1(event.target.value);
  };
  const address1Ref = useRef(address1);
  useEffect(() => {
    address1Ref.current = address1;
  }, [address1]);

  const [address2, setAddress2] = useState('');
  const handleAddress2Change = (event) => {
    setAddress2(event.target.value);
  };
  const address2Ref = useRef(address2);
  useEffect(() => {
    address2Ref.current = address2;
  }, [address2]);

  const [verificationMethod, setVerificationMethod] = useState(null);
  const [verificationMethodSelected, setVerificationMethodSelected] =
    useState('');

  const handleVerificationMethodChange = (selectedOption) => {
    setVerificationMethod(selectedOption.value);
    setVerificationMethodSelected(selectedOption);
  };
  const verificationMethodRef = useRef(verificationMethod);
  useEffect(() => {
    verificationMethodRef.current = verificationMethod;
  }, [verificationMethod]);
  const options = [
    { value: 'Pass', label: t('sales.passport') },
    { value: 'Førerkort', label: t('sales.driverLicense') },
    { value: 'National-ID', label: t('sales.nationalID') },
    //{ value: "Electronic (BankID)", label: t("sales.electronicID") },
    //{ value: 'Bankkort', label: t('sales.bankCard') },
    //{ value: 'Annet', label: t('sales.other') },
  ];

  const [country, setCountry] = useState('');
  const [countrySelected, setCountrySelected] = useState('');
  const [phoneCode, setPhoneCode] = useState('+47');
  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.value);
    setCountrySelected(selectedOption);
    setPhoneCode(selectedOption.phoneCode);
  };
  const countryRef = useRef(country);
  useEffect(() => {
    countryRef.current = country;
  }, [country]);
  const phoneCodeRef = useRef(phoneCode);
  useEffect(() => {
    phoneCodeRef.current = phoneCode;
  }, [phoneCode]);

  const [postalCode, setPostalCode] = useState('');
  const handlePostalCodeChange = (event) => {
    const onlyNums = event.target.value.replace(/\D/g, '');
    setPostalCode(onlyNums);
  };
  const postalCodeRef = useRef(postalCode);
  useEffect(() => {
    postalCodeRef.current = postalCode;
  }, [postalCode]);

  const [region, setRegion] = useState('');
  const handleRegionChange = (event) => {
    setRegion(event.target.value);
  };
  const regionRef = useRef(region);
  useEffect(() => {
    regionRef.current = region;
  }, [region]);

  const [phonenumber, setPhonenumber] = useState('');
  const phonenumberRef = useRef(phonenumber);
  useEffect(() => {
    phonenumberRef.current = phonenumber;
  }, [phonenumber]);
  const handlePhonenumberChange = (event) => {
    // Remove all non-digit characters
    let input = event.target.value.replace(/\D/g, '');

    // Format the input value
    if (input.length > 3) {
      input = input.substring(0, 3) + ' ' + input.substring(3);
    }
    if (input.length > 6) {
      input = input.substring(0, 6) + ' ' + input.substring(6, 9);
    }

    setPhonenumber(input);
  };

  async function isEightDigitNumber(value) {
    const digits = value.replace(/\s/g, '_');

    if (digits[0] !== '4' && digits[0] !== '9') {
      return false;
    }
    const pattern = /^\d{3} \d{2} \d{3}$/;
    return pattern.test(value);
  }

  const [birthdate, setBirthdate] = useState('');
  const handleBirthdateChange = (event) => {
    let inputValue = event.target.value;

    // Remove all non-digit characters
    const digitsOnly = inputValue.replace(/\D/g, '');

    // Breaking down the string and inserting dots
    let formattedInput = digitsOnly;
    if (digitsOnly.length > 2) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2)}`;
    }
    if (digitsOnly.length > 4) {
      formattedInput = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(
        2,
        4
      )}.${digitsOnly.slice(4)}`;
    }

    // Limiting the length to 10 characters to match dd.mm.yyyy
    formattedInput = formattedInput.slice(0, 10);

    setBirthdate(formattedInput);
  };
  const birthdateRef = useRef(birthdate);
  useEffect(() => {
    birthdateRef.current = birthdate;
  }, [birthdate]);

  const [email, setEmail] = useState('');
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const emailRef = useRef(email);
  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  const [verificationID, setVerificationID] = useState(Date.now().toString());
  const handleVerificationIDChange = (event) => {
    setVerificationID(event.target.value);
  };
  const verificationIDRef = useRef(verificationID);
  useEffect(() => {
    verificationIDRef.current = verificationID;
  }, [verificationID]);

  const [terms, setTerms] = useState(true);
  const handleTermsChange = (event) => {
    setTerms(event.target.checked);
  };
  const termsRef = useRef(terms);
  useEffect(() => {
    termsRef.current = terms;
  }, [terms]);

  const [internetAccess, setInternetAccess] = useState(
    operatorInfo.hall.operatorsManageInternetAccess === 1 ? false : true
  );

  const handleInternetAccessChange = (event) => {
    setInternetAccess(event.target.checked);
  };
  const internetAccessRef = useRef(internetAccess);
  useEffect(() => {
    internetAccessRef.current = internetAccess;
  }, [internetAccess]);

  const [isPEP, setIsPEP] = useState(false);
  const handleIsPEPChange = (event) => {
    setIsPEP(event.target.checked);
  };
  const isPEPRef = useRef(isPEP);
  useEffect(() => {
    isPEPRef.current = isPEP;
  }, [isPEP]);

  //* Style for the select box
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '247.74px',
      height: '56px',
      minHeight: '58px',
      border: state.isFocused ? 'noone' : '1px solid #464646',
      boxShadow: 'none',
      '&:hover': { borderColor: '#ced4da' },
      borderColor: state.isFocused ? '#90caf9' : provided.borderColor,
      outline: state.isFocused ? '1px solid #90caf9' : 'none',
      outlineOffset: '0px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown layers above other content
    }),
  };

  // #region Sockets
  //* SOCKET SETUP
  const { socket } = useContext(SocketContext);
  const socketNFC = useContext(SocketNFCContext);

  useEffect(() => {
    const handleSearchMemberPreFullRegister = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      if (data.phonenumber) {
        toast.error(t('error.errorPhonenumberExists'));
        return;
      }
      if (data.username) {
        toast.error(t('error.errorUsernameExists'));
        return;
      }
      if (data.cardID) {
        toast.error(t('error.errorCardIDExists'));
        return;
      }

      var dataToSend = {
        phonenumber: phonenumberRef.current,
        username: usernameRef.current,
        sessionID: session,
        action: 'sms-hybrid',
      };
      socket.emit('smsFullRegistration', dataToSend);

      setShowSMS(true);
      socket.emit('activateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleNewMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        setShowSMS(false);
        setIsUserConfirmed(false);
        socket.emit('deactivateSMS', {
          bingoName: hallRef.current,
        });
        return;
      }
      var data = {
        auth_value: data.username,
        searchByUsername: true,
        isExact: true,
        action: 'searchMember',
        session: session,
      };
      socket.emit('searchMember', data);
    };

    const handleNewFullMember = (data) => {
      if (!data) {
        toast.error(t('error.error'));
        return;
      }
      var data = {
        auth_value: data.username,
        searchByUsername: true,
        isExact: true,
        action: 'searchMember',
        session: session,
      };
      socket.emit('searchMember', data);
    };

    const handleSearchMember = (data) => {
      if (
        !data ||
        !data.records ||
        data.records.length === 0 ||
        data.records.length > 1
      ) {
        toast.error(t('error.error'));
        return;
      }

      updateUserLogin(true);
      updateUserInfo(data.records[0]);
      updateCurrentUser(data.records[0]['7 digit PIN']);

      if (data.siblings) {
        updateUserSiblings(data.siblings);
      }

      if (data.children) {
        updateUserChildren(data.children);
      }

      var dataToRegisterObj = [];
      dataToRegisterObj.push(data.records[0]);
      if (
        data.records[0].username.slice(0, 4) === 'anon' ||
        (data.records[0].firstname === '' && data.records[0].mobile === '')
      ) {
        //ANON
        updateIsFullUser(false);
        updateIsHybridUser(false);
        updateIsAnonUser(true);
        if (data.parent) {
          updateUserParent(data.parent);
          var data = {
            auth_value: data.parent.parentID,
            searchByID: true,
            action: 'searchMemberParent',
            session: session,
          };
          socket.emit('searchMemberParent', data);
        }
        if (data.siblings) {
          updateUserSiblings(data.siblings);
        }
      } else if (data.records[0].username.slice(0, 6) === 'hybrid') {
        //Hybrid, don't do shit
        updateIsFullUser(false);
        updateIsHybridUser(true);
        updateIsAnonUser(false);
      } else {
        //Parent, save the children if exist
        if (data.children) {
          updateUserChildren(data.children);
        }
        updateIsFullUser(true);
        updateIsHybridUser(false);
        updateIsAnonUser(false);
      }

      updateIsFastUserLogin(false);

      var dataToRegister = {
        action: 'registerLastUsers',
        operator: operator,
        bingoName: hall,
        sessionID: session,
        user7Pincode: dataToRegisterObj[0]['7 digit PIN'],
        username: dataToRegisterObj[0].username,
        firstname:
          !dataToRegisterObj[0].firstname ||
          dataToRegisterObj[0].firstname === ''
            ? 'Uregistrert'
            : dataToRegisterObj[0].firstname,
        userInfo: dataToRegisterObj[0],
        isFastUserLogin: 0,
      };
      registerLastUsers(dataToRegister);

      updateFocusOnMainScreen(true);
      updateIsFullUserRegistration(false);
    };

    const handleSendPincodeTerminal = (data) => {
      if (smsMemberRegistrationRef.current === '') {
        return;
      }

      if (parseInt(data.pincode) !== smsMemberRegistrationRef.current) {
        toast.error(t('error.errorUserFailCode'));
        return;
      }
      setIsUserConfirmed(true);
      socket.emit('deactivateSMS', {
        bingoName: hallRef.current,
      });
    };

    const handleSmsFullRegistration = (data) => {
      setSmsMemberRegistration(data);

      if (!data) {
        toast.error(t('error.error'));
        return;
      }

      setSmsMemberRegistration(data);
    };

    socket.on('searchMemberPreFullRegister', handleSearchMemberPreFullRegister);
    socket.on('newMember', handleNewMember);
    socket.on('newFullMember', handleNewFullMember);
    socket.on('sendPincodeTerminal', handleSendPincodeTerminal);
    socket.on('smsFullRegistration', handleSmsFullRegistration);
    socket.on('searchMember', handleSearchMember);

    return () => {
      socket.off(
        'searchMemberPreFullRegister',
        handleSearchMemberPreFullRegister
      );
      socket.off('newMember', handleNewMember);
      socket.off('newFullMember', handleNewFullMember);
      socket.off('sendPincodeTerminal', handleSendPincodeTerminal);
      socket.off('smsFullRegistration', handleSmsFullRegistration);
      socket.off('searchMember', handleSearchMember);
    };
  }, [socket]);

  useEffect(() => {
    /*
    const handleCardDetected = (data) => {
      setCardID(data);
    };

    socketNFC.on('cardDetected', handleCardDetected);

    return () => {
      socketNFC.off('cardDetected', handleCardDetected);
    };
    */

    const handleScanDetected = async (data) => {
      //console.log('scanDetected', data);

      const str = data;
      const regex = /[Mm]\d{7}/;
      const match = str.match(regex);

      //console.log(match ? match[0] : 'No match found');
      updateIsLoadingScreen(false);
      updateShowWaitingForScan(false);

      setCardID(match[0]);
    };

    socketNFC.on('scanDetected', handleScanDetected);

    return () => {
      socketNFC.off('scanDetected', handleScanDetected);
    };
  }, [socketNFC]);

  // #region Buttons and logic
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmRegister = async () => {
    //* IMAGE VERIFICATION
    /*
    if (!imageVerificationTempRef.current) {
      toast.error(t("error.errorImageRegister"));
      return;
    }
    */

    const validation = NorwegianId(ssnidRef.current);

    if (
      !validation.isValid() &&
      enviroment === 'prod' &&
      ssnidRef.current !== ''
    ) {
      toast.error(t('error.errorSSNIDInvalid'));
      return;
    }
    if (
      validation.age() < 18 &&
      enviroment === 'prod' &&
      ssnidRef.current !== ''
    ) {
      toast.error(t('error.errorSSNIDUnderAge'));
      return;
    }

    const isPhonenumber = await isEightDigitNumber(phonenumber);
    if (!isPhonenumber) {
      toast.error(t('error.errorPhonenumberInput'));
      return;
    }
    if (!cardIDRef.current) {
      toast.error(t('error.errorCardIDEmpty'));
      return;
    }
    if (cardIDRef.current.length < 6) {
      toast.error(t('error.errorCardIDFormat'));
      return;
    }
    if (!usernameRef.current) {
      toast.error(t('error.errorUsernameEmpty'));
      return;
    }
    if (usernameRef.current.length < 2) {
      toast.error(t('error.errorUsernameTooShort'));
      return;
    }
    if (usernameRef.current.length > 12) {
      toast.error(t('error.errorUsernameTooLong'));
      return;
    }
    if (!firstnameRef.current) {
      toast.error(t('error.errorFirstNameEmpty'));
      return;
    }
    if (!lastnameRef.current) {
      toast.error(t('error.errorLastNameEmpty'));
      return;
    }
    if (!address1Ref.current) {
      toast.error(t('error.errorAddress1Empty'));
      return;
    }
    if (!postalCodeRef.current) {
      toast.error(t('error.errorPostalCodeEmpty'));
      return;
    }
    if (!regionRef.current) {
      toast.error(t('error.errorRegionEmpty'));
      return;
    }
    if (!birthdateRef.current) {
      toast.error(t('error.errorBirthdateEmpty'));
      return;
    }
    if (birthdateRef.current.length !== 10) {
      toast.error(t('error.errorBirthdateFormat'));
      return;
    }
    if (!verificationIDRef.current) {
      toast.error(t('error.errorVerificationIDEmpty'));
      return;
    }
    if (!termsRef.current) {
      toast.error(t('error.errorTermsEmpty'));
      return;
    }
    if (!verificationMethodRef.current) {
      toast.error(t('error.errorVerificationMethodEmpty'));
      return;
    }

    //* Search if phonenumber or user already existed
    var dataToSend = {
      phonenumber: phonenumberRef.current,
      username: usernameRef.current,
      cardID: cardIDRef.current,
      action: 'searchMemberPreFullRegister',
      session: session,
    };
    socket.emit('searchMemberPreFullRegister', dataToSend);
  };

  //* CONFIRM SMS REGISTRATION (and register the user)
  const confirmSMS = () => {
    registerAndEmitUser();
  };

  //* CANCEL BUTTON (close the modal and go back to main screen)
  const cancel = () => {
    socket.emit('deactivateSMS', {
      bingoName: hallRef.current,
    });
    updateIsFullUserRegistration(false);
    updateImageVerificationTemp(false);
    updateFocusOnMainScreen(true);
  };

  //* UPDATE USER LIST AFTER SUCCESSFUL REGISTRATION
  const registerLastUsers = (data) => {
    if (data.operator === '') {
      return;
    }
    socket.emit('registerLastUsers', data);
  };

  //* Emit the user for the register
  const registerAndEmitUser = () => {
    var data = {
      action: 'newMember',
      ssnid: ssnidRef.current,
      cardID: cardIDRef.current,
      username: usernameRef.current,
      firstname: firstnameRef.current,
      lastname: lastnameRef.current,
      address1: address1Ref.current,
      address2: address2Ref.current,
      verificationMethod: verificationMethodRef.current
        ? verificationMethodRef.current
        : false,
      country: countryRef.current,
      postalCode: postalCodeRef.current,
      region: regionRef.current,
      phonenumber: phonenumberRef.current,
      email: emailRef.current,
      birthdate: birthdateRef.current,
      verificationID: verificationIDRef.current,
      terms: termsRef.current,
      internetAccess: internetAccessRef.current,
      session: session,
      operator: operator,
      isPEP: isPEPRef.current,
      type: 'newMember',
      /* IMAGE VERIFICATION */
      /*image: imageVerificationTempRef.current,
       */
      bingoName: hallRef.current,
    };
    socket.emit('newMember', data);
  };
  const handlePicture = () => {
    updateShowModalPictureVerificationPreRegister(true);
  };

  const handleScanCard = (cardID) => {
    updateShowWaitingForScan(true);
  };

  // #region Render
  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          {isUserConfirmed ? (
            <div
              className="react-confirm-alert-body"
              style={{ width: '1000px' }}
            >
              <h1>{t('sales.userConfirm')}</h1>

              <div className="react-confirm-alert-button-group">
                <button
                  className="btn-custom-yes"
                  onClick={() => {
                    confirmSMS();
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px', // Space between icon and text
                  }}
                >
                  <CheckCircleIcon style={{ fontSize: '1.2em' }} />

                  {t('sales.confirm')}
                </button>
              </div>
            </div>
          ) : (
            <>
              {!showSMS ? (
                <div
                  className="react-confirm-alert-body"
                  style={{ width: '  1000px' }}
                >
                  <h1>{t('header.newMember')}</h1>

                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      height: '100%',
                      width: '100%',
                      padding: '5px',
                    }}
                  >
                    <Grid
                      xs={6}
                      item
                      sx={{
                        height: '100%',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <TextField
                        autoComplete="off"
                        label={`${t('sales.cardID')}* (Mxxxxxxx)`}
                        value={cardID}
                        variant="outlined"
                        onChange={handleCardIDChange}
                        className="modal-textfield"
                        inputRef={inputRef}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                borderRight: '1px solid #ccc', // Add right border
                                paddingRight: '8px', // Add some space after the icon
                              }}
                              onClick={() => {
                                handleScanCard();
                              }}
                            >
                              <ScanIcon style={{ color: 'black' }} />{' '}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        autoComplete="off"
                        label={'Fødselsnummer'}
                        value={ssnid}
                        variant="outlined"
                        onChange={handleSSNIDChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.username') + '*'}
                        value={username}
                        variant="outlined"
                        onChange={handleUsernameChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.firstname') + '*'}
                        value={firstname}
                        variant="outlined"
                        onChange={handleFirstNameChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.lastname') + '*'}
                        value={lastname}
                        variant="outlined"
                        onChange={handleLastNameChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.address1') + '*'}
                        value={address1}
                        variant="outlined"
                        onChange={handleAddress1Change}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.address2')}
                        value={address2}
                        variant="outlined"
                        onChange={handleAddress2Change}
                        className="modal-textfield"
                      />
                    </Grid>

                    <Grid
                      xs={6}
                      item
                      sx={{
                        height: '100%',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <Select
                        className="modal-textfield-select"
                        styles={customStyles}
                        value={countrySelected}
                        onChange={handleCountryChange}
                        options={
                          currentLanguage === 'no'
                            ? countriesDataNO
                            : countriesDataEN
                        }
                        isClearable={false}
                        placeholder={t('sales.country') + '*'}
                      />
                      <TextField
                        autoComplete="off"
                        label={t('sales.postalCode') + '*'}
                        value={postalCode}
                        variant="outlined"
                        onChange={handlePostalCodeChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.region') + '*'}
                        value={region}
                        variant="outlined"
                        onChange={handleRegionChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.phonenumber') + '* (xxx xx xxx)'}
                        value={phonenumber}
                        variant="outlined"
                        onChange={handlePhonenumberChange}
                        className="modal-textfield"
                      />
                      <TextField
                        autoComplete="off"
                        label={t('sales.birthdate') + '* (dd.mm.yyyy)'}
                        value={birthdate}
                        variant="outlined"
                        onChange={handleBirthdateChange}
                        className="modal-textfield"
                      />{' '}
                      <TextField
                        autoComplete="off"
                        label={t('sales.email')}
                        value={email}
                        variant="outlined"
                        onChange={handleEmailChange}
                        className="modal-textfield"
                      />
                      <TextField
                        autoComplete="off"
                        label={t('sales.verificationID') + '*'}
                        value={verificationID}
                        variant="outlined"
                        onChange={handleVerificationIDChange}
                        className="modal-textfield"
                        sx={{ display: 'none !important' }}
                      />
                      <Select
                        className="modal-textfield-select"
                        styles={customStyles}
                        value={verificationMethodSelected}
                        onChange={handleVerificationMethodChange}
                        options={options}
                        isClearable={false}
                        placeholder={t('sales.verificationMethod') + '*'}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: '100%',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isPEP}
                            onChange={handleIsPEPChange}
                            name="checked"
                            color="primary"
                            sx={{
                              color: 'black',
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="h6"
                            style={{
                              color: 'black',
                              marginLeft: '10px',
                              marginTop: '5px',
                            }}
                          >
                            P.E.P.
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: '100%',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={terms}
                            onClick={handleTermsChange}
                            name="checked"
                            color="primary"
                            sx={{
                              color: 'black',
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                            }}
                          />
                        }
                        label={
                          <Typography
                            variant="h6"
                            style={{
                              color: 'black',
                              marginLeft: '10px',
                              marginTop: '5px',
                            }}
                          >
                            {t('sales.terms')}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid
                      xs={12}
                      item
                      sx={{
                        height: '100%',
                        padding: '5px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={internetAccess}
                            onChange={handleInternetAccessChange}
                            name="checked"
                            color="primary"
                            sx={{
                              color:
                                operatorInfo.hall.defaultInternetAccess !==
                                'permitted'
                                  ? 'grey'
                                  : 'black',
                              '&.Mui-checked': {
                                color:
                                  operatorInfo.hall.defaultInternetAccess !==
                                  'permitted'
                                    ? 'grey'
                                    : 'primary.main',
                              },
                              '&.Mui-disabled': {
                                color: 'grey',
                              },
                              '&.MuiCheckbox-root.Mui-disabled': {
                                color: 'grey',
                              },
                            }}
                            disabled={
                              operatorInfo.hall.defaultInternetAccess !==
                              'permitted'
                                ? true
                                : false
                            }
                          />
                        }
                        label={
                          <Typography
                            variant="h6"
                            style={{
                              color: 'black',
                              marginLeft: '10px',
                              marginTop: '5px',
                            }}
                          >
                            {t('sales.internetAccess')}
                          </Typography>
                        }
                      />
                    </Grid>

                    <div className="react-confirm-alert-button-group">
                      <button
                        className="btn-custom-yes"
                        onClick={() => {
                          confirmRegister();
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px', // Space between icon and text
                        }}
                      >
                        <CheckCircleIcon style={{ fontSize: '1.2em' }} />
                        {t('header.newMember')}
                      </button>

                      {/* IMAGE VERIFICATION */}
                      {/*
                      <button
                        className="btn-custom-thirdOption"
                        onClick={() => {
                          handlePicture();
                        }}
                      >
                        {t("sales.photoUploadTitle")}
                      </button>
                      */}

                      <button
                        className="btn-custom-no"
                        onClick={() => {
                          cancel();
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px', // Space between icon and text
                        }}
                      >
                        <CancelIcon style={{ fontSize: '1.2em' }} />

                        {t('sales.cancel')}
                      </button>
                    </div>
                  </Grid>
                </div>
              ) : (
                <div
                  className="react-confirm-alert-body"
                  style={{ width: '  1000px' }}
                >
                  <h1>{t('sales.smsTitle')}</h1>
                  <p>{t('sales.smsText1')}</p>
                  <p>{t('sales.smsText2')}</p>

                  <div className="react-confirm-alert-button-group">
                    <button
                      className="btn-custom-no"
                      onClick={() => {
                        cancel();
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px', // Space between icon and text
                      }}
                    >
                      <CancelIcon style={{ fontSize: '1.2em' }} />

                      {t('sales.cancel')}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ModalFullUserRegistration;
