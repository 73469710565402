import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";

import Calculator from "../../../components/Calculator/Calculator";
import PaymentScreenButtons from "./PaymentScreenButtons";
import PaymentScreenDescription from "./PaymentScreenDescription";

import { AppContext } from "../../../context/Context";

function PaymentScreen() {
  const {
    vendorInfo,
    sellType,
    payCashTemp,
    updatePayCashTemp,
    updateShowUserInfo,
  } = useContext(AppContext);

  const [amount, setAmount] = useState("0");

  useEffect(() => {
    updateShowUserInfo(false);
  }, []);

  useEffect(() => {}, [payCashTemp]);

  const updateAmount = (value, isTotal) => {
    /*
    if (!isTotal && parseInt(amount + value) > parseInt(total)) {
      setAmount(total.toString());
      updatePayCashTemp(total);
      return;
    }
    */

    if ((amount === 0 || amount === "0") && (value === "0" || value === "00")) {
      return;
    }

    if ((amount === "0" || amount === 0) && value !== "backspace") {
      setAmount(value);
      updatePayCashTemp(parseInt(amount + value));
      return;
    }
    if ((amount === "0" || amount === 0) && value === "backspace") {
      return;
    }
    if (
      value === "backspace" &&
      amount.toString().length === 1 &&
      (amount !== "0" || amount !== 0)
    ) {
      setAmount("0");
      updatePayCashTemp(0);
      return;
    }
    if (
      value === "backspace" &&
      amount.toString().length > 1 &&
      (amount !== "0" || amount !== 0)
    ) {
      setAmount(amount.substring(0, amount.length - 1));
      updatePayCashTemp(parseInt(amount.substring(0, amount.length - 1)));
      return;
    }

    if (isTotal) {
      setAmount(value.toString());
      updatePayCashTemp(value);
      return;
    }

    setAmount(amount + value);
    updatePayCashTemp(parseInt(amount + value));
  };

  const updateAmountShortcut = (value) => {
    setAmount(value.toString());
    updatePayCashTemp(value);
  };
  const updateOnlyAmount = (value) => {
    setAmount(value.toString());
    //updatePayCashTemp(0);
  };

  const [description, setDescription] = useState("");
  const [sortDescription, setSortDescription] = useState("");
  const [action, setAction] = useState("");

  const [sum, setSum] = useState(true);

  const [isTicket, setIsTicket] = useState(false);

  const [isTicketCH, setIsTicketCH] = useState(false);

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: "10px",
      }}
    >
      {/*Header*/}
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            padding: "10px",
            height: "100%",
            width: "100%",
          }}
        >
          <PaymentScreenDescription
            amount={amount}
            updateAmount={updateAmount}
            description={description}
            action={action}
            isTicket={isTicket}
            isTicketCH={isTicketCH}
            updateAmountShortcut={updateAmountShortcut}
          />
        </Grid>

        <Grid
          item
          xs={6}
          sx={{
            padding: "10px",
          }}
        >
          <Calculator updateAmount={updateAmount} />
          <PaymentScreenButtons
            amount={amount}
            updateOnlyAmount={updateOnlyAmount}
            sum={sum}
            sortDescription={sortDescription}
            action={action}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentScreen;
