import React from "react";
import { Grid } from "@mui/material";
import CalculatorItem from "./CalculatorItem";

function Calculator({ updateAmount, isColorCard, isSellScreen }) {
  return (
    <Grid
      item
      xs={12}
      sx={{
        border: "2px solid black",
        width: "100%",
        maxWidth: "100% !important",
        height: isSellScreen ? "584px" : "100%",
        backgroundColor: !isColorCard ? "#232a45" : "#f0f0f0",
      }}
    >
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "140px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          padding: "10px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CalculatorItem updateAmount={updateAmount} value={"1"} />
        <CalculatorItem updateAmount={updateAmount} value={"2"} />
        <CalculatorItem updateAmount={updateAmount} value={"3"} />
        <CalculatorItem updateAmount={updateAmount} value={"backspace"} />
      </Grid>
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "140px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          padding: "10px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CalculatorItem updateAmount={updateAmount} value={"4"} />
        <CalculatorItem updateAmount={updateAmount} value={"5"} />
        <CalculatorItem updateAmount={updateAmount} value={"6"} />
        <CalculatorItem value={null} />
      </Grid>
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "140px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          padding: "10px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CalculatorItem updateAmount={updateAmount} value={"7"} />
        <CalculatorItem updateAmount={updateAmount} value={"8"} />
        <CalculatorItem updateAmount={updateAmount} value={"9"} />
        <CalculatorItem value={null} />
      </Grid>
      <Grid
        container
        flexDirection="row"
        sx={{
          height: "140px",
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          padding: "10px",
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <CalculatorItem updateAmount={updateAmount} value={"0"} />
        <CalculatorItem updateAmount={updateAmount} value={"00"} />
        <CalculatorItem updateAmount={updateAmount} value={"50"} />
        <CalculatorItem value={null} />
      </Grid>
    </Grid>
  );
}

export default Calculator;
