import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../context/Context";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import "../../assets/css/buttons.css";

function ModalEditNumbers({ updateShowEditModal, provider }) {
  const { t } = useTranslation();

  const { closeShiftNumbers, updateCloseShiftNumbers } = useContext(AppContext);

  const [numbers, setNumbers] = useState({});

  useEffect(() => {
    switch (provider) {
      case "CH":
        var data = {
          cashin: closeShiftNumbers.chCashin,
          cashout: closeShiftNumbers.chCashout,
          neto: closeShiftNumbers.chCashin - closeShiftNumbers.chCashout,
        };
        setNumbers(data);
        break;

      case "OK D.":
        var data = {
          cashin: closeShiftNumbers.okCashin,
          cashout: closeShiftNumbers.okCashout,
          neto: closeShiftNumbers.okCashin - closeShiftNumbers.okCashout,
        };
        setNumbers(data);
        break;

      case "WG":
        var data = {
          cashin: closeShiftNumbers.wgCashin,
          cashout: closeShiftNumbers.wgCashout,
          neto: closeShiftNumbers.wgCashin - closeShiftNumbers.wgCashout,
        };
        setNumbers(data);
        break;

      default:
        return;
    }
  }, []);

  const handleChangeNumber = (e, type) => {
    switch (type) {
      case "cashin":
        setNumbers({ ...numbers, cashin: e.target.value });
        break;
      case "cashout":
        setNumbers({ ...numbers, cashout: e.target.value });
        break;
      case "neto":
        setNumbers({ ...numbers, neto: e.target.value });
        break;
      default:
        return;
    }
  };

  // #region LOGIC
  //* CONFIRM REGISTRATION BUTTON (search if phone exist or register the user)
  const confirmOpenShift = async () => {
    //update numbers if needed
    updateShowEditModal(false);
    switch (provider) {
      case "CH":
        var data = closeShiftNumbers;
        data.chCashin = numbers.cashin;
        data.chCashout = numbers.cashout;
        data.chNeto = numbers.neto;
        updateCloseShiftNumbers(data);
        break;

      case "OK D.":
        var data = closeShiftNumbers;
        data.okCashin = numbers.cashin;
        data.okCashout = numbers.cashout;
        data.okNeto = numbers.neto;
        break;

      case "WG":
        var data = closeShiftNumbers;
        data.wgCashin = numbers.cashin;
        data.wgCashout = numbers.cashout;
        data.wgNeto = numbers.neto;
        break;

      default:
        return;
    }
  };

  const cancel = () => {
    updateShowEditModal(false);
  };

  return (
    <div id="react-confirm-alert">
      <div className="react-confirm-alert-overlay">
        <div className="react-confirm-alert">
          <div className="react-confirm-alert-body" style={{ width: "1000px" }}>
            <h1>{t("report.edit") + " " + provider} </h1>

            <TextField
              autoComplete="off"
              label={t("report.cashin")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                handleChangeNumber(e, "cashin");
              }}
              placeholder={numbers.cashin}
              className="modal-textfield"
              value={numbers.cashin}
            />

            <TextField
              autoComplete="off"
              label={t("report.cashout")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                handleChangeNumber(e, "cashout");
              }}
              placeholder={numbers.cashout}
              className="modal-textfield"
              value={numbers.cashout}
            />

            <TextField
              autoComplete="off"
              label={t("report.neto")}
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => {
                handleChangeNumber(e, "neto");
              }}
              placeholder={numbers.neto}
              className="modal-textfield"
              value={numbers.neto}
            />

            <div className="react-confirm-alert-button-group">
              <button
                className="btn-custom-yes"
                onClick={() => {
                  confirmOpenShift();
                }}
              >
                {t("report.confirm")}
              </button>

              <button
                className="btn-custom-no"
                onClick={() => {
                  cancel();
                }}
              >
                {t("sales.cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalEditNumbers;
